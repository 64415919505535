import { chainId_ETH } from "../helpers/utilities";
import '../styles/referralStyles.less';
import { Skeleton, Statistic, Table, Row, Col, Button } from "antd";
import { useBTReferrals } from "../hooks/useBTReferrals";
import { formattedTime, weiToFixed } from "../helpers/utilities";
import { encode, K_ARR, ORDER32 } from '../helpers/encode'  // DO NOT CHANGE THIS LINE
import styled from "styled-components";
import { fonts, colors, transitions, shadows } from "../styles";
import Column from "../components/Column";
import { isMobile } from "../utils/userAgent";
import { ellipseAddress } from "../helpers/utilities";


const SReferralContainer = styled.div`
  position: relative;
  width: 95%;
  height: 624px;
  max-height: 80%;
  max-width: 960px;
  margin: 0 auto;
  padding-top: ${(props) => (props.isMobile ? `28px` : `56px`)};
  justify-content: center;
  background: #FAFAFA;
  border-radius: 60px;
`;

const SStatisticsPanel = styled.div`
  height: 25%;
  margin-bottom: 46px;
  padding-top: 24px;
  /* Sunset Orange / orange-1 */
  background: #FFF7E6;
  border-radius: 25px;
`;

const SReferralLinkBar = styled.div`
  width: 100%;
  /* Geek Blue / geekblue-2 */
  background: #D6E4FF;
  margin-bottom: 46px;
  border-radius: 20px;
  padding: 8px 20px 8px 32px; 
  justify-content: space-between;
  font-size: ${fonts.size.small};
`;

const SHoverLayer = styled.div`
  transition: ${transitions.button};
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgb(${colors.white}, 0.1);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
`;

const SCopyButton = styled.button`
  z-index: 99;
  width: 64px;
  height: 40px;
  /* Gray / gray-9 */
  background: #262626;
  border-radius: 1820px;  
  @media (hover: hover) {
    &:hover {
      transform: translateY(-1px);
      box-shadow: ${shadows.hover};
    }

    &:hover ${SHoverLayer} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const STitleLabel = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  /* Gray / gray-9 */
  color: #262626;
`;

const SReferralPageLabel = styled.label`
  position: relative;
  width: 60%;
  height: 50px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 50px;
  /* identical to box height */

  /* Gray / gray-10 */
  color: #000000;
`

const mobileBlockStyle = {
  width: "82%",
  marginLeft: "9%",
  marginRight: "9%",
}

const desktopBlockStyle = {
  width: "64%",
  marginLeft: "6.6%",
  marginRight: "auto",
}

const referralProgressSiderStyle = {
  /* Auto layout */
  //display: "flex",
  //flexDirection: "column",
  //alignItems: "flex-end",
  position: "absolute",
  padding: "4px 4px",
  width: "20%",
  height: "496px",
  top: "56px",
  right: "54px",
  //zIndex: 42,
  //left: "24px",
  //top: "124px",
  /* Gray / gray-1 */
  //background: "#FFFFFF",
  //boxShadow: "inset -1px 0px 0px #F0F0F0",
};

const desktopValueStyle = {
  fontFamily: "Verdana",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "24px",
  lineHeight: "36px",
}

const mobileValueStyle = {
  fontFamily: "Verdana",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "16px",
  lineHeight: "18px",
}

function BatchtransferReferrals(props) {
  const { provider, web3, chainId, walletAddress, tl } = props;
  const { referrals, totalValue, pending, paid, accEarnings } = useBTReferrals(provider, web3, chainId, walletAddress);
  const enAddress = encode(walletAddress, K_ARR, ORDER32)  // DO NOT CHANGE THIS LINE
  const referLink = 'https://app.batchtransfer.io/?ref=' + enAddress
  const displayRefLink = 'https://app.batchtransfer.io/?ref=' + (isMobile ? ellipseAddress(enAddress, 0, 0) : enAddress);

  const getCoin = (chainId) => {
    return chainId ? chainId_ETH.get(chainId) : ''
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referLink);
  };

  const columns = [
    {
      title: tl('Friends Wallet Address'),
      dataIndex: "refereeAddress",
      key: "refereeAddress",
      render: (address) => address
    },
    {
      title: `${tl('Referral Bonus')} ${chainId ? '(' + getCoin(chainId) + ')' : ''}`,
      dataIndex: "accEarnings",
      key: "accEarnings",
      render: (value, item) => web3 && value ? weiToFixed(value, 4, web3) : null,
    },
    {
      title: tl('Last Transaction Date'),
      dataIndex: "lastTxTimestamp",
      key: "lastTxTimestamp",
      render: (timestamp) => timestamp ? formattedTime(timestamp, true) : null
    },
  ];

  const logoTitle = 'Batch Transfer NFTs, Bulk Send Tokens'

  let key = 0;
  return (
    // <Column maxWidth={1000} spanHeight center={false}>
    <Column maxWidth={1000} center={false}>
      {!isMobile && <><SReferralPageLabel>{`${tl('Start Earning ')}20% + 10%`}</SReferralPageLabel><br></br></>}
      <SReferralContainer isMobile={isMobile}>
        <div style={isMobile ? mobileBlockStyle : desktopBlockStyle}>
          <SStatisticsPanel>
            <Row justify="center">
              <Col span={6}>
                <img src="/assets/earningsIcon.png" title={logoTitle} alt={logoTitle} />
                {web3 &&
                  <Statistic
                    title={tl('Total Bonus')}
                    value={web3 && accEarnings ? (isMobile ? `${weiToFixed(accEarnings, 3, web3)}` : `${weiToFixed(accEarnings, 4, web3)}`) : '-'}
                    valueStyle={isMobile ? mobileValueStyle : desktopValueStyle}
                    suffix={<div style={{ fontSize: 12, fontWeight: "normal" }}>{getCoin(chainId)}</div>} />}
              </Col>
              <Col span={6}>
                <img src="/assets/earningsIcon.png" title={logoTitle} alt={logoTitle} />
                {web3 &&
                  <Statistic
                    title={tl('Paid')}
                    value={web3 && paid ? (isMobile ? `${weiToFixed(paid, 3, web3)}` : `${weiToFixed(paid, 4, web3)}`) : '-'}
                    valueStyle={isMobile ? mobileValueStyle : desktopValueStyle}
                    suffix={<div style={{ fontSize: 12, fontWeight: "normal" }}>{getCoin(chainId)}</div>} />}
              </Col>
              <Col span={6}>
                <img src="/assets/friendsStartedIcon.png" title={logoTitle} alt={logoTitle} />
                {web3 &&
                  <Statistic
                    title={tl('Pending')}
                    value={web3 && pending ? (isMobile ? `${weiToFixed(pending, 3, web3)}` : `${weiToFixed(pending, 4, web3)}`) : '-'}
                    valueStyle={isMobile ? mobileValueStyle : desktopValueStyle}
                    suffix={<div style={{ fontSize: 12, fontWeight: "normal" }}>{getCoin(chainId)}</div>} />}
              </Col>
              <Col span={6}>
                <img src="/assets/friendsIcon.png" title={logoTitle} alt={logoTitle} />
                {web3 &&
                  <Statistic
                    title={tl('Friends')}
                    value={referrals ? referrals.length : '-'}
                    valueStyle={isMobile ? mobileValueStyle : desktopValueStyle} />}
              </Col>
            </Row>
          </SStatisticsPanel>
        </div>
        <div style={isMobile ? mobileBlockStyle : desktopBlockStyle}>
          <STitleLabel>{tl('Your Referral Link')}</STitleLabel>
          <SReferralLinkBar>
            <Row justify='space-between' align='middle'>
              <p style={{ fontSize: "12px" }}>{displayRefLink}</p>
              <Button
                onClick={copyToClipboard}
                shape="round"
                type="primary"
              >
                {tl('Copy')}
              </Button>
            </Row>
          </SReferralLinkBar>
        </div>
        <div style={isMobile ? mobileBlockStyle : desktopBlockStyle}>
          <STitleLabel>{tl('Your Referrals')}</STitleLabel>
          <Skeleton loading={!referrals}>
            <Table
              dataSource={referrals}
              columns={columns}
              rowKey={(record) => {
                key++;
                return `${record.refereeAddress}-${key}`;
              }}
              pagination={false}
              scroll={{ y: 104 }} />
          </Skeleton>
        </div>
        {!isMobile &&
          <div style={referralProgressSiderStyle}>
            {tl('en') === 'zh' ?
              <img src="/assets/referralProgress_zh.jpg" title={logoTitle} alt={logoTitle} />
              :
              <img src="/assets/referralProgress_en.jpg" title={logoTitle} alt={logoTitle} />
            }
          </div>
        }
      </SReferralContainer>
    </Column>
  );
}

export default BatchtransferReferrals;
