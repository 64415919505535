import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";


const getWalletTransactions = /* GraphQL */ `
  query GetWallet($chainId: Int!, $address: ID!) {
    getWallet(chainId: $chainId, address: $address) {
      address
      transactions(filter: {chainId: {eq: $chainId}}) {
        items {
          chainId
          txHash
          tokenType
          tokenAddress
          numberOfReceivers
          tokenAmount
          discountRate
          timestamp
        }
      }
    }
  }
`;

export const useBTTransactions = (provider, web3, chainId, walletAddress) => {
    const [transactions, setTransactions] = useState([])
    //const { walletAddress } = props

    useEffect(() => {
      if (provider?.on && web3 && walletAddress) {
        fetchTransactions();
      }
    }, [provider, web3, chainId, walletAddress]);

    async function fetchTransactions() {
        try {
          const txData = await API.graphql(graphqlOperation(getWalletTransactions, { chainId: chainId, address: walletAddress.toLowerCase() }));
          const data = txData.data.getWallet;
          if (!data) return;
          const txs = data.transactions.items;
          setTransactions(txs);
          return txs;
        } catch (err) {
          // console.log('Error fetching transactions:', err)
        }
    };

    return { fetchTransactions, transactions };
};