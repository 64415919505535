import { useRef, useState } from "react";
import { Radio, RadioChangeEvent, InputNumber } from "antd";


interface ITipSelectorProps {
  onSelectTips: (selectedTips: string) => void;
  defaultTips?: number;
}

const TipSelector = (props: ITipSelectorProps) => {
  const { onSelectTips, defaultTips } = props;
  const [selectedTips, setTips] = useState(defaultTips);

  return (
    <>
      <Radio.Group
        defaultValue="0"
        size="large"
        onChange={(e: RadioChangeEvent) => {
          const val = e.target.value
          setTips(val);
          onSelectTips(val);
        }}
      >
        <Radio.Button value={0}>0</Radio.Button>
        <Radio.Button value={1}>$1</Radio.Button>
        <Radio.Button value={2}>$2</Radio.Button>
        <Radio.Button value={5}>$5</Radio.Button>
        <Radio.Button value={10}>$10</Radio.Button>
        <Radio.Button value={50}>$50</Radio.Button>
      </Radio.Group>
    </>
  );
};


export default TipSelector;
