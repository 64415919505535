/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTransaction = /* GraphQL */ `
  query GetTransaction($chainId: Int!, $txHash: ID!) {
    getTransaction(chainId: $chainId, txHash: $txHash) {
      chainId
      txHash
      sender
      referrer
      tokenType
      tokenAddress
      numberOfReceivers
      tokenAmount
      value
      commission
      discountRate
      referralBonusRate
      description
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $chainId: Int
    $txHash: ModelIDKeyConditionInput
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTransactions(
      chainId: $chainId
      txHash: $txHash
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        chainId
        txHash
        sender
        referrer
        tokenType
        tokenAddress
        numberOfReceivers
        tokenAmount
        value
        commission
        discountRate
        referralBonusRate
        description
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWallet = /* GraphQL */ `
  query GetWallet($chainId: Int!, $address: ID!) {
    getWallet(chainId: $chainId, address: $address) {
      chainId
      address
      transactions {
        items {
          chainId
          txHash
          sender
          referrer
          tokenType
          tokenAddress
          numberOfReceivers
          tokenAmount
          value
          commission
          discountRate
          referralBonusRate
          description
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      refereesByChainByLastTxTimestamp {
        items {
          chainId
          referrerAddress
          refereeAddress
          accEarnings
          lastTxTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      refereesByChainByEarnings {
        items {
          chainId
          referrerAddress
          refereeAddress
          accEarnings
          lastTxTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      referrer
      totalValue
      paid
      accEarnings
      ownerships {
        items {
          id
          chainId
          walletAddress
          tokenAddress
          balance
          didApprove
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listWallets = /* GraphQL */ `
  query ListWallets(
    $chainId: Int
    $address: ModelIDKeyConditionInput
    $filter: ModelWalletFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWallets(
      chainId: $chainId
      address: $address
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        chainId
        address
        transactions {
          nextToken
        }
        refereesByChainByLastTxTimestamp {
          nextToken
        }
        refereesByChainByEarnings {
          nextToken
        }
        referrer
        totalValue
        paid
        accEarnings
        ownerships {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReferralRelationship = /* GraphQL */ `
  query GetReferralRelationship(
    $chainId: Int!
    $referrerAddress: ID!
    $refereeAddress: ID!
  ) {
    getReferralRelationship(
      chainId: $chainId
      referrerAddress: $referrerAddress
      refereeAddress: $refereeAddress
    ) {
      chainId
      referrerAddress
      refereeAddress
      accEarnings
      lastTxTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listReferralRelationships = /* GraphQL */ `
  query ListReferralRelationships(
    $chainId: Int
    $referrerAddressRefereeAddress: ModelReferralRelationshipPrimaryCompositeKeyConditionInput
    $filter: ModelReferralRelationshipFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReferralRelationships(
      chainId: $chainId
      referrerAddressRefereeAddress: $referrerAddressRefereeAddress
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        chainId
        referrerAddress
        refereeAddress
        accEarnings
        lastTxTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getToken = /* GraphQL */ `
  query GetToken($chainId: Int!, $token_address: ID!) {
    getToken(chainId: $chainId, token_address: $token_address) {
      chainId
      token_address
      contract_type
      symbol
      name
      logo
      balance
      decimals
      ownerships {
        items {
          id
          chainId
          walletAddress
          tokenAddress
          balance
          didApprove
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTokens = /* GraphQL */ `
  query ListTokens(
    $chainId: Int
    $token_address: ModelIDKeyConditionInput
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTokens(
      chainId: $chainId
      token_address: $token_address
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        chainId
        token_address
        contract_type
        symbol
        name
        logo
        balance
        decimals
        ownerships {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWalletTokenRelationship = /* GraphQL */ `
  query GetWalletTokenRelationship($id: ID!) {
    getWalletTokenRelationship(id: $id) {
      id
      chainId
      walletAddress
      tokenAddress
      balance
      didApprove
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listWalletTokenRelationships = /* GraphQL */ `
  query ListWalletTokenRelationships(
    $filter: ModelWalletTokenRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWalletTokenRelationships(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chainId
        walletAddress
        tokenAddress
        balance
        didApprove
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
