import fileSaver from "file-saver";
import { useState } from "react";

interface IDownloaderProps {
    //tokenStandard?: "eth" | "erc20" | "erc721" | "erc1155";
    tokenStandard?: string;
    tl: (key: string) => string;
  }

const Downloader = (props: IDownloaderProps) => {
    //
    const { tokenStandard, tl } = props;
    const [selectedSuffix, setSuffix] = useState("csv");

    const saveFile = (e: any) => {
        if (!tokenStandard) {
          return
        }
        const suffix = e.target.value
        setSuffix(suffix);
        fileSaver.saveAs(
            `/download_example/${tokenStandard.toUpperCase()}/send_${tokenStandard.toUpperCase()}.${suffix}`,
            `send_${tokenStandard.toUpperCase()}.${suffix}`
        )
    };

    return (
      <>
      {tokenStandard && 
        <label>
          {tl('Download')} <a href={`/download_example/${tokenStandard.toUpperCase()}/send_${tokenStandard.toUpperCase()}.csv`}><u>csv</u></a>, <a href={`/download_example/${tokenStandard.toUpperCase()}/send_${tokenStandard.toUpperCase()}.txt`}><u>txt</u></a>, {tl('or')} <a href={`/download_example/${tokenStandard.toUpperCase()}/send_${tokenStandard.toUpperCase()}.xlsx`}><u>Excel</u></a> {tl('example')}
        </label>
      }
      </>
    );
}

export default Downloader;