import { useNFTBalances } from "react-moralis";
import Dropdown from "react-dropdown";
import styled from "styled-components";
import "../styles/autocompleteStyles.css";
import AutoComplete from "./Autocomplete";

const SDropdown = styled(Dropdown)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

interface INFTBalancesProps {
    address: string;
    handleFetchBalances: (arg0: any) => void;
  }

const NFTBalances = (props: INFTBalancesProps) => {
  const { getNFTBalances, data, error, isLoading, isFetching } = useNFTBalances();  
  const { address, handleFetchBalances } = props;
  let tokens: any[] = [];
  if (data && data.result) {
    for (var i in data.result) {
        tokens.push(data.result[i].token_address)
    }
  }
  let options = Array.from(new Set(tokens));
  //handleFetchBalances(options);

  return (
    <div>
      <Dropdown 
        options={options} 
        onFocus={() => {
          getNFTBalances({ params: { chain: "0x4", address: address }})
          //handleFetchBalances(options)
        }}
        onChange={(option) => {
         handleFetchBalances(option);
        }}
      />    
    </div>
  );
};

export default NFTBalances