import { useLocation } from "react-router";
//import 'antd/dist/antd.less';
import '../styles/menuItems.less';
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { LangContext } from '../context/lang';

//import '../styles/menu.css';


const divStyle = {
  display: "block",
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}

const MenuStyle = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
}

function MenuItems() {
  const { pathname } = useLocation();
  const { dispatch: { tl } } = useContext(LangContext);

  return (
    <div style={divStyle}>
      <Menu
        theme="light"
        mode="horizontal"
        defaultSelectedKeys={pathname !== '/' ? [pathname] : ['/app']}
        selectedKeys={pathname !== '/' ? [pathname] : ['/app']}
        style={MenuStyle}
        disabledOverflow={true}
      >
        {/* <Menu.Item key="/mint">
          <a href='https://www.nftrai.io' target='_blank' rel='noopener noreferrer'>{tl('Free Mint')}</a>
        </Menu.Item> */}
        <Menu.Item key="/swap">
          <a href='https://weix.io' target='_blank' rel='noopener noreferrer'>{tl('Swap')}</a>
        </Menu.Item>
        <Menu.Item key="/app">
          <NavLink to="/app">{tl('Transfer')}</NavLink>
        </Menu.Item>
        <Menu.Item key="/referrals">
          <NavLink to="/referrals">{tl('Referrals')}</NavLink>
        </Menu.Item>
        <Menu.Item key="/history">
          <NavLink to="/history">{tl('History')}</NavLink>
        </Menu.Item>
        <Menu.Item key="/revoke">
          <NavLink to="/revoke" >{tl('Revoke')}</NavLink>
        </Menu.Item>
        <Menu.Item key="/tutorial">
          <a href='https://www.batchtransfer.io' target='_blank' rel='noopener noreferrer'>{tl('Tutorial')}</a>
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default MenuItems;
