import * as React from "react";
import styled from "styled-components";
import { isObject, getEtherscanUrl } from "../helpers/utilities";

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

interface IResultTableStyleProps {
  nested?: boolean;
  chainId?: number;
  tl?: (key: string) => string;
}

const STable = styled(SContainer) <IResultTableStyleProps>`
  flex-direction: column;
  min-height: ${({ nested }) => (nested ? "auto" : "200px")};
  text-align: left;
`;

const SRow = styled.div<IResultTableStyleProps>`
  width: 100%;
  display: ${({ nested }) => (nested ? "block" : "flex")};
  margin: 6px 0;
`;

const SKey = styled.div<IResultTableStyleProps>`
  width: ${({ nested }) => (nested ? "100%" : "25%")};
  font-weight: 700;
`;

const SValue = styled.div<IResultTableStyleProps>`
  width: ${({ nested }) => (nested ? "100%" : "75%")};
  font-family: monospace;
`;

function toString(key: string, value: any, tl: (k: string) => string) {
  if (typeof value === 'undefined') { return tl('undefined') }
  const v = value.toString()
  if (key === 'Action' || key === 'Status') { return tl(v) }
  return v
}


function ModalResult(props: any) {
  if (!props.children) {
    return null;
  }
  const result = props.children;
  return (
    <STable nested={props.nested}>
      {Object.keys(result).map((key) => {
        const nested = isObject(result[key]);
        const chainId = props.chainId;
        const tl = props.tl
        return (
          <SRow nested={nested} key={key}>
            <SKey nested={nested}>{tl(key)}</SKey>
            <SValue nested={nested}>
              {nested ? (
                <ModalResult nested={nested} chainId={chainId} tl={tl}>{result[key]}</ModalResult>
              ) :
                key === 'Tx hash' ? <a href={getEtherscanUrl(chainId, 'tx', toString(key, result[key], tl))} target="_blank" rel="noopener noreferrer"><u>{toString(key, result[key], tl)}</u></a>
                  : `${toString(key, result[key], tl)}`
              }
            </SValue>
          </SRow>
        );
      })}
    </STable>
  );
}

export default ModalResult;
