import * as PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import useState from 'react-usestateref';
import SegmentedControl from "./SegmentedControl";
import { chainId_ETH, chainId_ERC20, chainId_ERC721, chainId_ERC1155, cyclicChainIds } from "../helpers/utilities";


const tokenStandard_map = new Map<'eth' | 'erc20' | 'erc721' | 'erc1155' | string, number>([
  ['eth', 0],
  ['erc20', 1],
  ['erc721', 2],
  ['erc1155', 3],
]);

interface ITokenStandardSelectorProps {
  connected: boolean;
  chainId?: number;
  backButtonClicked?: boolean;
  onSelectTokenStandard: (selectedTokenStandard: string) => void;
  defaultStandard: string;
}

const TokenStandardSelector = (props: ITokenStandardSelectorProps) => {
  const { connected, chainId, onSelectTokenStandard, defaultStandard } = props
  const [selectedStandard, setStandard] = useState(defaultStandard)

  const [ethLabel, setEthLabel] = useState('ETH')
  const [erc20Label, setErc20Label] = useState('ERC20')
  const [erc721Label, setErc721Label] = useState('ERC721')
  const [erc1155Label, setErc1155Label] = useState('ERC1155')
  const [counter, setCounter, counterRef] = useState(0)
  const nChains = cyclicChainIds.length
  const TWO_SEC = 2000

  const setLabels = () => {
    if (!connected) {
      // console.log('Entered inside, not connected: ', !connected)
      const counter = counterRef.current
      const cc = cyclicChainIds[counter]
      setEthLabel(cc ? chainId_ETH.get(cc) ?? 'ETH' : 'ETH')
      setErc20Label(cc ? chainId_ERC20.get(cc) ?? 'ERC20' : 'ERC20')
      setErc721Label(cc ? chainId_ERC721.get(cc) ?? 'ERC721' : 'ERC721')
      setErc1155Label(cc ? chainId_ERC1155.get(cc) ?? 'ERC1155' : 'ERC1155')
      setCounter((counter + 1) % nChains)
    }
  }

  useEffect(() => {
    setLabels()  // Initial fetch
    const interval = setInterval(() => {
      setLabels()
    }, TWO_SEC);
    return () => clearInterval(interval);  // Clear the interval to prevent memory leaks when unmount.
  }, []);


  return (   
    <SegmentedControl
        name="group-1"
        callback={(val: string) => {
            setStandard(val);
            onSelectTokenStandard(val);
        }}
        defaultIndex={tokenStandard_map.get(selectedStandard)}
        segments={[
            {
                label: connected ? (chainId ? chainId_ETH.get(chainId) ?? 'ETH' : 'ETH') : ethLabel,
                value: 'eth',
                ref: useRef()
            },
            {
                label: connected ? (chainId ? chainId_ERC20.get(chainId) ?? 'ERC20' : 'ERC20') : erc20Label,
                value: 'erc20',
                ref: useRef()
            },
            {
                label: connected ? (chainId ? chainId_ERC721.get(chainId) ?? 'ERC721' : 'ERC721') : erc721Label,
                value: 'erc721',
                ref: useRef()
            },
            {
                label: connected ? (chainId ? chainId_ERC1155.get(chainId) ?? 'ERC1155' : 'ERC1155') : erc1155Label,
                value: 'erc1155',
                ref: useRef()
            }
        ]}
    />  
  );
};

TokenStandardSelector.propTypes = {
  onSelectTokenStandard: PropTypes.func.isRequired,
};

export default TokenStandardSelector;
