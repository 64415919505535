import { IChainData } from './types';

const supportedChains: IChainData[] = [
  {
    name: 'Unsupported Chain',
    short_name: 'unsupportedchain',
    chain: 'unsupportedchain',
    network: 'unsupportedchain',
    chain_id: 0,
    network_id: 0,
    rpc_url: '',
    native_currency: {
      symbol: '',
      name: '',
      decimals: '0',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Ethereum Mainnet',
    short_name: 'eth',
    chain: 'ETH',
    network: 'mainnet',
    chain_id: 1,
    network_id: 1,
    rpc_url: 'https://mainnet.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: ''
    },
    moralis: {
      app_id: 'nvnDCDVsQlTBauLGk4ehNGNqAMRtkZF4nWRv92UC',
      server_url: 'https://43n3urvy7ren.usemoralis.com:2053/server'
    }
  },
  {
    name: 'Ethereum Ropsten',
    short_name: 'rop',
    chain: 'ETH',
    network: 'ropsten',
    chain_id: 3,
    network_id: 3,
    rpc_url: 'https://ropsten.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: ''
    },
    moralis: {
      app_id: 'MxZaIlYt216Mt85u6sqCmqLepAFZSV5s1NX12o4o',
      server_url: 'https://fnbny2ch4evs.usemoralis.com:2053/server'
    }
  },
  {
    name: 'Ethereum Rinkeby',
    short_name: 'rin',
    chain: 'ETH',
    network: 'rinkeby',
    chain_id: 4,
    network_id: 4,
    rpc_url: 'https://rinkeby.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: ''
    },
    moralis: {
      app_id: 'Ql08r5GuIshPINweuNn7BRSZ40f82QQ1YWpxaay9',
      server_url: 'https://5i7nnzct5jbg.usemoralis.com:2053/server'
    }
  },
  {
    name: 'Ethereum Görli',
    short_name: 'gor',
    chain: 'ETH',
    network: 'goerli',
    chain_id: 5,
    network_id: 5,
    rpc_url: 'https://goerli.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'RSK Mainnet',
    short_name: 'rsk',
    chain: 'RSK',
    network: 'mainnet',
    chain_id: 30,
    network_id: 30,
    rpc_url: 'https://public-node.rsk.co',
    native_currency: {
      symbol: 'RSK',
      name: 'RSK',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Ethereum Kovan',
    short_name: 'kov',
    chain: 'ETH',
    network: 'kovan',
    chain_id: 42,
    network_id: 42,
    rpc_url: 'https://kovan.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Ethereum Classic Mainnet',
    short_name: 'etc',
    chain: 'ETC',
    network: 'mainnet',
    chain_id: 61,
    network_id: 1,
    rpc_url: 'https://ethereumclassic.network',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'POA Network Sokol',
    short_name: 'poa',
    chain: 'POA',
    network: 'sokol',
    chain_id: 77,
    network_id: 77,
    rpc_url: 'https://sokol.poa.network',
    native_currency: {
      symbol: 'POA',
      name: 'POA',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'POA Network Core',
    short_name: 'skl',
    chain: 'POA',
    network: 'core',
    chain_id: 99,
    network_id: 99,
    rpc_url: 'https://core.poa.network',
    native_currency: {
      symbol: 'POA',
      name: 'POA',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  // {
  //   name: 'xDAI Chain',
  //   short_name: 'xdai',
  //   chain: 'POA',
  //   network: 'dai',
  //   chain_id: 100,
  //   network_id: 100,
  //   rpc_url: 'https://dai.poa.network',
  //   native_currency: {
  //     symbol: 'xDAI',
  //     name: 'xDAI',
  //     decimals: '18',
  //     contractAddress: '',
  //     balance: ''
  //   }
  // },
  {
    name: 'Callisto Mainnet',
    short_name: 'clo',
    chain: 'callisto',
    network: 'mainnet',
    chain_id: 820,
    network_id: 1,
    rpc_url: 'https://clo-geth.0xinfra.com/',
    native_currency: {
      symbol: 'CLO',
      name: 'CLO',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Binance Smart Chain',
    short_name: 'bsc',
    chain: 'bscmainnet',
    network: 'bscmainnet',
    chain_id: 56,
    network_id: 56,
    rpc_url: 'https://bsc-dataseed1.defibit.io/',
    native_currency: {
      symbol: 'BNB',
      name: 'BNB',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'BSC Testnet',
    short_name: 'bsctest',
    chain: 'bsctestnet',
    network: 'bsctestnet',
    chain_id: 97,
    network_id: 97,
    rpc_url: 'https://data-seed-prebsc-2-s2.binance.org:8545/',
    native_currency: {
      symbol: 'BNB',
      name: 'BNB',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Polygon Mainnet',
    short_name: 'matic',
    chain: 'polygonmainnet',
    network: 'polygonmainnet',
    chain_id: 137,
    network_id: 137,
    rpc_url: 'https://polygon-mainnet.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'MATIC',
      name: 'MATIC',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Polygon Testnet',
    short_name: 'mumbai',
    chain: 'polygontestnet',
    network: 'polygontestnet',
    chain_id: 80001,
    network_id: 80001,
    rpc_url: 'https://polygon-mumbai.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'MATIC',
      name: 'MATIC',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Avalanche Mainnet',
    short_name: 'avax',
    chain: 'avalanchemainnet',
    network: 'avalanchemainnet',
    chain_id: 43114,
    network_id: 43114,
    rpc_url: 'https://api.avax.network/ext/bc/C/rpc',
    native_currency: {
      symbol: 'AVAX',
      name: 'AVAX',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Avalanche Testnet',
    short_name: 'fuji',
    chain: 'avalanchetestnet',
    network: 'avalanchetestnet',
    chain_id: 43113,
    network_id: 43113,
    rpc_url: 'https://api.avax-test.network/ext/bc/C/rpc',
    native_currency: {
      symbol: 'AVAX',
      name: 'AVAX',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Fantom Opera',
    short_name: 'ftm',
    chain: 'fantomopera',
    network: 'fantomopera',
    chain_id: 250,
    network_id: 250,
    rpc_url: 'https://rpcapi.fantom.network/',
    native_currency: {
      symbol: 'FTM',
      name: 'FTM',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Fantom Testnet',
    short_name: 'ftmtest',
    chain: 'fantomtestnet',
    network: 'fantomtestnet',
    chain_id: 4002,
    network_id: 4002,
    rpc_url: 'https://rpc.testnet.fantom.network/',
    native_currency: {
      symbol: 'AVAX',
      name: 'AVAX',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Heco Mainnet',
    short_name: 'hecomainnet',
    chain: 'hecomainnet',
    network: 'hecomainnet',
    chain_id: 128,
    network_id: 128,
    rpc_url: 'https://http-mainnet.hecochain.com',
    native_currency: {
      symbol: 'HT',
      name: 'HT',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Heco Testnet',
    short_name: 'hecotestnet',
    chain: 'hecotestnet',
    network: 'hecotestnet',
    chain_id: 256,
    network_id: 256,
    rpc_url: 'https://http-testnet.hecochain.com',
    native_currency: {
      symbol: 'HTT',
      name: 'HTT',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Cronos Mainnet',
    short_name: 'cronosmainnet',
    chain: 'cronosmainnet',
    network: 'cronosmainnet',
    chain_id: 25,
    network_id: 25,
    rpc_url: 'https://evm-cronos.crypto.org',
    native_currency: {
      symbol: 'CRO',
      name: 'CRO',
      decimals: '8',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Optimism',
    short_name: 'optimism',
    chain: 'optimism',
    network: 'optimism',
    chain_id: 10,
    network_id: 10,
    rpc_url: 'https://mainnet.optimism.io',
    native_currency: {
      symbol: 'ETH',
      name: 'ETH',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Optimism Goerli',
    short_name: 'optimismgoerli',
    chain: 'optimismgoerli',
    network: 'optimismgoerli',
    chain_id: 420,
    network_id: 420,
    rpc_url: 'https://goerli.optimism.io',
    native_currency: {
      symbol: 'ETH',
      name: 'ETH',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Arbitrum',
    short_name: 'arbitrum',
    chain: 'arbitrum',
    network: 'arbitrum',
    chain_id: 42161,
    network_id: 42161,
    rpc_url: 'https://arb1.arbitrum.io/rpc',
    native_currency: {
      symbol: 'ETH',
      name: 'ETH',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Arbitrum Rinkeby',
    short_name: 'arbitrumrinkeby',
    chain: 'arbitrumrinkeby',
    network: 'arbitrumrinkeby',
    chain_id: 421611,
    network_id: 421611,
    rpc_url: 'https://rinkeby.arbitrum.io/rpc',
    native_currency: {
      symbol: 'ETH',
      name: 'ETH',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Klaytn',
    short_name: 'klaytn',
    chain: 'klaytn',
    network: 'klaytn',
    chain_id: 8217,
    network_id: 8217,
    rpc_url: 'https://public-node-api.klaytnapi.com/v1/cypress',
    native_currency: {
      symbol: 'KLAY',
      name: 'KLAY',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Klaytn Testnet',
    short_name: 'klaytntestnet',
    chain: 'klaytntestnet',
    network: 'klaytntestnet',
    chain_id: 1001,
    network_id: 1001,
    rpc_url: 'https://api.baobab.klaytn.net:8651',
    native_currency: {
      symbol: 'KLAY',
      name: 'KLAY',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Gnosis xDAI',
    short_name: 'gnosis',
    chain: 'gnosis',
    network: 'gnosis',
    chain_id: 100,
    network_id: 100,
    rpc_url: 'https://rpc.gnosischain.com',
    native_currency: {
      symbol: 'xDAI',
      name: 'xDAI',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Aurora',
    short_name: 'aurora',
    chain: 'aurora',
    network: 'aurora',
    chain_id: 1313161554,
    network_id: 1313161554,
    rpc_url: 'https://mainnet.aurora.dev',
    native_currency: {
      symbol: 'ETH',
      name: 'ETH',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Celo Mainnet',
    short_name: 'celo',
    chain: 'celomainnet',
    network: 'celomainnet',
    chain_id: 42220,
    network_id: 42220,
    rpc_url: 'https://forno.celo.org',
    native_currency: {
      symbol: 'CELO',
      name: 'CELO',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Fusion Mainnet',
    short_name: 'fusion',
    chain: 'fusionmainnet',
    network: 'fusionmainnet',
    chain_id: 32659,
    network_id: 32659,
    rpc_url: 'https://mainnet.anyswap.exchange',
    native_currency: {
      symbol: 'FSN',
      name: 'FSN',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Moonbeam',
    short_name: 'moonbeam',
    chain: 'moonbeam',
    network: 'moonbeam',
    chain_id: 1284,
    network_id: 1284,
    rpc_url: 'https://rpc.api.moonbeam.network',
    native_currency: {
      symbol: 'GLMR',
      name: 'GLMR',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Moonriver',
    short_name: 'moonriver',
    chain: 'moonriver',
    network: 'moonriver',
    chain_id: 1285,
    network_id: 1285,
    rpc_url: 'https://rpc.api.moonriver.moonbeam.network',
    native_currency: {
      symbol: 'MOVR',
      name: 'MOVR',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Moonbase Alphanet',
    short_name: 'moonbasealpha',
    chain: 'moonbasealphanet',
    network: 'moonbasealphanet',
    chain_id: 1287,
    network_id: 1287,
    rpc_url: 'https://rpc.api.moonbase.moonbeam.network',
    native_currency: {
      symbol: 'DEV',
      name: 'DEV',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Ethereum PoW',
    short_name: 'ethw',
    chain: 'EthereumPoW',
    network: 'EthereumPoW',
    chain_id: 10001,
    network_id: 10001,
    rpc_url: 'https://mainnet.ethereumpow.org',
    native_currency: {
      symbol: 'ETHW',
      name: 'Ethereum PoW',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
  {
    name: 'Ethereum Fair',
    short_name: 'ethf',
    chain: 'EthereumFair',
    network: 'EthereumFair',
    chain_id: 513100,
    network_id: 513100,
    rpc_url: 'https://rpc.etherfair.org',
    native_currency: {
      symbol: 'ETHF',
      name: 'Ethereum Fair',
      decimals: '18',
      contractAddress: '',
      balance: ''
    }
  },
];

// Network Name
// Cube Chain Mainnet
// Network URL
// https://http-mainnet.cube.network
// Chain ID
// 1818
// Currency Symbol
// CUBE
// Block Explorer URL
// https://cubescan.network

// Chains that has deflationary ERC20:
// BSC: 56
// BSC Testnet: 97
// Polygon: 137
// Polygon Testnet: 80001

// 1. Cronos: https://cronoscan.com
// 2. Optimism: https://optimistic.etherscan.io
// 3. Arbitrum: https://arbiscan.io
// 4. Klaytn: https://scope.klaytn.com, need to upload contract, No
// 5. Gnosis: https://blockscout.com/xdai/mainnet, Verified, Applied Dapp Link
// 5. Gnosis: https://gnosisscan.io
// 6. Aurora: https://aurorascan.dev, Cannot verify. Try later
// 7. Celo: https://celoscan.io, applied
// 8. Fusion: https://blocks.fusionnetwork.io, hard to know, No
// 9. ETHW: https://mainnet.ethwscan.com
// 10. ETHF: https://explorer.etherfair.org

// https://baobab.scope.klaytn.com
// https://testnet.arbiscan.io/
// https://hecoinfo.com/
// https://testnet.hecoinfo.com/

// Add chain
// 1. chains.ts
// 2. utilities.ts
// 3. faucet
// 4. deploy
// 5. contracts.ts
// 6. Footer.tsx
// 7. Tool supports
// 8. SEO

// Change charge commission for app
// 1. utilities.ts
// 2. Sender.tsx
// 2. ./src/index.tsx

// Change charge commission for io
// 1. pricing.js
// 2. banner.js
// 2. ./src/components/seo.js



export default supportedChains;
