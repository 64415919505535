import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Torus from "@toruslabs/torus-embed";
import Fortmatic from "fortmatic";
import { useCallback, useEffect, useReducer, useContext } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useMoralis, useERC20Balances, useNFTBalances } from "react-moralis";
import { chainId_map } from "./helpers/utilities";
import Footer from "./components/Footer";
import Header from "./components/HeaderV2";
import Sender from "./pages/Sender";
import BTTransactions from "./pages/BTTransactions";
import BTReferrals from "./pages/BTReferrals";
import Security from "./pages/Security";
import useState from 'react-usestateref';
import styled from "styled-components";
import ReactGA from "react-ga4";
import { LangContext } from './context/lang';
import axios from "axios";

ReactGA.initialize("G-7J4MBTF15E");

const SLayout = styled.div`
  height: 100%;
  overflow: auto;
  background: radial-gradient(90.49% 178.94% at 98.75% 10.5%, rgba(219, 157, 225, 0.22) 0%, rgba(210, 209, 237, 0.789496) 17.71%, rgba(200, 216, 223, 0.743536) 30.73%, rgba(187, 214, 232, 0.45) 62.63%, rgba(246, 226, 195, 0.560419) 96.14%);
`;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_ID
    }
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: 'Batch Transfer',
      infuraId: process.env.REACT_APP_INFURA_ID
    }
  },
  binancechainwallet: {
    package: true
  },
  fortmatic: {
    package: Fortmatic, // required
    options: {
      key: process.env.REACT_APP_FORTMATIC_API_KEY, // required
      network: {
        rpcUrl: 'https://rpc-mainnet.maticvigil.com',
        chainId: 137
      } // if we don't pass it, it will default to localhost:8454
    }
  },
  torus: {
    package: Torus,
  },
}

let web3Modal: Web3Modal
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions
  })
}

type AppStateType = {
  provider?: any
  web3Provider?: any
  address?: string
}

type AppActionType =
  | {
    type: 'SET_WEB3_PROVIDER'
    provider?: AppStateType['provider']
    web3Provider?: AppStateType['web3Provider']
    address?: AppStateType['address']
  }
  | {
    type: 'SET_ADDRESS'
    address?: AppStateType['address']
  }
  | {
    type: 'SET_CHAIN_ID'
  }
  | {
    type: 'RESET_WEB3_PROVIDER'
  }

const INITIAL_STATE: AppStateType = {
  provider: null,
  web3Provider: null,
  address: "",
}

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);
  /* Increase this parameter to avoid reject request but still mined.  */
  // https://github.com/ChainSafe/web3.js/issues/1102
  // https://web3js.readthedocs.io/en/v1.7.0/web3-eth.html#transactionblocktimeout
  web3.eth.transactionBlockTimeout = 99999;
  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });
  return web3;
}

function reducer(state: AppStateType, action: AppActionType): AppStateType {
  switch (action.type) {
    case 'SET_WEB3_PROVIDER':
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
      }
    case 'SET_ADDRESS':
      return {
        ...state,
        address: action.address,
      }
    case 'SET_CHAIN_ID':
      return {
        ...state,
      }
    case 'RESET_WEB3_PROVIDER':
      return INITIAL_STATE
    default:
      throw new Error()
  }
}

export const App = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { provider, web3Provider, address } = state;
  // const { Moralis } = useMoralis();
  const [web3, setWeb3, web3Ref] = useState(null);
  const [walletAddress, setWalletAddress, walletAddressRef] = useState("");
  const [chainId, setChainId, chainIdRef] = useState(1);
  const { getNFTBalances, data: NFTBalancesData, isLoading: isLoadingNFT } = useNFTBalances({ chain: chainId_map.get(chainId), address: walletAddress });
  // const { fetchERC20Balances, data: ERC20BalancesData, isLoading: isLoadingERC20 } = useERC20Balances({ chain: chainId_map.get(chainId), address: walletAddress });
  const [NFTBalancesV2, setNFTBalancesV2, NFTBalancesV2Ref] = useState<any>([]);
  const [ERC20Balances, setERC20Balances] = useState<any>([]);
  const { dispatch: { tl } } = useContext(LangContext);

  const fetchNFTBalances = useCallback(async function () {
    try {
      const address = walletAddress.toLowerCase();
      const chainName = chainId_map.get(chainId);
      const response = await axios.get(`https://deep-index.moralis.io/api/v2/${address}/nft?chain=${chainName}&format=decimal&media_items=false`, {
        headers: {
          'X-API-Key': process.env.REACT_APP_MORALIS_KEY!,
          'accept': 'application/json',
        },
      })
      return response.data;
    } catch (err) {
      console.log('error fetching assets:', err);
    }
  }, [chainId, walletAddress]);

  const fetchERC20Balances = useCallback(async function () {
    try {
      const address = walletAddress.toLowerCase();
      const chainName = chainId_map.get(chainId);
      const response = await axios.get(`https://deep-index.moralis.io/api/v2/${address}/erc20?chain=${chainName}`, {
        headers: {
          'X-API-Key': process.env.REACT_APP_MORALIS_KEY!,
          'accept': 'application/json',
        },
      })
      return response.data;
    } catch (err) {
      console.log('error fetching assets:', err);
    }
  }, [chainId, walletAddress]);

  const connect = useCallback(async function () {
    // This is the initial `provider` that is returned when
    // using web3Modal to connect. Can be MetaMask or WalletConnect.
    ReactGA.event('connect_wallet');

    const provider = await web3Modal.connect();
    const web3: any = initWeb3(provider);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const chainId = await web3.eth.chainId();

    setWalletAddress(address);
    setChainId(chainId);
    setWeb3(web3);

    fetchERC20Balances().then((result) => {
      setERC20Balances(result);
    });
    const nftbalances = await fetchNFTBalances();
    setNFTBalancesV2(nftbalances);
    dispatch({
      type: 'SET_WEB3_PROVIDER',
      provider,
      web3Provider: web3,
      address,
    })
  }, [fetchERC20Balances, fetchNFTBalances, getNFTBalances, setChainId, setNFTBalancesV2, setWalletAddress, setWeb3]);

  const disconnect = useCallback(
    async function () {
      ReactGA.event('disconnect_wallet');

      web3Modal.clearCachedProvider()
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect()
      }
      setWalletAddress("");
      setChainId(1);
      setWeb3(null);
      setNFTBalancesV2([]);
      setERC20Balances([]);
      dispatch({
        type: 'RESET_WEB3_PROVIDER',
      })
    }, [provider]);

  // Auto connect to the cached provider
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connect();
    }
  }, [connect]);

  // A `provider` should come with EIP-1193 events. We'll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        setWalletAddress(accounts[0]);
        dispatch({
          type: 'SET_ADDRESS',
          address: accounts[0],
        })
      }

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId: string) => {
        setChainId(parseInt(_hexChainId));
      }

      const handleDisconnect = (error: { code: number; message: string }) => {
        disconnect()
      }

      provider.on('accountsChanged', handleAccountsChanged)
      provider.on('chainChanged', handleChainChanged)
      provider.on('disconnect', handleDisconnect)

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged)
          provider.removeListener('chainChanged', handleChainChanged)
          provider.removeListener('disconnect', handleDisconnect)
        }
      }
    }
  }, [provider, disconnect])

  useEffect(() => {
    const web3 = web3Ref.current;
    const walletAddress = walletAddressRef.current;
    const chainId = chainIdRef.current;
    // const isLoading = isLoadingNFT;
    if (provider?.on && web3 && walletAddress && chainId) {
      fetchERC20Balances().then((result) => {
        setERC20Balances(result);
      });
      const nftbalances = fetchNFTBalances();
      setNFTBalancesV2(nftbalances);
    }
  }, [web3, walletAddress, chainId, connect, provider, web3Ref, walletAddressRef, chainIdRef, fetchERC20Balances, getNFTBalances, fetchNFTBalances, setNFTBalancesV2]);

  return (
    <SLayout>
      <Router>
        <Header
          connected={web3Provider}
          address={address}
          chainId={chainId}
          killSession={disconnect}
          defaultStandard={'erc721'}
          connectWallet={connect}
        />
        <Routes>
          <Route
            path='/app'
            element={<Sender chainId={chainId} address={address} showModal={false} showQuestModal={false} provider={provider} web3Provider={web3Provider} ERC20Balances={ERC20Balances} NFTBalances={NFTBalancesV2} tl={tl} />}
          />
          <Route
            path='/history'
            element={<BTTransactions provider={provider} web3={web3Provider} chainId={chainId} walletAddress={address} tl={tl} />}
          />
          <Route
            path='/referrals'
            element={<BTReferrals provider={provider} web3={web3Provider} chainId={chainId} walletAddress={address} tl={tl} />}
          />
          <Route
            path='/revoke'
            element={<Security chainId={chainId} address={address} showModal={false} provider={provider} web3Provider={web3Provider} ERC20Balances={ERC20Balances} NFTBalances={NFTBalancesV2} tl={tl} />}
          />
          <Route
            path='/'
            element={<Sender chainId={chainId} address={address} showModal={false} showQuestModal={false} provider={provider} web3Provider={web3Provider} ERC20Balances={ERC20Balances} NFTBalances={NFTBalancesV2} tl={tl} />}
          />
        </Routes>
      </Router>
      <Footer
        web3Provider={web3Provider}
        address={address}
        chainId={chainId}
        connectWallet={connect}
      />
    </SLayout>
  )
}

export default App