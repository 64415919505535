import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { useState, useCallback } from 'react';
import * as XLSX from 'xlsx';
import { aggregateJson, addHeaderToCsvStr } from '../helpers/utilities';


interface IFileUploaderProps {
  isDragAccept: boolean;
  isDragReject: boolean;
  isFocused: boolean;
  tokenStandard?: string;
  connected: boolean;
  // handleFileUploading: (arg0: string, arg1: any[]) => void;
  handleFileUploading: (arg0: string) => void;
  tl: (key: string) => string;
}

const getColor = (props: IFileUploaderProps) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
    flex: 1;
    width: 100%;
    max-height: 251px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props: IFileUploaderProps) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  `;

const TightH1 = styled.h1`
    line-height: 60%;
  `;

function FileUploader(props: IFileUploaderProps) {
  //
  const { tokenStandard, handleFileUploading, connected, tl } = props;
  const onDrop = useCallback(acceptedFiles => {
    //
    const file = acceptedFiles[0]
    const name = file.name; // TODO: If the user click cancel when uploading, will have this error: Uncaught (in promise) TypeError: Cannot read properties of undefined (reading 'name')
    const lastDot = name.lastIndexOf('.');

    //const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    //
    switch (ext) {
      case 'xlsx':
        readExcel(file);
        break;
      case 'csv':
        readCsv(file);
        break;
      case 'txt':
        readCsv(file);
        break;
    }
  }, [])
  //
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    multiple: false,
    accept: ['text/csv', 'text/plain', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    onDrop: onDrop,
    disabled: !connected
  });
  const [placeholder, setPlaceholder] = useState('');
  const [pendingDrop, togglePendingDrop] = useState(true);

  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const readCsv = (file: File) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target) return;
      const csvStr = e.target.result as string;
      // handleCsv(csvStr)
      postprocess(csvStr);
    }
  };

  const readExcel = async (file: File) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        if (!e.target) return;
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const csvStr = XLSX.utils.sheet_to_csv(ws);
        resolve(csvStr);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    //
    promise.then((csvStr: any) => {
      if (csvStr !== null) {
        postprocess(csvStr);
      }
    });
  };


  // const readExcel = async (file: File) => {
  //     const promise = new Promise((resolve, reject) => {
  //         const fileReader = new FileReader();
  //         fileReader.readAsArrayBuffer(file);    
  //         fileReader.onload = (e: ProgressEvent<FileReader>) => {
  //         if (!e.target) return;
  //         const bufferArray = e.target.result;
  //         const wb = XLSX.read(bufferArray, { type: "buffer" });
  //         const wsname = wb.SheetNames[0];
  //         const ws = wb.Sheets[wsname];
  //         const data = XLSX.utils.sheet_to_json(ws);
  //         resolve(data);
  //         };
  //         fileReader.onerror = (error) => {
  //         reject(error);
  //         };
  //     });
  //     //
  //     promise.then((jsonArray: any) => {
  //         if (jsonArray !== null) {      
  //         const { Parser } = require('json2csv');
  //         const json2csvParser = new Parser({ quote: '' });
  //         var csvStr = json2csvParser.parse(jsonArray);
  //         // csvStr = addHeaderToCsvStr(tokenStandard, csvStr)
  //         postprocess(csvStr);
  //         }
  //     });
  // };


  const postprocess = (csvStr: string) => {
    togglePendingDrop(!pendingDrop);
    setPlaceholder(csvStr);
    handleFileUploading(csvStr);
  };

  return (
    <Container
      {
      ...getRootProps({
        isFocused,
        isDragAccept,
        isDragReject,
        tokenStandard,
        connected,
        handleFileUploading,
        tl
      })
      }>
      <input {...getInputProps()} />
      <TightH1>&#8679;</TightH1>
      {connected ?
        <h6>{tl('Drag and drop file, or click to upload.')}</h6>
        :
        <h6>{tl('Please connect wallet before uploading.')}</h6>
      }
    </Container>
  );
}

FileUploader.defaultProps = {
  isDragAccept: false,
  isDragReject: false,
  isFocused: false
};

export default FileUploader