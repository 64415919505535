import styled from "styled-components";
import Wrapper from "../components/Wrapper";
import Column from "../components/Column";
import { fonts, colors, colorCode } from "../styles";
import Button from "../components/Button";
import CodeMirror from "@uiw/react-codemirror";
import NFTBalances from "../components/NFTBalances";
import InputModeButton from "../components/InputModeButton";
import logo from "../assets/logo.svg";


export const SLayout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  text-align: center;
`;

export const SHeader = styled.div`
  margin-top: -1px;
  margin-bottom: 1px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  overflow: auto;
`;

export const SFormWrapper = styled.div`
  position: relative;
  padding: 8px 88px;
  width: 100%;
  display: flex;
  overflow: auto;
`;

export const SPanelContainer = styled(Wrapper)`
  position: relative;
  width: ${(props) => (props.isMobile ? `8%` : `70%`)};
  min-height: 696px;
  max-width: 700px;
  min-width: 420px;
  padding: 57px 16px; 
  background: #F5F5F5;
  border-radius: 60px;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.25));
`;

export const SProgressWrapper = styled(Wrapper)`
  padding: 0;
`

export const STightP = styled.p`
  padding: 0;
  margin: 0;
`;

export const SLoaderDiv = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

export const SLanding = styled(Column)`
  height: 600px;
`;

export const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

export const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

// export const SSendNote = styled.p`
//   color: rgb(${colors.weiBlue});
// `;

export const STitle = styled.h6`
  color: ${colorCode.blue};
`;

export const SSendNote = styled.p`
color: ${colorCode.blue};
`;

export const SSendNoteMargin = styled.p`
color: ${colorCode.blue};
margin-left: 10px;
margin-right: 10px;
`;

export const SSendNoteLeft = styled.p`
color: ${colorCode.blue};
align-items: left;
text-align: left;
`;

export const SChainsNote = styled.p`
// color: ${colorCode.blue};
margin-left: 30px;
margin-right: 30px;
margin-bottom: 30px;
align-items: left;
text-align: left;
`;

export const SInvalidParagraph = styled.p`
  color: red;
`;

export const SModalParagraph = styled.p`
  margin-top: 30px;
`;

export const SModalParagraphLeft = styled.p`
  margin-top: 30px;
  align-items: left;
  text-align: left;
`;

// @ts-ignore
export const SBalances = styled(SLanding)`
  position: relative;
  margin-top: 75px;
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;

export const STestButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const STestButton = styled(Button)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  max-width: 175px;
  margin: 0 0 0 12px;
`;

export const SAddressLabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 14px;
`;

export const SWrongPhraseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 14px;
`;

export const SInputModeButton = styled(InputModeButton)`
  border-radius: 8px;
  font-size: ${fonts.size.small};
  width: 100%;  
  max-width: 150px;
  margin: 0 0 0 0;
`;

export const STokenInput = styled.input`
  font-size: ${fonts.size.medium};
  width: 100%;
  height: 44px;
  padding: 10px;
  margin: 0;
  background: white;
  border: black;
  border-radius: 3px;
  ::placeholder {
    color: lightblue;
  }
`;

export const SRadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

export const SRadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  &:hover ~ ${SRadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 6px;
      background: #eeeeee;
    }
  }
  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${SRadioButtonLabel} {
      background: lightblue;
      border: 1px solid lightblue;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 6px;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: white;
      }
    }
  `}
`;

export const SDropdownArrow = styled(NFTBalances)`
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;

  &::before {
    content: "\2193" !important;
    font-size: 30px;
    line-height: 1;
    color: black;
    background:none;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d2d2d2;
  }
`;

export const SRadioWrapper = styled.div`
  height: auto;
  width: auto;
  padding: 0px 16px 24px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

export const STextarea = styled.textarea`
    min-height: 251px;
    font-size: ${fonts.size.medium};
    width: 100%;
    padding: 10px;
    background: white;
    border: black;
    border-radius: 3px;
    ::placeholder {
      color: lightblue;
    }    
`;

export const SCodeMirror = styled(CodeMirror)`
    min-height: 251px;
    height: 251px;
    overflow: auto;
    font-size: ${fonts.size.medium};
    width: 100%;
    padding: 10px;
    background: #D6E4FF;
    border: white;
    box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    ::placeholder {
      color: lightblue;
    }
    text-align: left;
`

//export const SCMPlaceholder = styled(placeholder)`
//    height: 222px;
//`

export const STextInputWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 251px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  outline: none;
  transition: border .24s ease-in-out;
`;

export const SBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  & span {
    color: rgb(${colors.weiBlue});
    font-weight: ${fonts.weight.bold};
    font-size: ${fonts.size.h5};
    margin-left: 12px;
  }
`;

export const SBanner = styled.div`
  width: 233px;
  height: 45px;
  background: url(${logo}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  z-index: 1;
`

export const SDropdown = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 0;
  z-index: 99;
`