import { useRef, useState } from "react";
import { Radio, RadioChangeEvent, InputNumber } from "antd";


interface IDonateSelectorProps {
  onSelectDonates: (selectedDonates: string) => void;
  defaultDonates?: number;
}

const DonateSelector = (props: IDonateSelectorProps) => {
  const { onSelectDonates, defaultDonates } = props;
  const [selectedDonates, setDonates] = useState(defaultDonates);

  return (
    <>
      <Radio.Group
        defaultValue="1"
        size="large"
        onChange={(e: RadioChangeEvent) => {
          const val = e.target.value
          setDonates(val);
          onSelectDonates(val);
        }}
      >
        <Radio.Button value={1}>$1</Radio.Button>
        <Radio.Button value={2}>$2</Radio.Button>
        <Radio.Button value={5}>$5</Radio.Button>
        <Radio.Button value={10}>$10</Radio.Button>
        <Radio.Button value={50}>$50</Radio.Button>
        <Radio.Button value={100}>$100</Radio.Button>
      </Radio.Group>
    </>
  );
};


export default DonateSelector;
