import styled from "styled-components";
import * as PropTypes from "prop-types";
import Blockie from "./Blockie";
import Banner from "./Banner";
import { ellipseAddress, getChainData } from "../helpers/utilities";
import { transitions } from "../styles";
import { useState, useContext, ChangeEvent } from "react";
import ConnectButton from "./ConnectButton";
import MenuItems from "./MenuItems";
import { LangContext } from '../context/lang';


const SHeader = styled.div`
  margin-top: -1px;
  margin-bottom: 1px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  overflow: auto;
`;

const SActiveAccount = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 500;
`;

const SActiveChain = styled(SActiveAccount)`
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  width: 143px;
  & p {
    font-size: 0.8em;
    margin: 0;
    padding: 0;
  }
  & p:nth-child(2) {
    font-weight: bold;
  }
`;

const SBlockie = styled(Blockie)`
  margin-right: 10px;
`;

interface IHeaderStyle {
  connected: boolean;
}

const SAddress = styled.p<IHeaderStyle>`
  transition: ${transitions.base};
  font-weight: bold;
  margin: ${({ connected }) => (connected ? "-2px auto 0.7em" : "0")};
`;

const SDisconnect = styled.div<IHeaderStyle>`
  transition: ${transitions.button};
  font-size: 12px;
  font-family: monospace;
  position: absolute;
  right: 0;
  top: 20px;
  opacity: 0.7;
  cursor: pointer;

  opacity: ${({ connected }) => (connected ? 1 : 0)};
  visibility: ${({ connected }) => (connected ? "visible" : "hidden")};
  pointer-events: ${({ connected }) => (connected ? "auto" : "none")};

  &:hover {
    transform: translateY(-1px);
    opacity: 0.5;
  }
`;

const styles: any = {
  select: {
    marginLeft: '5px',
    height: '36px',
    border: 'transparent',
    background: 'transparent',
  },
  lanButton: {
    marginLeft: '5px',
    height: '36px',
    border: 'transparent',
    background: 'transparent',
    cursor: 'pointer',
  },
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#041836",
    marginTop: "130px",
    padding: "10px",
  },
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    //background: "#FFF",
    background: "rgba(255,255,255,0.9)",
    //backgroundColor: "rgba(255, 255, 255, 0.9)",
    //backgroundColor: "#FFF", /* Background white, to override the background propery*/
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
  },
  headerLeft: {
    display: "flex",
    width: "300px",
    gap: "20px",
    alignItems: "center",
    justifyContent: 'left',
  },
  headerRight: {
    display: "flex",
    width: "300px",
    gap: "20px",
    alignItems: "center",
    justifyContent: 'right',
    fontSize: "15px",
    fontWeight: "600",
  },
};

interface IHeaderProps {
  killSession: () => void;
  connected: boolean;
  address?: string;
  chainId?: number;
  defaultStandard: string;
  connectWallet: () => void;
}

const BTHeader = (props: IHeaderProps) => {
  const { state: { language }, dispatch: { setLanguage, tl } } = useContext(LangContext);
  const { connected, address, chainId, killSession, defaultStandard, connectWallet } = props;
  const chainData = chainId ? getChainData(chainId) : null;
  // const [selectedStandard, setStandard] = useState(defaultStandard);

  // const selectLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
  //   setLanguage(event.target.value)
  // }

  const handleClick = () => {
    setLanguage(language === 'en' ? 'zh' : 'en')
  }

  return (
    <SHeader>
      <div style={styles.headerLeft}>
        <Banner />
        {/* <select value={language} onChange={selectLanguage} style={styles.select}>
          <option value='en'>EN</option>
          <option value='zh'>中文</option>
        </select> */}
        <button onClick={handleClick} style={styles.lanButton}>
          {language === 'en' ? '中文' : 'EN'}
        </button>
      </div>

      <MenuItems />

      <div style={styles.headerRight}>
        {connected ? ((address && chainData) && (
          <>
            <SActiveChain>
              <p>{tl('Connected to')}</p>
              <p>{chainData.name}</p>
            </SActiveChain>
            <SActiveAccount>
              <SBlockie address={address} />
              <SAddress connected={connected}>{ellipseAddress(address)}</SAddress>
              <SDisconnect connected={connected} onClick={killSession}>
                {tl('Disconnect')}
              </SDisconnect>
            </SActiveAccount>
          </>
        )) : <ConnectButton onClick={connectWallet} tl={tl} />}
      </div>
    </SHeader>
  );
};

BTHeader.propTypes = {
  killSession: PropTypes.func.isRequired,
  address: PropTypes.string,
};

export default BTHeader;
