import { useCallback, useEffect, useReducer, useMemo } from "react";
import Web3Modal from "web3modal";
import * as PropTypes from "prop-types";
import { getEtherscanUrl, getERC20Allowance, getNFTApprovalStatus, formatSetApprovalTransaction, chainId_map, chainId_ETH } from "../helpers/utilities";
import useState from 'react-usestateref';
import Column from "../components/Column";
import TokenSelector3 from "../components/TokenSelector3";
import AutoComplete from "../components/AutocompleteSecurity";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import ModalResult from "../components/ModalResult";
import {
  SPanelContainer,
  SContainer,
  SBalances,
  SModalContainer,
  SModalTitle,
  SModalParagraph,
  SModalParagraphLeft,
  SFormWrapper,
  SSendNote,
  SSendNoteLeft,
} from "../styles/SComponents";
import ReactGA from "react-ga4";
import { EyeOutlined } from "@ant-design/icons";
import '../styles/senderStyles.less';
import { Button } from 'antd';
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { useBTWalletTokenRelationships } from "../hooks/useBTWalletTokenRelationships";
import { isMobile } from "../utils/userAgent";

ReactGA.initialize("G-7J4MBTF15E");

const pStyle = {
  fontSize: "15px",
  color: "#8c8c8c",
  marginBottom: "40px",
}

enum ApproveStatus {
  DISCONNECTED = 0,  // user's wallet needs to be connected, landing page
  IDLE = 1,  // wallet connected, before user input, button disabled
  CONFIRMED_INPUT = 2,
  APPROVED = 3, // setApproval completed
  COMPLETED = 4,  // sending completed
  ERROR = -1  // fallback
};

type ISecurityState = {
  web3Modal?: Web3Modal;
  address?: string;
  web3Provider?: any;
  provider?: any;
  connected?: boolean;
  chainId?: number;
  networkId?: number;
  showModal: boolean;
  pendingRequest?: boolean;
  result?: any | null;
  selectType?: "erc20" | "erc721" | "erc1155";
  tokenAddress?: string;
  approveSendState?: ApproveStatus;
  approvalStatus?: boolean;
  pendingConfirmToken?: boolean;
  pendingTxReceipt?: boolean;
  latestTxHash?: string;
  didCheckAll?: number;
  errorMsg?: string;
  onConnect?: () => void;
  ERC20Balances?: any;
  NFTBalances?: any;
  tl: (key: string) => string;
}

const INITIAL_STATE: ISecurityState = {
  address: '',
  web3Provider: null,
  provider: null,
  connected: false,
  chainId: 3,
  networkId: 1,
  showModal: false,
  pendingRequest: false,
  result: null,
  selectType: 'erc721',
  tokenAddress: '',
  approveSendState: ApproveStatus.DISCONNECTED,
  approvalStatus: false,
  pendingConfirmToken: true,
  pendingTxReceipt: false,
  latestTxHash: '',
  didCheckAll: 0,
  errorMsg: '',
  tl: (key: string) => key,
};

type ActionType =
  | {
    type: 'SET_WEB3_PROVIDER'
    provider?: ISecurityState['provider']
    web3Provider?: ISecurityState['web3Provider']
    address?: ISecurityState['address']
    chainId?: ISecurityState['chainId']
  }
  | {
    type: 'SET_ADDRESS'
    address?: ISecurityState['address']
  }
  | {
    type: 'SET_CHAIN_ID'
    chainId?: ISecurityState['chainId']
  }
  | {
    type: 'SET_TOKEN_STANDARD'
    selectType: ISecurityState['selectType']
  }
  | {
    type: 'TOGGLE_MODAL'
    showModal: ISecurityState['showModal']
  }
  | {
    type: 'SET_TOKEN_ADDRESS'
    tokenAddress: ISecurityState['tokenAddress']
    approvalStatus?: ISecurityState['approvalStatus']
  }
  | {
    type: 'SEND_SET_APPROVAL'
    pendingRequest: ISecurityState['pendingRequest']
    pendingTxReceipt: ISecurityState['pendingTxReceipt']
    latestTxHash?: ISecurityState['latestTxHash']
    result?: ISecurityState['result']
    approvalStatus?: ISecurityState['approvalStatus']
    approveSendState?: ISecurityState['approveSendState']
    errorMsg?: ISecurityState['errorMsg']
  }
  | {
    type: 'RESET_WEB3_PROVIDER'
  }

function reducer(state: ISecurityState, action: ActionType): ISecurityState {
  switch (action.type) {
    case 'SET_WEB3_PROVIDER':
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        chainId: action.chainId,
      }
    case 'SET_ADDRESS':
      return {
        ...state,
        address: action.address,
      }
    case 'SET_CHAIN_ID':
      return {
        ...state,
        chainId: action.chainId,
      }
    case 'SET_TOKEN_STANDARD':
      return {
        ...state,
        selectType: action.selectType,
      }
    case 'TOGGLE_MODAL':
      return {
        ...state,
        showModal: action.showModal,
      }
    case 'SET_TOKEN_ADDRESS':
      return {
        ...state,
        tokenAddress: action.tokenAddress,
        approvalStatus: action.approvalStatus,
      }
    case 'SEND_SET_APPROVAL':
      return {
        ...state,
        pendingRequest: action.pendingRequest,
        pendingTxReceipt: action.pendingTxReceipt,
        latestTxHash: action.latestTxHash,
        result: action.result,
        approvalStatus: action.approvalStatus,
        approveSendState: action.approveSendState,
        errorMsg: action.errorMsg,
      }
    case 'RESET_WEB3_PROVIDER':
      return INITIAL_STATE
    default:
      throw new Error()
  }
}

const Security = (props: ISecurityState) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { chainId: cId, address: addr, provider, web3Provider: web3, ERC20Balances, NFTBalances, tl } = props;
  const { errorMsg, result,
    pendingRequest, pendingTxReceipt, showModal, latestTxHash, web3Modal } = state;
  const [web3Provider, setWeb3Provider, web3Ref] = useState(web3);
  const [address, setAddress, addressRef] = useState(addr);
  const [chainId, setChainId, chainIdRef] = useState(cId);
  const [selectType, setSelectType, typeRef] = useState('erc721');
  const [tokenAddress, setTokenAddress, tokenAddressRef] = useState('');
  const [approveSendState, setApproveSendState] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState(false);
  const [approvePhrase, setApprovePhrase] = useState('');
  const { ownerships } = useBTWalletTokenRelationships(provider, web3, chainId, addr);


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/revoke" });
    setWeb3Provider(web3);
    setAddress(addr);
    setChainId(cId);
    if (web3 && addr) {
      setApproveSendState(ApproveStatus.IDLE);
      dispatch({
        type: 'SET_WEB3_PROVIDER',
        provider: provider,
        web3Provider: web3,
        address: address,
        chainId: chainId
      })
    }
    else {
      setApproveSendState(ApproveStatus.DISCONNECTED);
      dispatch({
        type: 'SET_WEB3_PROVIDER',
        provider: provider,
        web3Provider: web3,
        address: address,
        chainId: chainId
      })
    }
  }, [web3, addr, cId]);


  const handleSelectTokenStandard = useCallback(function (type: any) {
    setApprovePhrase('')
    setSelectType(type);
    dispatch({
      type: 'SET_TOKEN_STANDARD',
      selectType: type,
    })
  }, []);

  const handleDropdownMenu = useCallback(async function (tokenAddress: string) {
    setTokenAddress(tokenAddress);

    const web3 = web3Ref.current;
    const address = addressRef.current;
    const selectType = typeRef.current;
    const chainId = chainIdRef.current;

    if (!web3 || !selectType || !address || !chainId || !tokenAddress) {
      return
    }

    var approvalStatus = false
    var allowance = 0
    if (selectType === 'erc20') {
      allowance = await getERC20Allowance(address, tokenAddress, chainId, web3)
      approvalStatus = allowance > 0
    } else {
      approvalStatus = await getNFTApprovalStatus(address, tokenAddress, chainId, web3)
    }
    var approvePhrase = ''
    if (approvalStatus) {
      if (selectType === 'erc20') {
        // const allowanceExp = Number(allowance).toExponential(4)
        // approvePhrase = 'Your current BatchTransfer allowance is ' + allowanceExp + ' for this token. You can click the Revoke button to set the allowance to 0.'
        approvePhrase = 'BatchTransfer is currently approved for this token. You can click the button to revoke.'
      } else {
        approvePhrase = 'BatchTransfer is currently approved for this NFT. You can click the button to revoke.'
      }
    } else {
      approvePhrase = 'BatchTransfer is currently not approved for this token. No need to revoke.'
    }

    setApprovalStatus(approvalStatus)
    setApprovePhrase(approvePhrase)

    dispatch({
      type: 'SET_TOKEN_ADDRESS',
      tokenAddress: tokenAddress,
      approvalStatus: approvalStatus,
    })
  }, []);

  const revoke = useCallback(async function () {

    const web3 = web3Ref.current;
    const address = addressRef.current;
    const tokenAddress = tokenAddressRef.current;
    const selectType = typeRef.current;
    const chainId = chainIdRef.current;

    if (!web3 || !address || !tokenAddress || !selectType || !chainId) {
      return;
    }

    const ZERO_UINT = '0x0'
    const tx = await formatSetApprovalTransaction(address, tokenAddress, selectType, chainId, ZERO_UINT, false, web3);

    try {
      //
      ReactGA.event('revoke_token_approval');
      // open modal
      toggleModal();

      // toggle pending request indicator
      dispatch({
        type: 'SEND_SET_APPROVAL',
        pendingRequest: true,
        pendingTxReceipt: false
      });

      // @ts-ignore
      const sendTransaction = (_tx: any) => {
        return new Promise((resolve, reject) => {
          web3.eth
            .sendTransaction(_tx)
            .once("transactionHash", (txHash: string) => {
              dispatch({
                type: 'SEND_SET_APPROVAL',
                pendingRequest: false,
                pendingTxReceipt: true,
                latestTxHash: txHash
              });
            })
            .then((txReceipt: any) => resolve(txReceipt))
            .catch((err: any) => reject(err));
        });
      }

      // send transaction
      const receipt: any = await sendTransaction(tx);

      // format displayed result ONCE tx completes
      const formattedResult = {
        Action: 'Revoke',
        From: address,
        To: tokenAddress,
        Value: `0 ${chainId ? chainId_ETH.get(chainId) : ''}`,
        Status: (receipt && receipt.blockNumber) ? 'Success' : 'Error',
        'Tx hash': receipt.transactionHash
      }

      try {
        // Update WalletTokenRelationship Table
        const walletAddress = address.toLowerCase();
        const tkAddress = tokenAddress.toLowerCase();
        const newBTWalletTokenRelationship = {
          chainId: chainId,
          walletAddress: walletAddress,
          tokenAddress: tkAddress,
          didApprove: false,
          timestamp: Math.floor(Date.now() / 1000)
        }
        await API.graphql(graphqlOperation(mutations.createWalletTokenRelationship, { input: newBTWalletTokenRelationship }));
      } catch (error: any) {
        // console.error(error)
      }

      // display result
      setApproveSendState(ApproveStatus.APPROVED);
      setApprovalStatus(false);
      setApprovePhrase('')
      dispatch({
        type: 'SEND_SET_APPROVAL',
        pendingRequest: false,
        result: formattedResult || null,
        approvalStatus: false,
        approveSendState: ApproveStatus.APPROVED,
        pendingTxReceipt: false,
        errorMsg: ''
      });
    } catch (error: any) {
      // console.error(error); // tslint:disable-line
      dispatch({
        type: 'SEND_SET_APPROVAL',
        pendingRequest: false,
        result: null,
        pendingTxReceipt: false,
        errorMsg: error.message
      });
    }
  }, []);

  const toggleModal = useCallback(function () {
    dispatch({
      type: 'TOGGLE_MODAL',
      showModal: !showModal
    });
  }, [showModal]);

  return (
    <div>
      <SFormWrapper>
        <Column maxWidth={1000} spanHeight>
          <SPanelContainer isMobile={isMobile}>
            <TokenSelector3
              chainId={chainId}
              defaultStandard={'erc721'}
              onSelectTokenStandard={handleSelectTokenStandard}
            />
            <SBalances>
              <AutoComplete
                web3={web3}
                chainId={chainId_map.get(chainId)}
                address={address}
                tokenStandard={selectType}
                tokenAddress={tokenAddress}
                options={ownerships}
                handleFetchBalances={handleDropdownMenu}
                tl={tl}
              />
              <br />
              {(approvePhrase && approvePhrase.length > 0) && <p>{tl(approvePhrase)}</p>}
              <br />
              <Column>
                <Button
                  onClick={revoke}
                  disabled={!approvalStatus}
                  shape="round"
                  type="primary"
                  size="large">
                  {tl('Revoke')}
                </Button>
              </Column>
              <br />
              <p style={pStyle}>
                {tl('The approval to BatchTransfer is safe.')}
              </p>
            </SBalances>
          </SPanelContainer>
        </Column>
      </SFormWrapper>
      <br />
      <Modal show={showModal} toggleModal={toggleModal}>
        {pendingRequest ? (
          <SModalContainer>
            <SModalTitle>{tl('Pending Request')}</SModalTitle>
            <SContainer>
              <Loader />
              <SModalParagraph>{tl('Please note that once revoked, BatchTranfer needs to be approved again before using it next time.')}</SModalParagraph>
              <SSendNote><b>{tl('Reject the request from your wallet if you meant not to revoke.')}</b></SSendNote>
            </SContainer>
          </SModalContainer>
        ) : pendingTxReceipt ? (
          <SModalContainer>
            <SModalTitle>{tl('Pending Transaction Receipt')}</SModalTitle>
            <SContainer>
              <Loader />
              <SModalParagraph>
                {tl('Waiting for the transaction to complete: ')}<a href={getEtherscanUrl(chainId, 'tx', latestTxHash)} target='_blank' rel='noopener noreferrer'><u>{latestTxHash}</u></a>
              </SModalParagraph>
            </SContainer>
          </SModalContainer>
        ) : result ? (
          <SModalContainer>
            <SModalTitle>{approveSendState === 3 ? tl('Token Revoked!') : tl('Transaction Success!')}</SModalTitle>
            <ModalResult chainId={chainId} tl={tl}>{result}</ModalResult>
          </SModalContainer>
        ) : (
          <SModalContainer>
            <SModalTitle>{tl('Request Rejected or Timeout')}</SModalTitle>
            <SModalParagraph>
              {tl(errorMsg ?? '')}
            </SModalParagraph>
          </SModalContainer>
        )}
      </Modal>
    </div>
  );
}

Security.propTypes = {
  address: PropTypes.string
};

export default Security;
