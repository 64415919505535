/////////////////////////////
// DO NOT CHANGE THIS FILE //
/////////////////////////////

const PI_ARR = [3, 1, 4, 1, 5, 9, 2, 6, 5, 3, 5, 8, 9, 7, 9, 3, 1, 1, 6, 0]
const EXP_ARR = [2, 7, 1, 8, 2, 8, 1, 8, 2, 8, 4, 5, 9, 0, 4, 5, 0, 9, 0, 7, 9, 5, 5, 9, 8, 2, 9, 8, 4, 2, 7, 6]
export const K_ARR = PI_ARR.concat(EXP_ARR)

export const ORDER32 = [20, 31, 9, 14, 8, 0, 28, 3, 21, 17, 5, 22, 1, 11, 24, 27, 13, 10, 6, 2, 4, 30, 26, 12, 19, 25, 7, 23, 18, 16, 15, 29]
export const DE_ORDER32 = [5, 12, 19, 7, 20, 10, 18, 26, 4, 2, 17, 13, 23, 16, 3, 30, 29, 9, 28, 24, 0, 8, 11, 27, 14, 25, 22, 15, 6, 31, 21, 1]


export const ORDER40 = [22, 25, 17, 20, 18, 3, 6, 8, 10, 12, 2, 37, 26, 28, 27, 30, 1, 38, 19, 23, 39, 36, 24, 33, 31, 0, 35, 13, 9, 5, 7, 32, 4, 14, 16, 34, 21, 15, 11, 29]
export const DE_ORDER40 = [25, 16, 10, 5, 32, 29, 6, 30, 7, 28, 8, 38, 9, 27, 33, 37, 34, 2, 4, 18, 3, 36, 0, 19, 22, 1, 12, 14, 13, 39, 15, 24, 31, 23, 35, 26, 21, 11, 17, 20]

////

const HEX2BIN = {
  '0': '0000', '1': '0001', '2': '0010', '3': '0011', '4': '0100', '5': '0101', '6': '0110', '7': '0111',
  '8': '1000', '9': '1001', 'a': '1010', 'b': '1011', 'c': '1100', 'd': '1101', 'e': '1110', 'f': '1111'
}
const BIN2HEX = {
  '0000': '0', '0001': '1', '0010': '2', '0011': '3', '0100': '4', '0101': '5', '0110': '6', '0111': '7',
  '1000': '8', '1001': '9', '1010': 'a', '1011': 'b', '1100': 'c', '1101': 'd', '1110': 'e', '1111': 'f'
}

const WRD2BIN = {
  '0': '00000', '1': '00001', '2': '00010', '3': '00011', '4': '00100', '5': '00101', '6': '00110', '7': '00111',
  '8': '01000', '9': '01001', 'a': '01010', 'b': '01011', 'c': '01100', 'd': '01101', 'e': '01110', 'f': '01111',
  'g': '10000', 'h': '10001', 'i': '10010', 'j': '10011', 'k': '10100', 'l': '10101', 'm': '10110', 'n': '10111',
  'o': '11000', 'p': '11001', 'q': '11010', 'r': '11011', 's': '11100', 't': '11101', 'u': '11110', 'v': '11111'
}

const BIN2WRD = {
  '00000': '0', '00001': '1', '00010': '2', '00011': '3', '00100': '4', '00101': '5', '00110': '6', '00111': '7',
  '01000': '8', '01001': '9', '01010': 'a', '01011': 'b', '01100': 'c', '01101': 'd', '01110': 'e', '01111': 'f',
  '10000': 'g', '10001': 'h', '10010': 'i', '10011': 'j', '10100': 'k', '10101': 'l', '10110': 'm', '10111': 'n',
  '11000': 'o', '11001': 'p', '11010': 'q', '11011': 'r', '11100': 's', '11101': 't', '11110': 'u', '11111': 'v'
}


export function hexToBin(hex) {
  let bin = ''
  const n = hex.length
  for (let i = 0; i < n; i++) {
    bin += HEX2BIN[hex[i]]
  }
  return bin
}

export function wrdToBin(wrd) {
  let bin = ''
  const n = wrd.length
  for (let i = 0; i < n; i++) {
    bin += WRD2BIN[wrd[i]]
  }
  return bin
}

export function binToHex(bin) {
  let hex = ''
  var n = bin.length
  const rem = n % 4
  if (rem !== 0) {
    // Round down
    bin = bin.substring(rem)
  }
  n = bin.length
  for (let i = 0; i < n; i+=4) {
    hex += BIN2HEX[bin.substring(i, i + 4)]
  }
  return hex
}

export function binToWrd(bin) {
  let wrd = ''
  var n = bin.length
  const rem = n % 5
  if (rem !== 0) {
    // Round up
    let pad = ''
    for (let i = 0; i < 5-rem; i++) {
      pad += '0'
    }
    bin = pad + bin
  }
  n = bin.length
  for (let i = 0; i < n; i+=5) {
    wrd += BIN2WRD[bin.substring(i, i + 5)]
  }
  return wrd
}

export function del0x(hex) {
  if (hex.length < 2) { return hex }
  hex = hex.toLowerCase()
  if (hex.substring(0, 2) === '0x') { return hex.substring(2) }
  return hex
}

export function add0x(hex) {
  hex = hex.toLowerCase()
  if (hex.substring(0, 2) !== '0x') { return ('0x' + hex) }
  return hex
}

export function isValidHex(hex) {
  const n = hex.length
  for (let i = 0; i < n; i++) {
    var v = hex[i].charCodeAt(0)
    if (!((v >= 48 && v <= 57) || (v >= 65 && v <= 70) || (v >= 97 && v <= 102))) { return false }
  }
  return true
}

export function isValidWrd(wrd) {
  const n = wrd.length
  for (let i = 0; i < n; i++) {
    var v = wrd[i].charCodeAt(0)
    if (!((v >= 48 && v <= 57) || (v >= 65 && v <= 86) || (v >= 97 && v <= 118))) { return false }
  }
  return true
}

export function hexToWrd(hex) {
  hex = del0x(hex)
  if (!isValidHex(hex)) {
    return hex
  }
  const bin = hexToBin(hex)
  return binToWrd(bin)
}

export function wrdToHex(wrd) {
  if (!isValidWrd(wrd)) {
    return wrd
  }
  wrd = wrd.toLowerCase()
  const bin = wrdToBin(wrd)
  const hex = binToHex(bin)
  return add0x(hex)
}

/////////////////////////////////////////

export function encodeNum(s, k) {
  const sn = parseInt(s)
  const newSn = (sn + k) % 10
  return newSn.toString()
}

export function decodeNum(s, k) {
  const sn = parseInt(s)
  var newSn = sn - k
  while (newSn < 0) {
    newSn += 10
  }
  return newSn.toString()
}

export function encodeChar(s, k, ev) {
  let val = s.charCodeAt(0)  // ASCII value
  const shiftVal = val + k
  if (shiftVal > ev) {  // if k-th ahead character exceed 'z'
    return String.fromCharCode(shiftVal - 26)
  }
  return String.fromCharCode(shiftVal)
}

export function decodeChar(s, k, sv) {
  let val = s.charCodeAt(0)  // ASCII value
  const shiftVal = val - k
  if (shiftVal < sv) {  // if k-th behind character exceed 'a'
    return String.fromCharCode(shiftVal + 26)
  }
  return String.fromCharCode(shiftVal)
}

// 0: 48
// 9: 57
// A: 65
// Z: 90
// a: 97
// z: 122

export function encodeLetter(s, k) {
  let val = s.charCodeAt(0)  // ASCII value
  if (val >= 48 && val <= 57) { return encodeNum(s, k) }
  if (val >= 65 && val <= 90) { return encodeChar(s, k, 90) }
  if (val >= 97 && val <= 122) { return encodeChar(s, k, 122) }
  return s
}

export function decodeLetter(s, k) {
  let val = s.charCodeAt(0)  // ASCII value
  if (val >= 48 && val <= 57) { return decodeNum(s, k) }
  if (val >= 65 && val <= 90) { return decodeChar(s, k, 65) }
  if (val >= 97 && val <= 122) { return decodeChar(s, k, 97) }
  return s
}

export function shiftEncode(sArr, kArr) {
  let newS = ''
  const sLen = sArr.length
  const kLen = kArr.length
  const minLen = sLen < kLen ? sLen : kLen
  for (let i = 0; i < minLen; i++) {
    newS += encodeLetter(sArr[i], kArr[i])
  }
  for (let i = minLen; i < sLen; i++) {
    newS += sArr[i]
  }
  return newS
}

export function shiftDecode(sArr, kArr) {
  let newS = ''
  const sLen = sArr.length
  const kLen = kArr.length
  const minLen = sLen < kLen ? sLen : kLen
  for (let i = 0; i < minLen; i++) {
    newS += decodeLetter(sArr[i], kArr[i])
  }
  for (let i = minLen; i < sLen; i++) {
    newS += sArr[i]
  }
  return newS
}

export function orderCode(sArr, order) {
  const len = sArr.length
  if (len !== order.length) { return sArr }
  let newS = ''
  for (let i = 0; i < len; i++) {
    newS += sArr[order[i]]
  }
  return newS
}

export function unicodeToB64(str) {
  return Buffer.from(str).toString('base64')
}

export function b64ToUnicode(str) {
  return Buffer.from(str, 'base64').toString('ascii')
}

export function encode(sArr, kArr, order) {
  if (sArr === null) { return sArr }
  const wrdSArr = hexToWrd(sArr)
  const orderArr = orderCode(wrdSArr, order)
  return shiftEncode(orderArr, kArr)
}

export function decode(sArr, kArr, order) {
  if (sArr === null) { return sArr }
  const shiftArr = shiftDecode(sArr, kArr)
  const orderArr = orderCode(shiftArr, order)
  return wrdToHex(orderArr)
}
