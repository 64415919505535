import * as PropTypes from "prop-types";
import { useRef, useState } from "react";
import SegmentedControl from "../components/SegmentedControl";
import { chainId_ERC20, chainId_ERC721, chainId_ERC1155 } from "../helpers/utilities";


interface ITokenSelector3Props {
  chainId?: number;
  onSelectTokenStandard: (selectedTokenStandard: string) => void;
  defaultStandard: string;
}

const TokenSelector3 = (props: ITokenSelector3Props) => {
  const { chainId, onSelectTokenStandard, defaultStandard } = props;
  const [selectedStandard, setStandard] = useState(defaultStandard);

  return (   
    <SegmentedControl
        name="group-1"
        callback={(val: string) => {
            setStandard(val);
            onSelectTokenStandard(val);
        }}
        defaultIndex={1}
        segments={[
            {
                label: chainId ? chainId_ERC20.get(chainId) ?? 'ERC20' : 'ERC20',
                value: 'erc20',
                ref: useRef()
            },
            {
                label: chainId ? chainId_ERC721.get(chainId) ?? 'ERC721' : 'ERC721',
                value: 'erc721',
                ref: useRef()
            },
            {
                label: chainId ? chainId_ERC1155.get(chainId) ?? 'ERC1155' : 'ERC1155',
                value: 'erc1155',
                ref: useRef()
            }
        ]}
    />
  );
};

TokenSelector3.propTypes = {
  onSelectTokenStandard: PropTypes.func.isRequired,
};

export default TokenSelector3;
