import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
//import { listTransactions } from "../graphql/queries";


const getWalletOwnerships = /* GraphQL */ `
  query GetWallet($chainId: Int!, $address: ID!) {
    getWallet(chainId: $chainId, address: $address) {
      address
      ownerships(filter: {chainId: {eq: $chainId}}) {
        items {
          chainId
          walletAddress
          tokenAddress
          balance
          didApprove
          timestamp
        }
      }
    }
  }
`;

const getToken = /* GraphQL */ `
  query GetToken($chainId: Int!, $token_address: ID!) {
    getToken(chainId: $chainId, token_address: $token_address) {
      chainId
      token_address
      contract_type
      symbol
      name
      logo
      balance
      decimals
    }
  }
`;


export const useBTWalletTokenRelationships = (provider, web3, chainId, walletAddress) => {
    const [ownerships, setOwnerships] = useState([])
    //const [totalValue, setTotalValue] = useState("");
    //const { walletAddress } = props

    useEffect(() => {
      if (provider?.on && web3 && walletAddress) {
        fetchOwnerships();
      }
    }, [provider, web3, walletAddress]);

    async function fetchOwnerships() {
        try {
          const ownershipData = await API.graphql(graphqlOperation(getWalletOwnerships, { chainId: chainId, address: walletAddress.toLowerCase() }));
          const data = ownershipData.data.getWallet;
          if (!data) return;
          const ownerships = data.ownerships.items;
          //const value = data.totalValue;
          const reducedOwnerships = await reduceOwnerships(ownerships);
          setOwnerships(reducedOwnerships);
          //setTotalValue(value);
          return ownerships;
        } catch (err) {
          // console.log('error fetching ownerships:', err)
        }
    };

    async function reduceOwnerships(ownerships) {
        let result = ownerships.reduce(function(total, currentValue, currentIndex, arr) {
            let index = total.findIndex(function(entry) { return entry.tokenAddress == currentValue.tokenAddress; })
            if (index >= 0) { // entry already exists
              // check modified
              if (total[index].timestamp < currentValue.timestamp) {
                // need to replace with more recent
                //currentValue.Count = total[index].Count + 1;
                total[index] = currentValue;
              }
            } else { // first record for this code
              //currentValue.Count = 1;
              total.push(currentValue);
            }
            return total;
          }, []);          
        let approvedTokens = [];
        for (var i in result) {
            //tokens.push(NFTBalances.result[i].token_address)
            const item = result[i];
            if (item.didApprove === true) {
              const tokenData = await API.graphql(graphqlOperation(getToken, { chainId: chainId, token_address: item.tokenAddress }));
              const token = tokenData.data.getToken;
              approvedTokens.push(token);
            }
        }
        return approvedTokens;
    }

    return { fetchOwnerships, ownerships };
};