import SEO from "react-seo-component";
//import * as React from "react";
import * as ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";
import Footer from "./components/Footer";
import App from "./AppV2";
import { globalStyle } from "./styles";
import { MoralisProvider } from "react-moralis";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import LangState from './context/lang';

Amplify.configure(awsExports);

const GlobalStyle = createGlobalStyle`
  ${globalStyle}
`;

// @ts-ignore
declare global {
  // tslint:disable-next-line
  interface Window {
    web3: any;
    ethereum: any;
    Web3Modal: any;
    Box: any;
    box: any;
    space: any;
    [name: string]: any;
  }
}

const element = (
  <>
    <SEO
      title={'NFT Batch Transfer, Token Bulk Send NFTs - BatchTransfer.io'}
      titleTemplate={'Tool to batch transfer NFTs, airdrop Tokens on ETH, BNB, Solana, MATIC, AVAX, FTM, HT, CRO, Arbitrum, Optimism, KLAY, xDAI, Aurora, CELO, FSN, ETHW, ETHF, GLMR, and MOVR. Free tool for Binance Smart Chain BNB, Solana SOL, Polygon MATIC, Avalanche AVAX, Fantom FTM, HECO chain HT, Cronos CRO, Arbitrum One, Optimistic ETH, Klaytn KLAY, Gnosis xDAI, Aurora ETH, Celo, Fusion FSN, Ethereum PoW, Ethereum Fair, Moonbeam GLMR, and Moonriver MOVR.'}
      titleSeparator={`-`}
      description={'Tool to batch transfer NFTs, airdrop Tokens on ETH, BNB, Solana, MATIC, AVAX, FTM, HT, CRO, Arbitrum, Optimism, KLAY, xDAI, Aurora, CELO, FSN, ETHW, ETHF, GLMR, and MOVR safely and efficiently to multiple wallets in one transaction. Free tool for Binance Smart Chain BNB, Solana SOL, Polygon MATIC, Avalanche AVAX, Fantom FTM, HECO chain HT, Cronos CRO, Arbitrum One, Optimistic ETH, Klaytn KLAY, Gnosis xDAI, Aurora ETH, Celo, Fusion FSN, Ethereum PoW, Ethereum Fair, Moonbeam GLMR, and Moonriver MOVR.'}
      pathname={'https://www.batchtransfer.io'}
      image={'https://www.batchtransfer.io/_next/static/images/home1-bf47c614d827cf5f7eb93fc1fd4e1198.png'}
      siteLanguage={'en'}
      siteLocale={'en_US'}
      twitterUsername={'@batchtransfer'}
      author={'NFT Batch Transfer, Token Bulk Send NFTs - BatchTransfer.io'}
    />
    <GlobalStyle />
    <MoralisProvider
      appId="Ql08r5GuIshPINweuNn7BRSZ40f82QQ1YWpxaay9"
      serverUrl="https://5i7nnzct5jbg.usemoralis.com:2053/server"
      initializeOnMount={true}>
      <LangState>
        <App />
      </LangState>
    </MoralisProvider>
  </>
);

ReactDOM.render(element, document.getElementById("root"));
