import { useEffect, useRef, useCallback } from 'react'
import useState from 'react-usestateref'
import { useMoralis, useERC20Balances, useNFTBalances } from 'react-moralis'
import styled from 'styled-components'
import { SDropdown } from '../styles/SComponents'
import { Input } from 'antd'
import '../styles/autocompleteStyles.css'
import '../styles/autocompleteStyles.less'
import { getTypeForTokenStandard } from '../helpers/utilities'


interface ITokenBalancesProps {
  web3: any;
  chainId: "0x1" | "0x4" | '0x3' | "0x5" | "0x2a" | undefined;
  address?: string;
  //tokenStandard?: "eth" | "erc20" | "erc721" | "erc1155";
  tokenStandard?: string;
  tokenAddress?: string;
  options?: any;
  backButtonClicked?: boolean;
  handleFetchBalances: (arg0: any, arg1?: any) => void;
  tl: (key: string) => string;
}

const SHitbox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const styles: any = {
  input: {
    height: "64px",
    background: "#F0F5FF",
    boxShadow: "inset 1px 2px 1px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
  }
}


const AutoComplete = (props: ITokenBalancesProps) => {
  const { web3, chainId, address, tokenStandard,
    tokenAddress, options, backButtonClicked, handleFetchBalances, tl } = props;
  const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-42);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState(tokenAddress);
  const [shouldRefresh, setShouldRefresh, shouldRefreshRef] = useState(!backButtonClicked);
  //const [options, setOptions] = useState<any[]>([]); 
  //const { getNFTBalances, data: NFTBalances, error, isLoading, isFetching } = useNFTBalances(); 
  /*  */
  //const { getNFTBalances, data: NFTBalances, isFetching: isFetchingNFT, isLoading: isLoadingNFT } = useNFTBalances({chain: chainId, address: address});
  //const { fetchERC20Balances, data: ERC20Balances, isLoading: isLoadingERC20, isFetching: isFetchingERC20 } = useERC20Balances({chain: chainId, address: address});
  const { Moralis } = useMoralis();
  //const [focusState, setFocusState] = useState(false);
  //const inputEl = useRef(null);
  const mountedRef = useRef(true);

  //const { balances } = useMoralisBalances(web3, chainId, tokenStandard, address);
  const balances: any = [];

  //const { execute, status, value, error } = useAsync<any>(fetchERC20Balances, false);
  /*
  const fetchTokenBalances = useCallback(async () => {
    //
    try {
      //
      if (!mountedRef.current) return null;
      //let promise;
      //promise = tokenStandard==="erc20" ? fetchERC20Balances() : getNFTBalances();
      //
      if (tokenStandard==='erc20') {
        fetchERC20Balances().then(() => {
          setInput("");
          setShowSuggestions(false);
        });
      } else {
        getNFTBalances().then(() => {
          setInput("");
          setShowSuggestions(false);
        });
      }
      //promise.then( () => {
      //})
      //      
    } catch (error) {
      console.log("Error");
    }
  }, [mountedRef]);
  */
  //const fetchTokenBalances = useCallback(() => {

  //}, []);

  //useEffect(() => {
  //let isMounted = true;
  //const isFetching = isFetchingERC20 || isFetchingNFT;
  //const isLoading = isLoadingERC20 || isLoadingNFT;
  //
  //ref.current = true;
  /*
  if (mountedRef.current && chainId && address) {
    setInput("");
    setShowSuggestions(false);
    tokenStandard==="erc20" ? fetchERC20Balances() : getNFTBalances();//.then(() => {
    //promise.then(onFocus);
    //if (inputEl.current) {
    //  promise.then(onFocus)
    //}
  }
  */
  //return () => {isMounted = false};
  //fetchTokenBalances();
  //  let isSubscribed = true;
  //const fetchTokenBalances = tokenStandard==="erc20" ? fetchERC20Balances() : getNFTBalances();
  //  if (mountedRef.current && chainId && address) {
  //fetchERC20Balances().then(() => {
  //  if (isSubscribed) {
  //setInput("");
  //setShowSuggestions(false);
  //  }
  //})
  //    execute();
  //  }
  //  return () => {
  //ref.current = false;
  //    mountedRef.current = false;
  //    isSubscribed = false
  //  }
  //}, [chainId, tokenStandard, address]);

  /* TODO: fix dropdown menu */
  useEffect(() => {
    //console.log("DEBUG autocomplete options:", options);
    const shouldRefresh = shouldRefreshRef.current;
    //console.log("DEBUG should refresh:", shouldRefresh);
    if (shouldRefresh) {
      setInput("");
      setShowSuggestions(false);
    } else {
      setShouldRefresh(true);
    }
  }, [chainId, tokenStandard, address, shouldRefreshRef, setShouldRefresh]);

  const onBlur = () => {
    setShowSuggestions(false);
    //console.log("DEBUG on blur");
  }

  const onChange = (e: any) => {
    const userInput = e.target.value;

    // Filter our suggestions that don't contain the user's input
    const unLinked = filteredSuggestions.filter(
      (suggestion: any) => suggestion.token_address.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      //(suggestion.symbol.toLowerCase().indexOf(userInput.toLowerCase()) > -1)
    );

    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(-42);
    setShowSuggestions(true);
    handleFetchBalances(userInput);
  };

  const onFocus = async () => {
    //
    //tokenStandard==="erc20" ? await fetchERC20Balances() : await getNFTBalances();
    //    
    setShowSuggestions(true);
    setActiveSuggestionIndex(-42);
    /*
    let tokens: any[] = [];
    //const hasBalances = (NFTBalances && NFTBalances.result) || (ERC20Balances);
    if (tokenStandard==="erc20") {
      if (ERC20Balances) {
        for (var i in ERC20Balances) {
          //tokens.push(ERC20Balances[i].token_address);
          tokens.push(ERC20Balances[i]);
        }              
      }
    } else if (NFTBalances && NFTBalances.result) {
      for (var i in NFTBalances.result) {
        //tokens.push(NFTBalances.result[i].token_address)
        const item = NFTBalances.result[i];
        if (item.contract_type.toLowerCase()===tokenStandard) tokens.push(NFTBalances.result[i]);
      }
    }
    */
    //let options = Array.from(new Set(tokens));
    //const options = getUniqueListBy(tokens, 'token_address');
    //const options = getUniqueListBy(balances, 'token_address');
    //setOptions(Array.from(new Set(tokens)));          
    //setOptions(options);
    let tokens: any[] = [];
    if (tokenStandard === "erc20") {
      if (options) {
        for (var i in options) {
          //tokens.push(ERC20Balances[i].token_address);
          tokens.push(options[i]);
        }
      }
    } else if (options && options.result) {
      for (var i in options.result) {
        //tokens.push(NFTBalances.result[i].token_address)
        const item = options.result[i];
        if (item.contract_type.toLowerCase() === tokenStandard) tokens.push(options.result[i]);
      }
    }
    //console.log("DEBUG options:", tokens);
    setFilteredSuggestions(getUniqueListBy(tokens, 'token_address'));
  }

  const onClick = (e: any) => {
    //console.log("DEBUG onClick");
    const li = e.target.closest('li');
    const nodes = Array.from(li.closest('ul').children);
    const index = nodes.indexOf(li);
    const item = filteredSuggestions[index];
    const t_address = item.token_address;
    const t_name = tokenStandard === 'erc20' ? item.symbol : item.name;
    //console.log("DEBUG onClick token target:", index);
    setFilteredSuggestions([]);
    setInput(t_address);
    setActiveSuggestionIndex(-42);
    setShowSuggestions(false);
    handleFetchBalances(t_address, t_name);
  };

  const onKeyDown = (e: any) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setInput(filteredSuggestions[activeSuggestionIndex].token_address);
      setActiveSuggestionIndex(-42);
      setShowSuggestions(false);
      const item = filteredSuggestions[activeSuggestionIndex];
      const t_name = tokenStandard === 'erc20' ? item.symbol : item.name;
      handleFetchBalances(item.token_address, t_name);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex === -42) {
        setActiveSuggestionIndex(0);
        return;
      }
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex === -42) {
        setActiveSuggestionIndex(0);
        return;
      }
      if (activeSuggestionIndex === filteredSuggestions.length) {
        return;
      }
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  function getUniqueListBy(arr: any, key: string) {
    return [...new Map(arr.map((item: any) => [item[key], item])).values()]
  }

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className="suggestions">
        {filteredSuggestions.map((item, index) => {
          let className;

          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }
          //console.log("DEBUG index, activeSuggestionIndex =", index, activeSuggestionIndex);
          //console.log("DEBUG className:", className);

          return (
            <li className={className} key={item.token_address} onMouseDown={onClick}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: "8px",
                }}
              >
                <img
                  src={item.logo || "https://etherscan.io/images/main/empty-token.png"}
                  alt="nologo"
                  width="24px"
                  height="24px"
                  style={{ borderRadius: "15px" }}
                />
                <p>{tokenStandard === 'erc20' ? item.symbol : item.name}</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <p>{item.token_address}</p>
                  <p style={{ alignSelf: "right" }}>
                    {((tokenStandard === 'erc20') && item.balance && item.decimals) ? `(${parseFloat(Number(Moralis.Units.FromWei(item.balance, item.decimals)).toFixed(6))})` : " "}
                  </p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <span role="img" aria-label="tear emoji">
          😪
        </span>{" "}
        <em>{tl(`No ${getTypeForTokenStandard(tokenStandard)} address, please insert`)}</em>
      </div>
    )
  };

  return (
    <SDropdown>
      <Input
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={input}
        placeholder={tl(`Select or input ${getTypeForTokenStandard(tokenStandard)} address`)}
        onFocus={onFocus}
        onBlur={onBlur}
        style={styles.input}
      />
      {showSuggestions && <SuggestionsListComponent />}
    </SDropdown>
  );
};

export default AutoComplete;
