import { useState, useEffect } from "react"
import styled from "styled-components";
import GasPumpRed from '../../public/assets/GasPumpRed.svg';
import GasPumpOrange from '../../public/assets/GasPumpOrange.svg';
import GasPumpGreen from '../../public/assets/GasPumpGreen.svg';


const RedLabel = styled.label`
  color: #FF0000;
`;

const OrangeLabel = styled.label`
  color: #FFAC33;
`;

const GreenLabel = styled.label`
  color: #008000;
`;

const GasImg = styled.img`
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
  margin: 2px;
  padding: 0px;
  draggable: false;
  data-emoji-text: ⛽️;
`;

// "#99AAB5"
// "#FFAC33"
// "#AAB8C2"
// "#BE1931"
// "#E1E8ED"
// "#66757F"

const GasTracker = () => {
  const ETHERSCAN_KEY = process.env.REACT_APP_ETHERSCAN_API_TOKEN
  const [gasPrice, setGasPrice] = useState(0)
  const TEN_SEC = 10000

  const fetchGasPrice = () => {
    fetch(`https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${ETHERSCAN_KEY}`)
      .then(response => response.json())
      .then(data => {
        const result = data.result
        const proposeGasPrice = result.ProposeGasPrice
        setGasPrice(parseInt(proposeGasPrice))
      })
      .catch(e => console.log('Gas price not updated'))
  }

  useEffect(() => {
    fetchGasPrice()  // Initial fetch
    const interval = setInterval(() => {
      fetchGasPrice()
    }, TEN_SEC);
    return () => clearInterval(interval);  // Clear the interval to prevent memory leaks when unmount.
  }, []);


  return (
    <>
      {gasPrice > 0 &&
        (gasPrice > 150 ?
          <div>
            <GasImg src={GasPumpRed} title='Gas price' alt='Gas price:'></GasImg>
            <RedLabel>{`${gasPrice} Gwei`}</RedLabel>          
          </div>
          : gasPrice >= 100 ?
          <div>
            <GasImg src={GasPumpOrange} title='Gas price' alt='Gas price:'></GasImg>
            <OrangeLabel>{`${gasPrice} Gwei`}</OrangeLabel>
          </div>
          : gasPrice > 80 ?
          <div>
            <GasImg src={GasPumpOrange} title='Gas price' alt='Gas price:'></GasImg>
            <OrangeLabel>{`\u00A0\u00A0${gasPrice} Gwei`}</OrangeLabel>
          </div>
          :
          <div>
            <GasImg src={GasPumpGreen} title='Gas price' alt='Gas price:'></GasImg>
            <GreenLabel>{`\u00A0\u00A0${gasPrice} Gwei`}</GreenLabel>
          </div>
        )
      }
    </>
  );
}

export default GasTracker;