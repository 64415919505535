import { DAI_CONTRACT, BATCHSENDER_CONTRACT, DOGIEFIGHTER_CONTRACT } from '../constants'
import axios, { AxiosInstance } from 'axios'
import BatchSenderAbi from "../abis/BatchSender.json";
import ERC20_ABI from "../abis/erc20.json";
import ERC721_ABI from "../abis/erc721.json";
import ERC1155_ABI from "../abis/erc1155.json";
import ERC1155_OPENSEA_ABI from "../abis/erc1155_opensea.json";


const api: AxiosInstance = axios.create({
  baseURL: 'https://api-rinkeby.etherscan.io',
  timeout: 30000, // 30 secs
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export function getBatchSenderContract(chainId: number, web3: any) {
  const contract_instance = new web3.eth.Contract(
    BATCHSENDER_CONTRACT[chainId].abi,
    BATCHSENDER_CONTRACT[chainId].address
  )
  return contract_instance
}

export function getDogieFighterContract(chainId: number, web3: any) {
  const contract_instance = new web3.eth.Contract(
    DOGIEFIGHTER_CONTRACT[chainId].abi,
    DOGIEFIGHTER_CONTRACT[chainId].address
  )
  return contract_instance
}


export function getDaiContract(chainId: number, web3: any) {
  const dai = new web3.eth.Contract(
    DAI_CONTRACT[chainId].abi,
    DAI_CONTRACT[chainId].address
  )
  return dai
}

export function callBalanceOf(address: string, chainId: number, web3: any) {
  return new Promise(async (resolve, reject) => {
    const dai = getDaiContract(chainId, web3)

    await dai.methods
      .balanceOf(address)
      .call(
        { from: '0x0000000000000000000000000000000000000000' },
        (err: any, data: any) => {
          if (err) {
            reject(err)
          }

          resolve(data)
        }
      )
  })
}

export function callTransfer(address: string, chainId: number, web3: any) {
  return new Promise(async (resolve, reject) => {
    const dai = getDaiContract(chainId, web3)

    await dai.methods
      .transfer(address, '1')
      .send({ from: address }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }

        resolve(data)
      })
  })
}

export async function getTokenContractFromAddressRemote(
  tokenAddress: string,
  chainId: number,
  web3: any
) {
  const etherscanToken = process.env.REACT_APP_ETHERSCAN_API_TOKEN
  //const response = await api.get(
  //  `/api?module=contract&action=getabi&address=${tokenAddress}&apikey=${etherscanToken}`
  //)
  const chainId_2_baseUrl: any = {
    1: 'mainnet',
    3: 'ropsten',
    4: 'rinkeby',
    5: 'goerli',
    42: 'kovan'
  }
  const response = await fetch(`https://${chainId_2_baseUrl[chainId]}.etherscan.io/api?module=contract&action=getabi&address=${tokenAddress}&apikey=${etherscanToken}`);
  const contractAbiJson = await response.json();
  const contractABI = JSON.parse(contractAbiJson.result);
  const tokenContract = new web3.eth.Contract(contractABI, tokenAddress)
  //this.abi = await abi.result
  return tokenContract
};

export function getERC20Contract(tokenAddress: string, web3: any) {
  return new web3.eth.Contract(ERC20_ABI, tokenAddress)
}

export function getERC721Contract(tokenAddress: string, web3: any) {
  return new web3.eth.Contract(ERC721_ABI, tokenAddress)
}

export function getERC1155Contract(tokenAddress: string, web3: any) {
  return new web3.eth.Contract(ERC1155_ABI, tokenAddress)
}

export function getERC1155OpenSeaContract(tokenAddress: string, web3: any) {
  return new web3.eth.Contract(ERC1155_OPENSEA_ABI, tokenAddress)
}


// export async function getERC20ContractFromAddress(
//   tokenAddress: string, 
//   web3: any
// ) {

//   const abi = ["function balanceOf(address owner) view returns (uint256)",
//     "function approve(address spender, uint256 amount) returns (bool)"]
//   const tokenContract = new web3.eth.Contract(abi, tokenAddress)
//   let balance = await tokenContract.methods.balanceOf(tokenAddress).call();

// //////////////////////////////////
// // The minimum ABI to get ERC20 Token balance
// let minABI = [
//   // balanceOf
//   {
//     "constant":true,
//     "inputs":[{"name":"_owner","type":"address"}],
//     "name":"balanceOf",
//     "outputs":[{"name":"balance","type":"uint256"}],
//     "type":"function"
//   },
//   // decimals
//   {
//     "constant":true,
//     "inputs":[],
//     "name":"decimals",
//     "outputs":[{"name":"","type":"uint8"}],
//     "type":"function"
//   }
// ];

// // Get ERC20 Token contract instance
// let contract = web3.eth.contract(minABI).at(tokenAddress);

// // Call balanceOf function
// contract.balanceOf(walletAddress, (error, balance) => {
//   // Get decimals
//   contract.decimals((error, decimals) => {
//     // calculate a balance
//     balance = balance.div(10**decimals);
//   });
// });

// ////
// (async () => {
//   let balance = await UniswapToken.methods.balanceOf("THE_ADDRESS_YOU_WANT_THE_BALANCE_OF_HERE").call();
// })();
// //////////////////////////////////

//   return tokenContract
// };


// export function getTokenContractFromAddress(
//   tokenAddress: string, 
//   tokenStandard: string,
//   web3: any
// ) {
//   if (tokenStandard === 'eth') {
//     return null
//   }
//   let contractABI = null
//   if (tokenStandard === 'erc20') {
//     contractABI = ERC20_ABI;
//   } else {
//     contractABI = ERC721_ABI;
//   }
//   const tokenContract = new web3.eth.Contract(contractABI, tokenAddress)
//   return tokenContract      
// }

