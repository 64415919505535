import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { subTwoHexStrings } from "../helpers/utilities";


const getWalletReferrals = /* GraphQL */ `
  query GetWallet($chainId: Int!, $address: ID!) {
    getWallet(chainId: $chainId, address: $address) {
      address
      refereesByChainByEarnings(filter: {chainId: {eq: $chainId}}) {
        items {
          chainId
          refereeAddress
          accEarnings
          lastTxTimestamp
          createdAt
          updatedAt
        }
      }
      referrer
      totalValue
      paid
      accEarnings
    }
  }
`;

export const useBTReferrals = (provider, web3, chainId, walletAddress) => {
    const [referrals, setReferrals] = useState([])
    const [totalValue, setTotalValue] = useState('');
    const [pending, setPending] = useState('');
    const [paid, setPaid] = useState('');
    const [accEarnings, setAccEarnings] = useState('');
    //const { walletAddress } = props

    useEffect(() => {
      if (provider?.on && web3 && walletAddress) {
        fetchReferrals();
      }
    }, [provider, web3, walletAddress]);

    async function fetchReferrals() {
      try {
        const referralData = await API.graphql(graphqlOperation(getWalletReferrals, { chainId: chainId, address: walletAddress.toLowerCase() }));
        const data = referralData.data.getWallet;
        if (!data) return;
        const refs = data.refereesByChainByEarnings.items;
        setReferrals(refs);

        setTotalValue(data.totalValue)
        setPending(subTwoHexStrings(data.accEarnings, data.paid, web3))
        setPaid(data.paid)
        setAccEarnings(data.accEarnings)
        return refs;
      } catch (err) {
        //console.log('Error fetching referrals:', err)
      }
    };

    return { fetchReferrals, referrals, totalValue, pending, paid, accEarnings}
};