import { useCallback, useEffect, useContext } from 'react';
import useState from 'react-usestateref';
import { COLLECT_ADDRESS } from '../constants/wallets'  // DO NOT CHANGE THIS LINE
import { SocialIcon } from "react-social-icons";
import { BATCHSENDER_CONTRACT } from "../constants";
import styled from "styled-components";
import { fonts, colors } from "../styles";
import WeChatQR from '../assets/wechatQR.png';
import { isMobile } from "../utils/userAgent";
import { LangContext } from '../context/lang';
import { Row, Button, Modal } from 'antd';
import { HeartOutlined } from "@ant-design/icons";
import DonateSelector from "./DonateSelector";
import { etherToWei, getCoinPrice, getmaxFeePerGas, getEtherscanUrl, chainId_ADDR, chainId_PRICE } from "../helpers/utilities";
import { STitle } from "../styles/SComponents";

import ReactGA from "react-ga4";
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import '../styles/referralStyles.less';

ReactGA.initialize("G-7J4MBTF15E");

const SFooter = styled.div`
  margin-top: 1rem;
  margin-bottom: 12px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  bottom: 0;
  font-size: ${fonts.size.medium};
`;

const SQRDiv = styled.div`
margin-bottom: 180px;
`;

const SContractDiv = styled.div`
  width: 270px;
  margin-left: 35px;
  margin-bottom: 40px;
  justify-self: left;
  text-align: left;
`

const SDonateDiv = styled.div`
  margin-bottom: 40px;
`

const SSocialWrapper = styled.div`
  width: 244px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin-right: 35px;
  margin-bottom: 40px;
  & span {
    color: rgb(${colors.weiBlue});
    font-weight: ${fonts.weight.bold};
    font-size: ${fonts.size.h5};
    margin-left: 12px;
  }
`

const Title = styled.p`
  margin: 0px;
`
const SContract = styled.p`
  margin: 0px;
  font-size: 12px;
`
const SReferralLinkBar = styled.div`
  width: 100%;
  /* Geek Blue / geekblue-2 */
  background: #D6E4FF;
  margin-bottom: 46px;
  border-radius: 20px;
  padding: 8px 20px 8px 32px; 
  justify-content: space-between;
  font-size: ${fonts.size.small};
`;

const styles: any = {
  centerDiv: {
    marginTop: '40px',
    marginBottom: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  centerDivDonate: {
    marginTop: '55px',
    marginBottom: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  copyButton: {
    marginTop: '3px',
    marginBottom: '3px',
  },
  donateDiv: {
    marginBottom: '32px',
  },
  donateSelector: {
    marginLeft: '20px',
    marginRight: '10px',
  },
  donateButton: {
    marginRight: '15px',
  },

  social: {
    height: 36,
    width: 36,
    // opacity: 0.3,
  },
};

interface IFooterProps {
  web3Provider?: any;
  address?: string;
  chainId?: number;
  connectWallet: () => void;
}

const Footer = (props: IFooterProps) => {
  const { dispatch: { tl } } = useContext(LangContext);
  const { web3Provider, address: addr, chainId: cId, connectWallet } = props

  const [web3, setWeb3, web3Ref] = useState(web3Provider)
  const [address, setAddress, addressRef] = useState(addr)
  const [chainId, setChainId, chainIdRef] = useState(cId)
  const [donates, setDonates, donatesRef] = useState(0);
  const [txHash, setTxHash] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [showQR, setshowQR] = useState(false)

  useEffect(() => {
    setWeb3(web3Provider);
    setAddress(addr);
    setChainId(cId);
  }, [web3Provider, addr, cId])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(COLLECT_ADDRESS)  // DO NOT CHANGE THIS LINE
    ReactGA.event('copy_donate_address');
  }

  function handleSelectDonateAmount(val: any) {
    setDonates(val);
  }

  const sendDonation = useCallback(async function () {
    ReactGA.event('click_donate_button')
    setTxHash('')

    const web3 = web3Ref.current
    const address = addressRef.current
    const chainId = chainIdRef.current
    if (!address || !chainId || !web3) {
      setModalVisible(false)
      connectWallet()
      return
    }

    try {
      // @ts-ignore
      const sendTransaction = (_tx: any) => {
        return new Promise((resolve, reject) => {
          web3.eth.sendTransaction(_tx)
            .once('transactionHash', (txHash: string) => {
              setTxHash(txHash);
            })
            .then((txReceipt: any) => resolve(txReceipt))
            .catch((err: any) => reject(err));
        });
      }

      const tips = donatesRef.current <= 0 ? 1 : donatesRef.current
      var tipVal = 0
      try { // TODO: Check all coin prices are valid. If cannot find coinprice, set a default.
        // const symbol = (chainId ? chainId_SYMBOL.get(chainId) : chainId_SYMBOL.get(1)) ?? 'ETH-USD'
        const cAddr = (chainId ? chainId_ADDR.get(chainId) : chainId_ADDR.get(1)) ?? '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
        const defaultPrice = (chainId ? chainId_PRICE.get(chainId) : chainId_PRICE.get(1)) ?? 2000
        // const coinPrice = await getCoinBasePrice(symbol, defaultPrice)
        const coinPrice = await getCoinPrice(cAddr, defaultPrice)
        // console.log('My coin price = ', coinPrice)

        // ////
        // const ads = [1, 56, 137, 43114, 250, 128, 25, 1284, 1285]
        // for (let i = 0; i < ads.length; i++) {
        //   const coinPrice = await getCoinPrice(chainId_ADDR.get(ads[i]) ?? '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', defaultPrice)
        //   console.log('Chain = ', ads[i])
        //   console.log('coinPrice = ', coinPrice)
        // }
        // ////

        tipVal = tips / coinPrice
      } catch (error: any) {
      }

      const decimals = chainId === 25 ? 8 : 18  // TODO: Check CRO decimals is 8
      const weiValue = etherToWei(tipVal, decimals, web3)
      // const weiValue = web3.utils.toWei(tipVal.toFixed(18), 'ether')
      const value = web3.utils.toHex(weiValue)
      const gasDict = await getmaxFeePerGas(chainId, web3)
      const maxFeePerGas = gasDict.maxFeePerGas
      var tx = null
      if (maxFeePerGas === null) {
        tx = {
          from: address,
          to: COLLECT_ADDRESS,
          value: value,
        }

      } else {
        const maxPriorityFeePerGas = gasDict.maxPriorityFeePerGas
        const maxFeePerGasHex = maxFeePerGas ? web3.utils.toHex(maxFeePerGas) : null
        const maxPriorityFeePerGasHex = maxPriorityFeePerGas ? web3.utils.toHex(maxPriorityFeePerGas) : null
        tx = {
          from: address,
          to: COLLECT_ADDRESS,
          value: value,
          maxFeePerGas: maxFeePerGasHex,
          maxPriorityFeePerGas: maxPriorityFeePerGasHex,
        }
      }
      const receipt: any = await sendTransaction(tx)
      // TODO: CRO has this error: MetaMask - RPC Error: Invalid transaction params: params specify an EIP-1559 transaction but the current network does not support EIP-1559 {code: -32602, message: 'Invalid transaction params: params specify an EIP-…but the current network does not support EIP-1559'}

      if (receipt && receipt.blockNumber) {
        setTxHash(receipt.transactionHash)
      }

    } catch (error: any) {
      // console.log(error)
    }
  }, []);

  return (
    !isMobile ?
      <>
        <SFooter>
          <SQRDiv></SQRDiv>
          <SQRDiv>{showQR ? <img src={WeChatQR} alt='WeChat QR Code' width='140px' height='140px' /> : null}</SQRDiv>
        </SFooter>

        <SFooter>
          {/* <SContractAddressLabel>
          <p>BatchTransfer Tool Contract: <a href={getEtherscanUrl(chainId, 'address', BATCHSENDER_CONTRACT[chainId].address)} target="_blank" rel="noopener noreferrer"><u>{chainId ? chainId_name.get(chainId) : ''}</u></a></p>
        </SContractAddressLabel> */}
          {/* <SContractAddressLabel>
          <p>BatchTransfer Tool Contract: <a href='https://blockscan.com/address/0xBA6bba18d0711676175bA1464991980D12C7585C' target="_blank" rel="noopener noreferrer"><u>0xBA6bba18d0711676175bA1464991980D12C7585C</u></a></p>
        </SContractAddressLabel> */}
          <div>
            <SContractDiv>
              <Title>{`BatchTransfer ${tl('Contract (19 networks) ')}`}</Title>
              <SContract><a href='https://blockscan.com/address/0xBA6bba18d0711676175bA1464991980D12C7585C' target="_blank" rel="noopener noreferrer"><u>0xBA6bba18d0711676175bA1464991980D12C7585C</u></a></SContract>
            </SContractDiv>
          </div>

          <SDonateDiv>
            <Button type='primary' shape='round' icon={<HeartOutlined />} onClick={() => setModalVisible(true)}>
              {tl('Donate')}
            </Button>
            <Modal
              // title={tl('Your donations help us maintain the service')}
              centered
              footer={null}
              visible={modalVisible}
              onOk={() => setModalVisible(false)}
              onCancel={() => setModalVisible(false)}
            >
              <div style={styles.centerDiv}>
                <STitle>{tl('Your donations help us maintain the service')}</STitle>
              </div>
              <p style={{ fontSize: '16px' }}><b>{tl('Donation address:')}</b></p>
              <SReferralLinkBar>
                <Row justify='space-between' align='middle'>
                  <p style={{ fontSize: '13px' }}>{COLLECT_ADDRESS}</p>  {/* DO NOT CHANGE THIS LINE */}
                  <Button shape='round' type='primary' onClick={copyToClipboard}>
                    {tl('Copy')}
                  </Button>
                </Row>
              </SReferralLinkBar>
              <p style={{ fontSize: '16px' }}><b>{tl('We accept ETH, BNB, USDT, USDC, BUSD, MATIC, AVAX, FTM, HT, GLMR, and MOVR.')}</b></p>

              <div style={styles.donateDiv}>
                <Row justify='center' align='middle'>
                  {/* <div style={styles.donateSelector}> */}
                    <DonateSelector onSelectDonates={handleSelectDonateAmount} defaultDonates={1} />
                  {/* </div> */}
                </Row>
                <div style={styles.centerDivDonate}>
                  <Button type='primary' shape='round' icon={<HeartOutlined />} onClick={sendDonation}>
                    {tl('Donate')}
                  </Button>
                </div>

              </div>
              {txHash &&
                <div style={styles.centerDiv}>
                  <p>{tl('Thank you for your support! Here is your receipt:')}</p>
                  <p style={{ fontSize: '13px' }}><a href={getEtherscanUrl(chainId, 'tx', txHash)} target="_blank" rel="noopener noreferrer"><u>{txHash}</u></a></p>
                </div>
              }
            </Modal>
          </SDonateDiv>

          <SSocialWrapper>
            <SocialIcon url='https://twitter.com/batchtransfer' style={styles.social} />
            <SocialIcon url='https://discord.gg/Pe3BCSwz8T' style={styles.social} />
            <SocialIcon network='telegram' url='https://t.me/BatchTransfer' style={styles.social} />
            <SocialIcon url='https://www.youtube.com/channel/UCtN6CbKdwHGjn1UVG0nKqlg' style={styles.social} />
            <div
              onMouseEnter={e => { setshowQR(true) }}
              onMouseLeave={e => { setshowQR(false) }}
            >
              <SocialIcon network='wechat' style={styles.social} />
            </div>
          </SSocialWrapper>
        </SFooter>
      </> : null
  );
};

export default Footer;
