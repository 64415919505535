/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      chainId
      txHash
      sender
      referrer
      tokenType
      tokenAddress
      numberOfReceivers
      tokenAmount
      value
      commission
      discountRate
      referralBonusRate
      description
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      chainId
      txHash
      sender
      referrer
      tokenType
      tokenAddress
      numberOfReceivers
      tokenAmount
      value
      commission
      discountRate
      referralBonusRate
      description
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      chainId
      txHash
      sender
      referrer
      tokenType
      tokenAddress
      numberOfReceivers
      tokenAmount
      value
      commission
      discountRate
      referralBonusRate
      description
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createWallet = /* GraphQL */ `
  mutation CreateWallet(
    $input: CreateWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    createWallet(input: $input, condition: $condition) {
      chainId
      address
      transactions {
        items {
          chainId
          txHash
          sender
          referrer
          tokenType
          tokenAddress
          numberOfReceivers
          tokenAmount
          value
          commission
          discountRate
          referralBonusRate
          description
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      refereesByChainByLastTxTimestamp {
        items {
          chainId
          referrerAddress
          refereeAddress
          accEarnings
          lastTxTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      refereesByChainByEarnings {
        items {
          chainId
          referrerAddress
          refereeAddress
          accEarnings
          lastTxTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      referrer
      totalValue
      paid
      accEarnings
      ownerships {
        items {
          id
          chainId
          walletAddress
          tokenAddress
          balance
          didApprove
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWallet = /* GraphQL */ `
  mutation UpdateWallet(
    $input: UpdateWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    updateWallet(input: $input, condition: $condition) {
      chainId
      address
      transactions {
        items {
          chainId
          txHash
          sender
          referrer
          tokenType
          tokenAddress
          numberOfReceivers
          tokenAmount
          value
          commission
          discountRate
          referralBonusRate
          description
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      refereesByChainByLastTxTimestamp {
        items {
          chainId
          referrerAddress
          refereeAddress
          accEarnings
          lastTxTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      refereesByChainByEarnings {
        items {
          chainId
          referrerAddress
          refereeAddress
          accEarnings
          lastTxTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      referrer
      totalValue
      paid
      accEarnings
      ownerships {
        items {
          id
          chainId
          walletAddress
          tokenAddress
          balance
          didApprove
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWallet = /* GraphQL */ `
  mutation DeleteWallet(
    $input: DeleteWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    deleteWallet(input: $input, condition: $condition) {
      chainId
      address
      transactions {
        items {
          chainId
          txHash
          sender
          referrer
          tokenType
          tokenAddress
          numberOfReceivers
          tokenAmount
          value
          commission
          discountRate
          referralBonusRate
          description
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      refereesByChainByLastTxTimestamp {
        items {
          chainId
          referrerAddress
          refereeAddress
          accEarnings
          lastTxTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      refereesByChainByEarnings {
        items {
          chainId
          referrerAddress
          refereeAddress
          accEarnings
          lastTxTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      referrer
      totalValue
      paid
      accEarnings
      ownerships {
        items {
          id
          chainId
          walletAddress
          tokenAddress
          balance
          didApprove
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReferralRelationship = /* GraphQL */ `
  mutation CreateReferralRelationship(
    $input: CreateReferralRelationshipInput!
    $condition: ModelReferralRelationshipConditionInput
  ) {
    createReferralRelationship(input: $input, condition: $condition) {
      chainId
      referrerAddress
      refereeAddress
      accEarnings
      lastTxTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateReferralRelationship = /* GraphQL */ `
  mutation UpdateReferralRelationship(
    $input: UpdateReferralRelationshipInput!
    $condition: ModelReferralRelationshipConditionInput
  ) {
    updateReferralRelationship(input: $input, condition: $condition) {
      chainId
      referrerAddress
      refereeAddress
      accEarnings
      lastTxTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteReferralRelationship = /* GraphQL */ `
  mutation DeleteReferralRelationship(
    $input: DeleteReferralRelationshipInput!
    $condition: ModelReferralRelationshipConditionInput
  ) {
    deleteReferralRelationship(input: $input, condition: $condition) {
      chainId
      referrerAddress
      refereeAddress
      accEarnings
      lastTxTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createToken = /* GraphQL */ `
  mutation CreateToken(
    $input: CreateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    createToken(input: $input, condition: $condition) {
      chainId
      token_address
      contract_type
      symbol
      name
      logo
      balance
      decimals
      ownerships {
        items {
          id
          chainId
          walletAddress
          tokenAddress
          balance
          didApprove
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateToken = /* GraphQL */ `
  mutation UpdateToken(
    $input: UpdateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    updateToken(input: $input, condition: $condition) {
      chainId
      token_address
      contract_type
      symbol
      name
      logo
      balance
      decimals
      ownerships {
        items {
          id
          chainId
          walletAddress
          tokenAddress
          balance
          didApprove
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteToken = /* GraphQL */ `
  mutation DeleteToken(
    $input: DeleteTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    deleteToken(input: $input, condition: $condition) {
      chainId
      token_address
      contract_type
      symbol
      name
      logo
      balance
      decimals
      ownerships {
        items {
          id
          chainId
          walletAddress
          tokenAddress
          balance
          didApprove
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWalletTokenRelationship = /* GraphQL */ `
  mutation CreateWalletTokenRelationship(
    $input: CreateWalletTokenRelationshipInput!
    $condition: ModelWalletTokenRelationshipConditionInput
  ) {
    createWalletTokenRelationship(input: $input, condition: $condition) {
      id
      chainId
      walletAddress
      tokenAddress
      balance
      didApprove
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateWalletTokenRelationship = /* GraphQL */ `
  mutation UpdateWalletTokenRelationship(
    $input: UpdateWalletTokenRelationshipInput!
    $condition: ModelWalletTokenRelationshipConditionInput
  ) {
    updateWalletTokenRelationship(input: $input, condition: $condition) {
      id
      chainId
      walletAddress
      tokenAddress
      balance
      didApprove
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteWalletTokenRelationship = /* GraphQL */ `
  mutation DeleteWalletTokenRelationship(
    $input: DeleteWalletTokenRelationshipInput!
    $condition: ModelWalletTokenRelationshipConditionInput
  ) {
    deleteWalletTokenRelationship(input: $input, condition: $condition) {
      id
      chainId
      walletAddress
      tokenAddress
      balance
      didApprove
      timestamp
      createdAt
      updatedAt
    }
  }
`;
