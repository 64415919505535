import React from "react";
import { materialShadow } from "../styles/shadows";
import styled from "styled-components";
import { colors } from "../styles";
import { Statistic, Row } from "antd";
import { chainId_coin, getTokenStandard, isChargeChain, isTipChain } from "../helpers/utilities";
import { ApproveStatus, trackNftRai, trackWeiX } from '../pages/Sender'
// import NFTrai from '../assets/nftraiMedium.png';
// import WeiX from '../assets/weixMedium.png';


const SCenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SSummaryCard = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const SSendNote = styled.p`
  color: rgb(${colors.weiBlue});
`;

const SLabel = styled.label`
  color: rgb(${colors.weiBlue});
`;

const styles = {
  raiIcon: {
    width: "24px",
    height: "24px",
    marginRight: '4px',
  },
  wrapAdsDiv: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  adsDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dappDiv: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    alignItems: "center",
    justifyContent: "center",
    // border: "solid",
  },
  dappImg: {
    width: "100px",
    height: "100px",
  },
};

const CardIcon = ({ title, href }) => (
  <a href={href || `#${title}`}>
    <i class="material-icons">{title}</i>
  </a>
);

const tokenAddressStyle = {
  position: "relative",
  width: "90%",
  padding: "4px",
  margin: "0 5px",
  display: "flex",
  flexDirection: "column",
  fontSize: "16px",
}

function getTokenDescription(chainId, tokenStandard, tokenAddress) {
  if (tokenStandard === 'eth') {
    return chainId ? chainId_coin.get(chainId) : ' '
  }
  return tokenAddress
}

const Card = ({ chainId, approveSendState, tokenStandard, tokenName, tokenAddress, numberOfReceivers, totalAmountOfTokens, discountRate, tl }) => {
  const isCharge = isChargeChain(chainId)
  return (
    <SCenterDiv>
      <SSummaryCard>
        <header style={{ alignSelf: 'center' }}>
          <h6>{approveSendState === ApproveStatus.APPROVED ? tl('You are about to send') : (approveSendState === ApproveStatus.COMPLETED ? tl('You have successfully sent') : tl('Summary'))}</h6>
        </header>
        {/* <Row style={{ maxWidth: "90%" }}> */}
        <Row>
          <Statistic
            valueStyle={tokenAddressStyle}
            title={`${getTokenStandard(chainId, tokenStandard)}` + (tokenName ? ` - ${tokenName}` : '')}
            value={getTokenDescription(chainId, tokenStandard, tokenAddress)}
          />
        </Row>
        <Row>
          <Statistic
            title={tl('Number of receivers')}
            value={numberOfReceivers}
            valueStyle={tokenAddressStyle}
          />
        </Row>
        <Row>
          <Statistic
            title={tl('Amount of tokens')}
            value={totalAmountOfTokens}
            valueStyle={tokenAddressStyle}
          />
        </Row>
        {!isCharge &&
          <Row>
            <Statistic
              title={tl('Commission')}
              value={0}
              valueStyle={tokenAddressStyle}
            />
          </Row>
        }
        {isCharge && discountRate > 0 &&
          <Row>
            <Statistic
              title={tl('Discount')}
              value={discountRate + '%'}
              valueStyle={tokenAddressStyle}
            />
          </Row>
        }
        {/* {txHash && 
        <Row>
          <Statistic 
            title='Tx hash' 
            value={txHash}
          />    
        </Row>
      } */}

        {approveSendState === ApproveStatus.COMPLETED ?
          <div style={styles.wrapAdsDiv}>
            <SSendNote><b>{tl('Click the Referrals tab to start earning.')}</b></SSendNote>
            {/* <SLabel><b>{tl('Check out our Free Swap, and NFT Mint:')}</b></SLabel>
            <div style={styles.adsDiv}>
              <div style={styles.dappDiv}>
                <a href='https://www.weix.io' onClick={trackWeiX} target='_blank' rel='noopener noreferrer'>
                  <img src={WeiX} title='DeFi with Free Limit Order - WeiX.io' alt='DeFi Crypto Trading with Free Limit Order - WeiX.io' style={styles.dappImg} />
                </a>
                <a href='https://www.weix.io' onClick={trackWeiX} target='_blank' rel='noopener noreferrer'>
                  <label>{`${tl('Free Swap')} - WeiX.io`}</label>
                </a>
              </div>
              <div style={styles.dappDiv}>
                <a href='https://www.nftrai.io' onClick={trackNftRai} target='_blank' rel='noopener noreferrer'>
                  <img src={NFTrai} title='NFT AI Analytics and TradingView - nftrai.io' alt='NFT AI Analytics and TradingView, Discover Next Blue Chip NFT - nftrai.io' style={styles.dappImg} />
                </a>
                <a href='https://www.nftrai.io' onClick={trackNftRai} target='_blank' rel='noopener noreferrer'>
                  <label>{`${tl('Free Mint')} - NFTrai.io`}</label>
                </a>
              </div>
            </div> */}
          </div>
          :
          approveSendState === ApproveStatus.APPROVED ?
            <div>
              <SSendNote><b>{tl('Click the Batch Transfer button below to proceed.')}</b></SSendNote>
              {isTipChain(chainId) && <SSendNote><b>{tl('Thank you for tipping us to help maintain the service.') + '😊'}</b></SSendNote>}
            </div>
            :
            tokenStandard === 'erc20' ?
              <SSendNote><b>{tl('You need to approve this token before Batch Transfer.')}</b></SSendNote>
              :
              <SSendNote><b>{tl('You need to approve this token only once before Batch Transfer.')}</b></SSendNote>
        }
        <footer>
          <div>
          </div>
        </footer>
      </SSummaryCard>
    </SCenterDiv>
  )
};

Card.defaultProps = {
  shadowDepth: 2,
  shadowHoverDepth: 3
};

const StyledCard = styled.article`
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  border-radius: 0.5rem;
  transition: 300ms ease;
  ${materialShadow.base}

  ul {
    list-style: square;
  }

  ul li::before {
    content: "\2022";
    color: "red";
  }

  h1 {
    margin-bottom: 0;
  }
  img {
    width: 100%;
    border-radius: 0.2em;
  }

  > section:after {
    content: " ";
    display: block;
    height: 1px;
    width: 100%;
    background-color: #ccc;
  }

  p {
    max-width: 80%;
    line-height: 1.45em;
    color: #666;
  }

  footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 1em;
    p {
      color: #aaa;
      display: inline;
    }

    a {
      text-decoration: none;
      color: inherit;
      padding: 0 1em;
      color: #444;

      :last-child {
        padding-right: 0;
      }
    }
  }

  &:hover {
    ${materialShadow.hover}
    img {
      filter: grayscale(0) blur(0);
    }
  }
`;

export default Card;
