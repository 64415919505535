import * as ethUtil from 'ethereumjs-util'
import { IChainData } from './types'
import supportedChains from './chains'
import { greaterThanOrEqual } from './bignumber'
import { getBatchSenderContract, getERC20Contract, getERC721Contract, getERC1155Contract, getERC1155OpenSeaContract } from './web3'
import { BATCHSENDER_CONTRACT } from '../constants'
import Web3 from 'web3'
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

const POLYGONSCAN_KEY = process.env.REACT_APP_POLYGONSCAN_API_TOKEN
const API_KEY = process.env.REACT_APP_INFURA_ID
const MORALIS_KEY = process.env.REACT_APP_MORALIS_KEY ?? ''
var bluePromise = require('bluebird')
const axios = require('axios')
const Caver = require('caver-js')

export const showNFTrai = false
export const NetworkError = 'NetworkError'

const chainId_link = new Map<number | undefined, string>([
  [3, 'ropsten'],
  [4, 'rinkeby'],
  [5, 'goerli'],
  [42, 'kovan'],
]);

export const chainId_fee = new Map<number | undefined, string>([
  [1, '2'],
  [3, '5'],
  [1284, '1.5'],
  [1285, '1.5'],
]);

export const supportedChainIds = [1, 3, 4, 5, 42, 56, 97, 137, 80001, 43114, 43113, 250, 4002, 128, 256, 25, 8217, 1001, 100, 1313161554, 42220, 32659, 1284, 1285, 1287, 10, 420, 42161, 421611, 10001, 513100]
export const cyclicChainIds = [1, 56, 137, 43114, 250, 128, 25, 8217, 100, 42220, 32659, 1284, 1285, 10001, 513100]
export const tipChainIds = [137, 80001]
export const chargeChainIds = [1, 3, 4, 5, 42, 56, 97, 43114, 43113, 10001, 513100, 10, 420, 42161, 421611, 100, 250, 25, 4002, 42220]
const deflERC20ChainIds = [56, 97, 137, 80001]

export const chainId_map = new Map<number | undefined, any>([
  [0, '0x0'],
  [1, '0x1'],
  [3, '0x3'],
  [4, '0x4'],
  [5, '0x5'],
  [42, '0x2a'],
  [56, '0x38'],
  [97, '0x61'],
  [137, '0x89'],
  [80001, '0x13881'],
  [43114, '0xa86a'],
  [43113, '0xa869'],
  [250, '0xfa'],  // Moralis cannot get ERC20 balance for FTM
  [4002, '0xfa2'],  // FTM does not have ERC721 or ERC1155
  [128, '0x80'],
  [256, '0x100'],
  [25, '0x19'],
  [8217, '0x2019'],
  [1001, '0x3e9'],
  [100, '0x64'],
  [1284, '0x504'],
  [1285, '0x505'],
  [1287, '0x507'],
  [10, '0xa'],
  [1313161554, '0x4e454152'],
  [42220, '0xa4ec'],
  [32659, '0x7f93'],
  [420, '0x1a4'],
  [42161, '0xa4b1'],
  [421611, '0x66eeb'],
  [10001, '0x2711'],
  [513100, '0x7d44c'],
]);

export const chainId_maxRows = new Map<number | undefined, number[]>([
  [0, [600, 500, 300, 600]],
  [1, [600, 500, 300, 600]],
  [3, [600, 500, 300, 600]],
  [4, [600, 500, 300, 600]],
  [5, [600, 500, 300, 600]],
  [42, [600, 500, 300, 600]],
  [56, [1000, 600, 600, 1000]],
  [97, [1000, 600, 600, 1000]],
  [137, [600, 600, 300, 600]],
  [80001, [600, 600, 300, 600]],
  [43114, [240, 240, 240, 150]],
  [43113, [240, 240, 240, 150]],
  [250, [600, 600, 240, 400]],
  [4002, [600, 600, 240, 400]],
  [128, [800, 800, 400, 800]],
  [256, [800, 800, 400, 800]],
  [25, [800, 800, 400, 800]],
  [8217, [600, 600, 300, 600]],
  [1001, [600, 600, 300, 600]],
  [100, [300, 300, 150, 300]],
  [1313161554, [600, 600, 300, 600]],
  [42220, [400, 400, 200, 400]],
  [32659, [300, 300, 150, 300]],
  [1284, [300, 300, 150, 300]],
  [1285, [300, 300, 150, 300]],
  [1287, [300, 300, 150, 300]],
  [10, [600, 600, 300, 600]],
  [420, [600, 600, 300, 600]],
  [42161, [600, 600, 300, 600]],
  [421611, [600, 600, 300, 600]],
  [10001, [600, 500, 300, 600]],
  [513100, [600, 500, 300, 600]],
]);

export const chainId_name = new Map<number | undefined, string>([
  [0, 'Unsupported Chain'],
  [1, 'Ethereum Mainnet'],
  [3, 'Ethereum Ropsten'],
  [4, 'Ethereum Rinkeby'],
  [5, 'Ethereum Goerli'],
  [42, 'Ethereum Kovan'],
  [56, 'BNB Smart Chain'],
  [97, 'BSC Testnet'],
  [137, 'Polygon Mainnet'],
  [80001, 'Polygon Mumbai'],
  [43114, 'Avalanche Mainnet'],
  [43113, 'Avalanche Fuji'],
  [250, 'Fantom Opera'],
  [4002, 'Fantom Testnet'],
  [128, 'Heco Mainnet'],
  [256, 'Heco Testnet'],
  [25, 'Cronos Mainnet'],
  [8217, 'Klaytn'],
  [1001, 'Klaytn Testnet'],
  [100, 'Gnosis'],
  [1313161554, 'Aurora'],
  [42220, 'Celo Mainnet'],
  [32659, 'Fusion'],
  [1284, 'Moonbeam'],
  [1285, 'Moonriver'],
  [1287, 'Moonbase Alphanet'],
  [10, 'Optimism Mainnet'],
  [420, 'Optimism Goerli'],
  [42161, 'Arbitrum'],
  [421611, 'Arbitrum Rinkeby'],
  [10001, 'Ethereum PoW'],
  [513100, 'Ethereum Fair'],
]);

export const chainId_coin = new Map<number | undefined, string>([
  [0, 'Unsupported Chain'],
  [1, 'Ethereum'],
  [3, 'Ethereum'],
  [4, 'Ethereum'],
  [5, 'Ethereum'],
  [42, 'Ethereum'],
  [56, 'BNB Coin'],
  [97, 'BNB Coin'],
  [137, 'Polygon'],
  [80001, 'Polygon'],
  [43114, 'Avalanche'],
  [43113, 'Avalanche'],
  [250, 'Fantom'],
  [4002, 'Fantom'],
  [128, 'Heco'],
  [256, 'Heco'],
  [25, 'Cronos'],
  [8217, 'Klaytn'],
  [1001, 'Klaytn'],
  [100, 'Gnosis'],
  [1313161554, 'Aurora'],
  [42220, 'Celo'],
  [32659, 'Fusion'],
  [1284, 'Moonbeam'],
  [1285, 'Moonriver'],
  [1287, 'Moonbase'],
  [10, 'Optimism'],
  [420, 'Optimism'],
  [42161, 'Arbitrum'],
  [421611, 'Arbitrum'],
  [10001, 'EthereumPoW'],
  [513100, 'EthereumFair'],
]);

export const chainId_ETH = new Map<number | undefined, string>([
  [0, 'ETH'],
  [1, 'ETH'],
  [3, 'ETH'],
  [4, 'ETH'],
  [5, 'ETH'],
  [42, 'ETH'],
  [56, 'BNB'],
  [97, 'BNB'],
  [137, 'MATIC'],
  [80001, 'MATIC'],
  [43114, 'AVAX'],
  [43113, 'AVAX'],
  [250, 'FTM'],
  [4002, 'FTM'],
  [128, 'HT'],
  [256, 'HTT'],
  [25, 'CRO'],
  [8217, 'KLAY'],
  [1001, 'KLAY'],
  [100, 'xDAI'],
  [1313161554, 'ETH'],
  [42220, 'CELO'],
  [32659, 'FSN'],
  [1284, 'GLMR'],
  [1285, 'MOVR'],
  [1287, 'DEV'],
  [10, 'ETH'],
  [420, 'ETH'],
  [42161, 'ETH'],
  [421611, 'ETH'],
  [10001, 'ETHW'],
  [513100, 'ETHF'],
]);

export const chainId_ERC20 = new Map<number | undefined, string>([
  [0, 'ERC20'],
  [1, 'ERC20'],
  [3, 'ERC20'],
  [4, 'ERC20'],
  [5, 'ERC20'],
  [42, 'ERC20'],
  [56, 'BEP20'],
  [97, 'BEP20'],
  [137, 'ERC20'],
  [80001, 'ERC20'],
  [43114, 'ERC20'],
  [43113, 'ERC20'],
  [250, 'ERC20'],
  [4002, 'ERC20'],
  [128, 'HRC20'],
  [256, 'HRC20'],
  [25, 'CRC20'],
  [8217, 'ERC20'],
  [1001, 'ERC20'],
  [100, 'ERC20'],
  [1313161554, 'ERC20'],
  [42220, 'ERC20'],
  [32659, 'FRC20'],
  [1284, 'ERC20'],
  [1285, 'ERC20'],
  [1287, 'ERC20'],
  [10, 'ERC20'],
  [420, 'ERC20'],
  [42161, 'ERC20'],
  [421611, 'ERC20'],
  [10001, 'ERC20'],
  [513100, 'ERC20'],
]);

export const chainId_ERC721 = new Map<number | undefined, string>([
  [0, 'ERC721'],
  [1, 'ERC721'],
  [3, 'ERC721'],
  [4, 'ERC721'],
  [5, 'ERC721'],
  [42, 'ERC721'],
  [56, 'BEP721'],
  [97, 'BEP721'],
  [137, 'ERC721'],
  [80001, 'ERC721'],
  [43114, 'ERC721'],
  [43113, 'ERC721'],
  [250, 'ERC721'],
  [4002, 'ERC721'],
  [128, 'HRC721'],
  [256, 'HRC721'],
  [25, 'CRC721'],
  [8217, 'ERC721'],
  [1001, 'ERC721'],
  [100, 'ERC721'],
  [1313161554, 'ERC721'],
  [42220, 'ERC721'],
  [32659, 'FRC721'],
  [1284, 'ERC721'],
  [1285, 'ERC721'],
  [1287, 'ERC721'],
  [10, 'ERC721'],
  [420, 'ERC721'],
  [42161, 'ERC721'],
  [421611, 'ERC721'],
  [10001, 'ERC721'],
  [513100, 'ERC721'],
]);

export const chainId_ERC1155 = new Map<number | undefined, string>([
  [0, 'ERC1155'],
  [1, 'ERC1155'],
  [3, 'ERC1155'],
  [4, 'ERC1155'],
  [5, 'ERC1155'],
  [42, 'ERC1155'],
  [56, 'BEP1155'],
  [97, 'BEP1155'],
  [137, 'ERC1155'],
  [80001, 'ERC1155'],
  [43114, 'ERC1155'],
  [43113, 'ERC1155'],
  [250, 'ERC1155'],
  [4002, 'ERC1155'],
  [128, 'HRC1155'],
  [256, 'HRC1155'],
  [25, 'CRC1155'],
  [8217, 'ERC1155'],
  [1001, 'ERC1155'],
  [100, 'ERC1155'],
  [1313161554, 'ERC1155'],
  [42220, 'ERC1155'],
  [32659, 'FRC1155'],
  [1284, 'ERC1155'],
  [1285, 'ERC1155'],
  [1287, 'ERC1155'],
  [10, 'ERC1155'],
  [420, 'ERC1155'],
  [42161, 'ERC1155'],
  [421611, 'ERC1155'],
  [10001, 'ERC1155'],
  [513100, 'ERC1155'],
]);

export const chainId_SYMBOL = new Map<number | undefined, string>([
  [0, 'ETH-USD'],
  [1, 'ETH-USD'],
  [3, 'ETH-USD'],
  [4, 'ETH-USD'],
  [5, 'ETH-USD'],
  [42, 'ETH-USD'],
  [56, 'BNBUSDT'],  // Get from Binance
  [97, 'BNBUSDT'],  // Get from Binance
  [137, 'MATIC-USD'],
  [80001, 'MATIC-USD'],
  [43114, 'AVAX-USD'],
  [43113, 'AVAX-USD'],
  [250, 'FTMUSDT'],  // Get from Binance
  [4002, 'FTMUSDT'],  // Get from Binance
  [128, 'HTUSDT'],  // Get from Binance
  [256, 'HTUSDT'],  // Get from Binance
  [25, 'CRO-USD'],
  [1284, 'GLMRUSDT'],  // Get from Binance
  [1285, 'MOVRUSDT'],  // Get from Binance
  [1287, 'MOVRUSDT'],  // Get from Binance
  [10, 'ETH-USD'],
  [420, 'ETH-USD'],
  [42161, 'ETH-USD'],
  [421611, 'ETH-USD'],
  [10001, 'ETHW-USD'],
  [513100, 'ETHF-USD'],
]);

export const chainId_ADDR = new Map<number | undefined, string>([
  [0, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [1, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [3, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [4, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [5, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [42, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [56, '0x418D75f65a02b3D53B2418FB8E1fe493759c7605'],
  [97, '0x418D75f65a02b3D53B2418FB8E1fe493759c7605'],
  [137, '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0'],
  [80001, '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0'],
  [43114, '0x85f138bfEE4ef8e540890CFb48F620571d67Eda3'],  // Check whether Wrapped AVAX (Wormhole) is good
  [43113, '0x85f138bfEE4ef8e540890CFb48F620571d67Eda3'],  // Check whether Wrapped AVAX (Wormhole) is good
  [250, '0x4E15361FD6b4BB609Fa63C81A2be19d873717870'],
  [4002, '0x4E15361FD6b4BB609Fa63C81A2be19d873717870'],
  [128, '0x6f259637dcD74C767781E37Bc6133cd6A68aa161'],  // The token address is correct, but Moralis price is not
  [256, '0x6f259637dcD74C767781E37Bc6133cd6A68aa161'],  // The token address is correct, but Moralis price is not
  [25, '0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b'],
  [1313161554, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [42220, '0x3294395e62F4eB6aF3f1Fcf89f5602D90Fb3Ef69'],
  [1284, 'GLMRUSDT'],  // Get from Binance
  [1285, 'MOVRUSDT'],  // Get from Binance
  [1287, 'MOVRUSDT'],  // Get from Binance
  [10, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [420, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [42161, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [421611, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
  [10001, 'ETHWSDT'],  // Get from Binance
  [513100, 'ETHFSDT'],  // Get from Binance
]);

export const chainId_PRICE = new Map<number | undefined, number>([
  [0, 2000],
  [1, 2000],
  [3, 2000],
  [4, 2000],
  [5, 2000],
  [42, 2000],
  [56, 300],  // BNB
  [97, 300],  // BNB
  [137, 0.7],  // MATIC
  [80001, 0.7],  // MATIC
  [43114, 10],  // AVAX
  [43113, 10],  // AVAX
  [250, 0.2],  // FTM
  [4002, 0.2],  // FTM
  [128, 6],  // Heco
  [256, 6],  // Heco
  [25, 0.06],  // CRO
  [8217, 0.3],  // KLAY
  [1001, 0.3],  // KLAY
  [100, 1],  // Gnosis
  [1313161554, 2000],  // Aurora
  [42220, 0.5],  // Celo
  [32659, 0.3],  // Fusion
  [1284, 1],  // GLMR
  [1285, 15],  // MOVR
  [1287, 15],  // Moonbase Alphanet
  [10, 2000],  // Optimism
  [420, 2000],  // Optimism
  [42161, 2000],  // Arbitrum
  [421611, 2000],  // Arbitrum
  [10001, 1.5],  // Ethereum PoW
  [513100, 0.3],  // Ethereum Fair
]);

export const chainId_maxComm = new Map<number | undefined, number>([
  [0, 0.029],
  [1, 0.029],
  [3, 0.029],
  [4, 0.029],
  [5, 0.029],
  [42, 0.029],
  [56, 0.09],  // BNB
  [97, 0.09],  // BNB
  [137, 20 / 0.7],  // MATIC
  [80001, 20 / 0.7],  // MATIC
  [43114, 0.9],  // AVAX
  [43113, 0.9],  // AVAX
  [250, 99],  // FTM
  [4002, 99],  // FTM
  [128, 20 / 6],  // Heco
  [256, 20 / 6],  // Heco
  [25, 20 / 0.06],  // CRO
  [8217, 20 / 0.3],  // KLAY
  [1001, 20 / 0.3],  // KLAY
  [100, 19],  // Gnosis
  [1313161554, 0.009],  // Aurora
  [42220, 20 / 0.5],  // Celo
  [32659, 20 / 0.3],  // Fusion
  [1284, 20 / 1],  // GLMR
  [1285, 20 / 15],  // MOVR
  [1287, 20 / 15],  // Moonbase Alphanet
  [10, 0.009],  // Optimism
  [420, 0.009],  // Optimism
  [42161, 0.009],  // Arbitrum
  [421611, 0.009],  // Arbitrum
  [10001, 20 / 1.5],  // Ethereum PoW
  [513100, 20 / 0.3],  // Ethereum Fair
]);

export const chainId_blackToken = new Map<number | undefined, string[]>([
  [0, []],
  [1, []],
  [3, []],
  [4, []],
  [5, []],
  [42, []],
  [56, []],
  [97, []],
  [137, []],
  [80001, []],
  [43114, []],
  [43113, []],
  [250, []],
  [4002, []],
  [128, []],
  [256, []],
  [25, []],
  [8217, []],
  [1001, []],
  [100, []],
  [1313161554, []],
  [42220, []],
  [32659, []],
  [1284, []],
  [1285, []],
  [1287, []],
  [10, []],
  [420, []],
  [42161, []],
  [421611, []],
  [10001, []],
  [513100, []],
]);


export function getEtherscanUrl(chainId: number | undefined, type: 'address' | 'tx', hash?: string) {

  if (isEthChain(chainId)) {
    if (chainId === 1) { return `https://etherscan.io/${type}/${hash}` }
    return `https://${chainId_link.get(chainId)}.etherscan.io/${type}/${hash}`
  }

  // BSC
  if (chainId === 56) { return `https://bscscan.com/${type}/${hash}` }
  if (chainId === 97) { return `https://testnet.bscscan.com/${type}/${hash}` }

  // Polygon
  if (chainId === 137) { return `https://polygonscan.com/${type}/${hash}` }
  if (chainId === 80001) { return `https://mumbai.polygonscan.com/${type}/${hash}` }

  // Avalanche
  if (chainId === 43114) { return `https://snowtrace.io/${type}/${hash}` }
  if (chainId === 43113) { return `https://testnet.snowtrace.io/${type}/${hash}` }

  // Fantom
  if (chainId === 250) { return `https://ftmscan.com/${type}/${hash}` }
  if (chainId === 4002) { return `https://testnet.ftmscan.com/${type}/${hash}` }

  // Heco
  if (chainId === 128) { return `https://hecoinfo.com/${type}/${hash}` }
  if (chainId === 256) { return `https://testnet.hecoinfo.com/${type}/${hash}` }

  // Cronos
  if (chainId === 25) { return `https://cronoscan.com/${type}/${hash}` }

  // Klaytn
  if (chainId === 8217) { return `https://scope.klaytn.com/${type}/${hash}` }
  if (chainId === 1001) { return `https://baobab.scope.klaytn.com/${type}/${hash}` }

  // Gnosis
  if (chainId === 100) { return `https://gnosisscan.io/${type}/${hash}` }

  // Aurora
  if (chainId === 1313161554) { return `https://aurorascan.dev/${type}/${hash}` }

  // Celo
  if (chainId === 42220) { return `https://celoscan.io/${type}/${hash}` }

  // Fusion
  if (chainId === 32659) {
    if (type === 'tx') { return `https://blocks.fusionnetwork.io/#!/transaction/${hash}` }
    return `https://blocks.fusionnetwork.io/#!/${type}/${hash}`
  }

  // Moonbeam
  if (chainId === 1284) { return `https://moonscan.io/${type}/${hash}` }
  if (chainId === 1285) { return `https://moonriver.moonscan.io/${type}/${hash}` }
  if (chainId === 1287) { return `https://moonbase.moonscan.io/${type}/${hash}` }

  // Optimism
  if (chainId === 10) { return `https://optimistic.etherscan.io/${type}/${hash}` }
  if (chainId === 420) { return `https://blockscout.com/optimism/goerli/${type}/${hash}` }

  // Arbitrum
  if (chainId === 42161) { return `https://arbiscan.io/${type}/${hash}` }
  if (chainId === 421611) { return `https://testnet.arbiscan.io/${type}/${hash}` }

  // ETHW
  if (chainId === 10001) { return `https://www.oklink.com/en/ethw/${type}/${hash}` }

  // ETHF
  if (chainId === 513100) { return `https://explorer.etherfair.org/${type}/${hash}` }

  return ''
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getTypeForTokenStandard = (tokenStandard: string | undefined): string => {
  switch (tokenStandard) {
    case 'eth':
      return ''
    case 'erc20':
      return 'token'
    case 'erc721':
      return 'NFT contract'
    case 'erc1155':
      return 'NFT contract'
    default:
      return ''
  }
}

export function getIndexForTokenStandard(tokenStandard: string | undefined) {
  if (tokenStandard === 'eth') { return 0 }
  if (tokenStandard === 'erc20') { return 1 }
  if (tokenStandard === 'erc721') { return 2 }
  if (tokenStandard === 'erc1155') { return 3 }
  return 0
}

export function getTokenStandard(chainId: number | undefined, tokenStandard: string | undefined) {
  if (tokenStandard === 'eth') {
    return chainId ? chainId_ETH.get(chainId) ?? 'ETH' : 'ETH'
  }
  if (tokenStandard === 'erc20') {
    return chainId ? chainId_ERC20.get(chainId) ?? 'ERC20' : 'ERC20'
  }
  if (tokenStandard === 'erc721') {
    return chainId ? chainId_ERC721.get(chainId) ?? 'ERC721' : 'ERC721'
  }
  if (tokenStandard === 'erc1155') {
    return chainId ? chainId_ERC1155.get(chainId) ?? 'ERC1155' : 'ERC1155'
  }
  return ' '
}

export function isEthChain(chainId: number | undefined) {
  if (chainId === 1) { return true }  // Mainnet
  if (chainId === 3) { return true }  // Ropsten
  if (chainId === 4) { return true }  // Rinkeby
  if (chainId === 5) { return true }  // Goerli
  if (chainId === 42) { return true }  // Kovan
  return false
}

export function isBnbChain(chainId: number | undefined) {
  if (chainId === 56) { return true }  // Mainnet
  if (chainId === 97) { return true }  // Testnet
  return false
}

export function isMaticChain(chainId: number | undefined) {
  if (chainId === 137) { return true }  // Mainnet
  if (chainId === 80001) { return true }  // Mumbai
  return false
}

export function isTipChain(chainId: number | undefined) {
  if (!chainId) { return false }
  return tipChainIds.includes(chainId)
}

export function isChargeChain(chainId: number | undefined) {
  if (!chainId) { return false }
  return chargeChainIds.includes(chainId)
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getTokenLean = /* GraphQL */ `
  query GetToken($chainId: Int!, $token_address: ID!) {
    getToken(chainId: $chainId, token_address: $token_address) {
      chainId
      token_address
      contract_type
      symbol
      name
      logo
      balance
      decimals
    }
  }
`;

const ethPlaceholderENS = '0xc00F09F6463607C03a6828132cab9621B0b78fA9,1\navocet.eth,1.5\n0xdC7100D8069D6E180a9910E4c3fe2f1ee03467BE,0.23'
const erc20PlaceholderENS = '0xc00F09F6463607C03a6828132cab9621B0b78fA9,1\navocet.eth,123.45\n0xdC7100D8069D6E180a9910E4c3fe2f1ee03467BE,2.3'
const erc721PlaceholderENS = '0xc00F09F6463607C03a6828132cab9621B0b78fA9,122\n0xc00F09F6463607C03a6828132cab9621B0b78fA9,6934\navocet.eth,7698\n0xdC7100D8069D6E180a9910E4c3fe2f1ee03467BE,118'
const erc1155PlaceholderENS = '0xc00F09F6463607C03a6828132cab9621B0b78fA9,115,3\n0xc00F09F6463607C03a6828132cab9621B0b78fA9,569,2\navocet.eth,234,1\n0xdC7100D8069D6E180a9910E4c3fe2f1ee03467BE,135,2'

const ethPlaceholder = '0xc00F09F6463607C03a6828132cab9621B0b78fA9,1\n0x40De03083741bEA794652C02cfAFe610e680ba94,1.5\n0xdC7100D8069D6E180a9910E4c3fe2f1ee03467BE,0.23'
const erc20Placeholder = '0xc00F09F6463607C03a6828132cab9621B0b78fA9,1\n0x40De03083741bEA794652C02cfAFe610e680ba94,123.45\n0xdC7100D8069D6E180a9910E4c3fe2f1ee03467BE,2.3'
const erc721Placeholder = '0xc00F09F6463607C03a6828132cab9621B0b78fA9,122\n0xc00F09F6463607C03a6828132cab9621B0b78fA9,6934\n0x40De03083741bEA794652C02cfAFe610e680ba94,7698\n0xdC7100D8069D6E180a9910E4c3fe2f1ee03467BE,118'
const erc1155Placeholder = '0xc00F09F6463607C03a6828132cab9621B0b78fA9,115,3\n0xc00F09F6463607C03a6828132cab9621B0b78fA9,569,2\n0x40De03083741bEA794652C02cfAFe610e680ba94,234,1\n0xdC7100D8069D6E180a9910E4c3fe2f1ee03467BE,135,2'

export function capitalize(string: string): string {
  return string
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function ellipseText(
  text: string = "",
  maxLength: number = 9999
): string {
  if (text.length <= maxLength) {
    return text
  }
  const _maxLength = maxLength - 3
  let ellipse = false
  let currentLength = 0
  const result =
    text
      .split(" ")
      .filter(word => {
        currentLength += word.length;
        if (ellipse || currentLength >= _maxLength) {
          ellipse = true;
          return false;
        } else {
          return true;
        }
      })
      .join(" ") + "...";
  return result;
}

export function ellipseAddress(
  address: string = "",
  width_pre: number = 6,
  width_post: number = 4
): string {
  return `${address.slice(0, width_pre)}...${address.slice(address.length - width_post, address.length)}`;
}

export function padLeft(n: string, width: number, z?: string): string {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function sanitizeHex(hex: string): string {
  hex = hex.substring(0, 2) === "0x" ? hex.substring(2) : hex;
  if (hex === "") {
    return "";
  }
  hex = hex.length % 2 !== 0 ? "0" + hex : hex;
  return "0x" + hex;
}

export function removeHexPrefix(hex: string): string {
  return hex.toLowerCase().replace("0x", "");
}

export function getDataString(func: string, arrVals: any[]): string {
  let val = "";
  for (let i = 0; i < arrVals.length; i++) {
    val += padLeft(arrVals[i], 64);
  }
  const data = func + val;
  return data;
}

export function isMobile(): boolean {
  let mobile: boolean = false;

  function hasTouchEvent(): boolean {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }

  function hasMobileUserAgent(): boolean {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        navigator.userAgent.substring(0, 4)
      )
    ) {
      return true
    } else if (hasTouchEvent()) {
      return true
    }
    return false
  }
  mobile = hasMobileUserAgent()
  return mobile
}

export function getChainData(chainId: number): IChainData {
  // console.log("DEBUG entered getChainData");
  if (!supportedChainIds.includes(chainId)) {
    chainId = 0
  }
  const chainId_number = parseInt(chainId.toString(16), 16);
  const chainData = supportedChains.filter(
    (chain: any) => chain.chain_id === chainId_number
  )[0]

  if (
    chainData.rpc_url.includes('infura.io') &&
    chainData.rpc_url.includes('%API_KEY%') &&
    API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace('%API_KEY%', API_KEY)
    return {
      ...chainData,
      rpc_url: rpcUrl
    }
  }

  return chainData
}

export function hashPersonalMessage(msg: string): string {
  const buffer = Buffer.from(msg)
  const result = ethUtil.hashPersonalMessage(buffer)
  const hash = ethUtil.bufferToHex(result)
  return hash
}

export function recoverPublicKey(sig: string, hash: string): string {
  const sigParams = ethUtil.fromRpcSig(sig)
  const hashBuffer = Buffer.from(hash.replace("0x", ""), "hex")
  const result = ethUtil.ecrecover(
    hashBuffer,
    sigParams.v,
    sigParams.r,
    sigParams.s
  )
  const signer = ethUtil.bufferToHex(ethUtil.publicToAddress(result))
  return signer
}

export function recoverPersonalSignature(sig: string, msg: string): string {
  const hash = hashPersonalMessage(msg)
  const signer = recoverPublicKey(sig, hash)
  return signer
}

export function isObject(obj: any): boolean {
  return typeof obj === "object" && !!Object.keys(obj).length
}

export async function formatSetApprovalTransaction(
  senderAddress: string,
  tokenAddress: string,
  tokenStandard: string,
  chainId: number,
  amountWei: string,
  approved: boolean,
  web3: any
) {
  if (tokenStandard === 'eth') { return null }

  const from = senderAddress
  const to = tokenAddress
  const value = web3.utils.toHex(0)
  const batchSenderAddress = BATCHSENDER_CONTRACT[chainId].address

  let data = null
  if (tokenStandard === 'erc20') {
    const tokenContract = getERC20Contract(tokenAddress, web3)
    // const balance = await tokenContract.methods.balanceOf(senderAddress).call()
    // data = tokenContract.methods.approve(batchSenderAddress, balance).encodeABI()  

    // const MAX_UINT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'  // 2 ** 256 - 1

    data = tokenContract.methods.approve(batchSenderAddress, amountWei).encodeABI()
  } else {
    const tokenContract = getERC721Contract(tokenAddress, web3)
    // data = tokenContract.methods['setApprovalForAll(address,bool)'](batchSenderAddress, approved).encodeABI()
    data = tokenContract.methods.setApprovalForAll(batchSenderAddress, approved).encodeABI()
  }

  const gasDict = await getmaxFeePerGas(chainId, web3)
  const maxFeePerGas = gasDict.maxFeePerGas
  const maxPriorityFeePerGas = gasDict.maxPriorityFeePerGas
  const maxFeePerGasHex = maxFeePerGas ? web3.utils.toHex(maxFeePerGas) : null
  const maxPriorityFeePerGasHex = maxPriorityFeePerGas ? web3.utils.toHex(maxPriorityFeePerGas) : null
  const tx = {
    from: from,
    to: to,
    value: value,
    maxFeePerGas: maxFeePerGasHex,
    maxPriorityFeePerGas: maxPriorityFeePerGasHex,
    data: data
  }
  return tx
}

function getUSDETH(nRows: number, exponent: number) {
  if (nRows <= 1) { return 0 }
  if (nRows < 24) { return 2 * nRows }  // $2 per row
  return 10 * Math.pow(nRows - 1, exponent)
}

function getUSDOther(nRows: number, exponent: number) {
  if (nRows <= 1) { return 0 }
  if (nRows < 24) { return 1 * nRows }  // $1 per row
  return 5 * Math.pow(nRows - 1, exponent)
}

function getUSD(nRows: number, exponent: number, unit: number) {
  if (nRows <= 1) { return 0 }
  if (nRows < 24) { return unit * nRows }  // $1 per row
  return 5 * unit * Math.pow(nRows - 1, exponent)
}

function getCommission(chainId: number, unitComm: number, nRows: number, exponent: number) {
  if (nRows <= 1) { return 0 }

  if (isEthChain(chainId)) {
    const comm = unitComm * getUSD(nRows, exponent, 1)  // $1 per row
    if (comm < 0.00251) { return 0.00251 }
    if (comm > 0.029) { return 0.029 }
    return comm
  }

  const comm = unitComm * getUSD(nRows, exponent, 0.5)  // $0.5 per row
  const maxComm = (chainId ? chainId_maxComm.get(chainId) : chainId_maxComm.get(1)) ?? 0.029
  if (chainId === 10001 || chainId === 513100) {
    if (comm < 0.00251) { return 0.00251 }
  }
  if (comm > maxComm) { return maxComm }
  return comm
}

function sumBNArr(BNArray: any[], web3: any) {
  var sum = web3.utils.toBN(0)
  for (let i = 0; i < BNArray.length; i++) {
    sum = sum.add(web3.utils.toBN(BNArray[i]))
  }
  return sum
}

export async function getmaxFeePerGas(chainId: number, web3: any) {
  let maxFeePerGas = null
  let maxPriorityFeePerGas = null
  if (chainId === 1 || chainId === 3 || chainId === 1284 || chainId === 1285) {  // Ethereum Mainnet, Ropsten, Moonbeam, Moonriver
    try {
      const gasPrice = await web3.eth.getGasPrice()
      var chainFee = chainId_fee.get(chainId)
      if (chainId === 1) {
        const gasPriceGwei = parseFloat(gasPrice) / 1e9
        if (gasPriceGwei < 10) {
          chainFee = '1.5'
        } else if (gasPriceGwei < 80) {
          chainFee = '1.5'
        }
      }
      maxPriorityFeePerGas = web3.utils.toWei(chainFee, 'gwei')
      const gasPriceBN = web3.utils.toBN(gasPrice)
      const quaterGasPrice = gasPriceBN.div(web3.utils.toBN(4))
      const maxPriorityFeeBN = web3.utils.toBN(maxPriorityFeePerGas)

      if (chainId === 3) {  // Ropsten
        maxFeePerGas = gasPriceBN.add(gasPriceBN).add(maxPriorityFeeBN).toString()
      } else {
        maxFeePerGas = gasPriceBN.add(quaterGasPrice).add(maxPriorityFeeBN).toString()
      }

    } catch (e) {
      maxFeePerGas = null
    }
  } else if (chainId === 137) {  // Polygon Mainnet
    let priorityFee = null
    let maxFeePerGasGwei = null
    try {
      const response = await fetch(`https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=${POLYGONSCAN_KEY}`)
      const jsonResponse = await response.json()
      const result = jsonResponse.result
      const baseFee = parseFloat(result.suggestBaseFee)
      const lowPriorityFee = Math.max(parseFloat(result.ProposeGasPrice) - baseFee, 1)
      const mediumPriorityFee = Math.max(parseFloat(result.FastGasPrice) - baseFee, 1)
      priorityFee = Math.min(lowPriorityFee * 1.25, mediumPriorityFee)
      maxFeePerGasGwei = baseFee + priorityFee
    } catch (e) {
    }
    const cPriorityFee = 30
    const cBaseFee = 180
    const cMaxFeePerGasGwei = cBaseFee + cPriorityFee
    try {
      if (priorityFee === null || isNaN(priorityFee)) {
        if (maxFeePerGasGwei === null || isNaN(maxFeePerGasGwei)) {
          priorityFee = cPriorityFee
          maxFeePerGasGwei = cMaxFeePerGasGwei
        } else {
          priorityFee = maxFeePerGasGwei - cBaseFee
        }
      } else if (maxFeePerGasGwei === null || isNaN(maxFeePerGasGwei)) {
        maxFeePerGasGwei = priorityFee + cBaseFee
      }
    } catch (e) {
      priorityFee = cPriorityFee
      maxFeePerGasGwei = cMaxFeePerGasGwei
    }
    maxPriorityFeePerGas = web3.utils.toWei(priorityFee.toFixed(9), 'gwei')
    maxFeePerGas = web3.utils.toWei(maxFeePerGasGwei.toFixed(9), 'gwei')
  } else if (chainId === 8217 || chainId === 1001) {  // Klaytn
    try {
      const caver = new Caver('https://public-node-api.klaytnapi.com/v1/cypress')
      maxFeePerGas = await caver.rpc.klay.getGasPrice()
      maxPriorityFeePerGas = await caver.rpc.klay.getMaxPriorityFeePerGas()
    } catch (e) {
      const defaultFee = web3.utils.toWei('250', 'gwei')
      maxFeePerGas = defaultFee
      maxPriorityFeePerGas = defaultFee
    }
  }

  /*
  else if (chainId === 56) {  // BSC
    try {
      const defaultUrl = 'https://api.bscscan.com/api?module=gastracker&action=gasoracle'
      const url = (chainId ? chainId_url.get(chainId) : chainId_url.get(56)) ?? defaultUrl
      const response = await fetch(url)
      const jsonResponse = await response.json()
      const result = jsonResponse.result
      const safeGasPrice = parseFloat(result.SafeGasPrice)
      const proposeGasPrice = parseFloat(result.ProposeGasPrice)
      const maxFeePerGasGwei = Math.max(safeGasPrice, proposeGasPrice)
      maxFeePerGas = web3.utils.toWei(maxFeePerGasGwei.toFixed(9), 'gwei')
    } catch (e) {
      const defaultGas = (chainId ? chainId_defaultGas.get(chainId) : chainId_defaultGas.get(56)) ?? '5'
      maxFeePerGas = web3.utils.toWei(defaultGas, 'gwei')
    }
  } else if (chainId === 250) {  // FTM
    try {
      const gasPrice = await web3.eth.getGasPrice()
      const baseFee = parseFloat(gasPrice) / 1e9
      const defaultUrl = 'https://api.ftmscan.com/api?module=gastracker&action=gasoracle'
      const url = (chainId ? chainId_url.get(chainId) : chainId_url.get(250)) ?? defaultUrl
      const response = await fetch(url)
      const jsonResponse = await response.json()
      const result = jsonResponse.result
      const fastGasPrice = parseFloat(result.FastGasPrice)
      let priorityFee = fastGasPrice - baseFee
      if (priorityFee < 0) {
        priorityFee = fastGasPrice
      }
      const maxFeePerGasGwei = 1.5 * baseFee + priorityFee
      maxFeePerGas = web3.utils.toWei(maxFeePerGasGwei.toFixed(9), 'gwei')
      maxPriorityFeePerGas = web3.utils.toWei(priorityFee.toFixed(9), 'gwei')
    } catch (e) {
      const defaultGas = (chainId ? chainId_defaultGas.get(chainId) : chainId_defaultGas.get(250)) ?? '60'
      maxPriorityFeePerGas = web3.utils.toWei(defaultGas, 'gwei')
      maxFeePerGas = web3.utils.toWei((parseFloat(defaultGas) * 2).toFixed(9), 'gwei')
    }
  } else if (chainId === 42161 || chainId === 10) {  // Arbitrum and Optimism
    try {
      const gasPrice = await web3.eth.getGasPrice()
      const baseFee = parseFloat(gasPrice) / 1e9
      const priorityFee = baseFee * 0.1
      const maxFeePerGasGwei = 1.5 * baseFee + priorityFee
      maxFeePerGas = web3.utils.toWei(maxFeePerGasGwei.toFixed(9), 'gwei')
      maxPriorityFeePerGas = web3.utils.toWei(priorityFee.toFixed(9), 'gwei')
    } catch (e) {
      const defaultGas = (chainId ? chainId_defaultGas.get(chainId) : chainId_defaultGas.get(42161)) ?? '0.1'
      maxPriorityFeePerGas = web3.utils.toWei((parseFloat(defaultGas) * 0.1).toFixed(9), 'gwei')
      maxFeePerGas = web3.utils.toWei((parseFloat(defaultGas) * 1.5).toFixed(9), 'gwei')
    }
  }
  */

  return {
    'maxFeePerGas': maxFeePerGas,
    'maxPriorityFeePerGas': maxPriorityFeePerGas
  }
}

export const chainId_url = new Map<number | undefined, string>([
  [56, 'https://api.bscscan.com/api?module=gastracker&action=gasoracle'],
  [250, 'https://api.ftmscan.com/api?module=gastracker&action=gasoracle'],
]);

export const chainId_defaultGas = new Map<number | undefined, string>([
  [56, '5'],
  [250, '60'],
  [42161, '0.1'],
  [10, '0.5'],
]);

export function weiToFixed(value: string, decimal: number, web3: any) {
  const scale = 10 ** decimal
  const valueFloat = parseFloat(web3.utils.fromWei(web3.utils.toBN(value), 'ether'))
  return (Math.round(valueFloat * scale) / scale).toString()
  // return parseFloat(web3.utils.fromWei(web3.utils.toBN(value), 'ether')).toFixed(decimal)
}

export function etherToWei(
  etherValue: number,
  decimal: number, // int in [0, 32]
  web3: any
) {
  // const unitMap = {
  //   '0': 'wei',
  //   '1': 'kwei',
  //   '2': 'mwei',
  //   '3': 'gwei',
  //   '4': 'szabo',
  //   '5': 'finney',
  //   '6': 'ether',
  //   '7': 'kether',
  //   '8': 'mether',
  //   '9': 'gether',
  //   '10': 'tether'
  // }

  const unitMap = ['wei', 'kwei', 'mwei', 'gwei', 'szabo', 'finney', 'ether', 'kether', 'mether', 'gether', 'tether']
  // if (decimal < 3) { return (etherValue * (10 ** decimal)).toString()}
  if (decimal < 33) {
    const quo = Math.floor(decimal / 3)
    const rem = decimal % 3
    const shiftetherValue = etherValue * (10 ** rem)
    return web3.utils.toWei(shiftetherValue.toFixed(quo * 3), unitMap[quo])
  }
  const quo = 10
  const rem = decimal - 30
  const shiftetherValue = etherValue * (10 ** rem)
  return web3.utils.toWei(shiftetherValue.toFixed(quo * 3), unitMap[quo])
}

export async function getERC20Decimals(tokenAddress: string, web3: any) {
  const tokenContract = getERC20Contract(tokenAddress, web3)
  const decimals = await tokenContract.methods.decimals().call()
  return decimals
}

// Coingecko
// https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc
// https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd
// https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd
// ethereum
// matic-network
// binancecoin
// avalanche-2
// fantom
// huobi-token
// moonbeam
// moonriver
// crypto-com-chain

// curl -X 'GET' \
//   'https://deep-index.moralis.io/api/v2/erc20/metadata?chain=bsc&addresses=0x55d398326f99059ff775485246999027b3197955&addresses=0x0a385f86059e0b2a048171d78afd1f38558121f3' \
//   -H 'accept: application/json' \
//   -H 'X-API-Key: kN8DTKqnFPMP3cXL19jrZ0HKkM2AWT9xumIwOnvCfzZcXQvQVCGkHzjzQw5G67MI'


export async function getCoinPrice(tokenAddress: string, defaultPrice: number) {
  try {
    const url = `https://deep-index.moralis.io/api/v2/erc20/${tokenAddress}/price?chain=eth`
    // const response = await fetch(url, {
    //   method: "GET",
    //   headers: { 'x-api-key': MORALIS_KEY }
    // })
    const response = await axios.request({
      url: url,
      method: 'GET',
      timeout: 3000,
      headers: { 'x-api-key': MORALIS_KEY },
    })
    if (response.status === 200) {
      return parseFloat(response.data.usdPrice)
    }
    return defaultPrice
  } catch (e) {
    return defaultPrice
  }
}

export async function getCoinBasePrice(symbol: string, defaultPrice: number) {
  // symbol = 'ETH-USD'
  try {
    const url = 'https://api.pro.coinbase.com/products/' + symbol + '/ticker'
    const response = await fetch(url)
    const jsonResponse = await response.json()
    return parseFloat(jsonResponse.price)
  } catch (e) {
    return defaultPrice
  }
}

export async function formatBatchSendTransaction(
  senderAddress: string,
  tokenAddress: string,
  chainId: number,
  tokenStandard: string,
  web3: any,
  aggInput: any
) {
  const from = senderAddress
  const to = BATCHSENDER_CONTRACT[chainId].address

  const uniqueInputPerAddr = mergeDupPerAddr(aggInput)
  const toAddresses = uniqueInputPerAddr.toAddresses
  const unique_token_id_2d = uniqueInputPerAddr.unique_token_id_2d
  const unique_amount_2d = uniqueInputPerAddr.unique_amount_2d

  var weiComm = 0
  const num_token_id_1d = unique_token_id_2d.map(row => row.length)
  const num_token_id = [...new Set(unique_token_id_2d.flat())].length

  if (isChargeChain(chainId)) {
    const nRows = num_token_id_1d.reduce((prev, cur) => prev + cur, 0)
    if (nRows > 1) {
      // Get coin price
      let coinPrice = 2000
      if (chainId === 100) {
        coinPrice = 1
      } else {
        const cAddr = (chainId ? chainId_ADDR.get(chainId) : chainId_ADDR.get(1)) ?? '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
        const defaultPrice = (chainId ? chainId_PRICE.get(chainId) : chainId_PRICE.get(1)) ?? 2000
        coinPrice = await getCoinPrice(cAddr, defaultPrice)
      }
      const unitComm = 1 / coinPrice  // $1 worth of coin
      const exponent = 0.5
      const commission = getCommission(chainId, unitComm, nRows, exponent)
      weiComm = web3.utils.toWei(commission.toFixed(18), 'ether')
    }
  }

  let amount_1d = null
  let amount_1d_wei = null
  let total_amount_wei = null
  if ((tokenStandard === 'erc1155' && num_token_id === 1) || tokenStandard === 'erc20' || tokenStandard === 'eth') {
    // Only need to compute for oneToMany
    amount_1d = unique_amount_2d.map(row => row[0])
    if (tokenStandard === 'erc20' || tokenStandard === 'eth') {
      var decimals = 18
      if (tokenStandard === 'erc20') {
        decimals = await getERC20Decimals(tokenAddress, web3)
      }
      amount_1d_wei = amount_1d.map(x => etherToWei(x, decimals, web3))
      try {
        total_amount_wei = sumBNArr(amount_1d_wei, web3)
      } catch (e) {
      }
    }
  }

  // Pass receivers length does not save
  // Replace safeBatchTransfer with safeTransfer is not good
  // Concatinate tokenAddress to receiver addresses does not save

  const unique_token_id_2dInt = unique_token_id_2d.map(row => {
    return row.map(v => parseInt(v))
  })
  const unique_token_id_2dHex = unique_token_id_2d.map(row => {
    return row.map(v => web3.utils.toHex(v))
  })

  let cum_length: number[] = []
  if (tokenStandard === 'erc721' || tokenStandard === 'erc1155') {
    num_token_id_1d.reduce(function (prev, curr, i) { return cum_length[i] = prev + curr }, 0)
    cum_length.pop()
  }

  var weiValue = weiComm
  const batchsender = getBatchSenderContract(chainId, web3)
  let data = null
  if (tokenStandard === 'erc1155') {
    if (num_token_id === 1) {
      if (amount_1d !== null) {
        if (new Set(amount_1d).size === 1) {  // Equal amount
          try {
            data = batchsender.methods.oneToMany1155_71s(tokenAddress, toAddresses, unique_token_id_2dInt[0][0], amount_1d[0]).encodeABI()
          } catch (e) {
            data = batchsender.methods.oneToMany1155_71s(tokenAddress, toAddresses, unique_token_id_2dHex[0][0], amount_1d[0]).encodeABI()
          }
        } else {  // Hetero amount
          try {
            data = batchsender.methods.oneToMany1155_Ghd(tokenAddress, toAddresses, unique_token_id_2dInt[0][0], amount_1d).encodeABI()
          } catch (e) {
            data = batchsender.methods.oneToMany1155_Ghd(tokenAddress, toAddresses, unique_token_id_2dHex[0][0], amount_1d).encodeABI()
          }
        }
      }
    } else if (toAddresses.length === 1) {
      try {
        data = batchsender.methods.manyToOne1155_VrA(tokenAddress, toAddresses[0], unique_token_id_2dInt[0], unique_amount_2d[0]).encodeABI()
      } catch (e) {
        data = batchsender.methods.manyToOne1155_VrA(tokenAddress, toAddresses[0], unique_token_id_2dHex[0], unique_amount_2d[0]).encodeABI()
      }
    } else {
      if (new Set(num_token_id_1d).size === 1) {
        const row_len = num_token_id_1d[0]
        try {
          data = batchsender.methods.manyToMany1155m_wiB(tokenAddress, toAddresses, unique_token_id_2dInt.flat(), unique_amount_2d.flat(), row_len).encodeABI()
        } catch (e) {
          data = batchsender.methods.manyToMany1155m_wiB(tokenAddress, toAddresses, unique_token_id_2dHex.flat(), unique_amount_2d.flat(), row_len).encodeABI()
        }
      } else {
        if (cum_length.length > 0) {
          try {
            data = batchsender.methods.manyToMany1155__Qfk(tokenAddress, toAddresses, unique_token_id_2dInt.flat(), unique_amount_2d.flat(), cum_length).encodeABI()
          } catch (e) {
            data = batchsender.methods.manyToMany1155__Qfk(tokenAddress, toAddresses, unique_token_id_2dHex.flat(), unique_amount_2d.flat(), cum_length).encodeABI()
          }
        }
      }
    }
  } else if (tokenStandard === 'erc721') {
    if (toAddresses.length === 1) {
      try {
        data = batchsender.methods.manyToOne721_N3_(tokenAddress, toAddresses[0], unique_token_id_2dInt[0]).encodeABI()
      } catch (e) {
        data = batchsender.methods.manyToOne721_N3_(tokenAddress, toAddresses[0], unique_token_id_2dHex[0]).encodeABI()
      }
    } else {
      if (new Set(num_token_id_1d).size === 1) {
        const row_len = num_token_id_1d[0]
        try {
          data = batchsender.methods.manyToMany721__a7H(tokenAddress, toAddresses, unique_token_id_2dInt.flat(), row_len).encodeABI()
        } catch (e) {
          data = batchsender.methods.manyToMany721__a7H(tokenAddress, toAddresses, unique_token_id_2dHex.flat(), row_len).encodeABI()
        }
      } else {
        if (cum_length.length > 0) {
          try {
            data = batchsender.methods.manyToMany721_ebh(tokenAddress, toAddresses, unique_token_id_2dInt.flat(), cum_length).encodeABI()
          } catch (e) {
            data = batchsender.methods.manyToMany721_ebh(tokenAddress, toAddresses, unique_token_id_2dHex.flat(), cum_length).encodeABI()
          }
        }
      }
    }
  } else if (tokenStandard === 'erc20') {
    if (amount_1d_wei !== null) {
      if (new Set(amount_1d_wei).size === 1) {  // Equal amount
        data = batchsender.methods.oneToMany20_TB(tokenAddress, toAddresses, amount_1d_wei[0]).encodeABI()
      } else {  // Hetero amount
        if (total_amount_wei !== null) {
          // if (chainId !== 56 && chainId !== 97 && chainId !== 137 && chainId !== 80001) {  // Not BSC or Polygon chain
          if (!deflERC20ChainIds.includes(chainId)) {  // Not BSC or Polygon chain
            amount_1d_wei.push(total_amount_wei.toString())
          }
          data = batchsender.methods.oneToMany20j_R7u(tokenAddress, toAddresses, amount_1d_wei).encodeABI()
        }
      }
    }
  } else if (tokenStandard === 'eth') {
    if (amount_1d_wei !== null) {
      try {
        weiValue = web3.utils.toBN(weiComm).add(total_amount_wei).toString()
      } catch (e) {
      }
      if (new Set(amount_1d_wei).size === 1) {  // Equal amount
        data = batchsender.methods.oneToManyETH__Jf1(toAddresses, amount_1d_wei[0]).encodeABI()
      } else {  // Hetero amount
        data = batchsender.methods.oneToManyETH__Ns_(toAddresses, amount_1d_wei).encodeABI()
      }
    }
  }

  const value = web3.utils.toHex(weiValue)
  weiComm = web3.utils.toHex(weiComm)

  const gasDict = await getmaxFeePerGas(chainId, web3)
  const maxFeePerGas = gasDict.maxFeePerGas
  const maxPriorityFeePerGas = gasDict.maxPriorityFeePerGas
  const maxFeePerGasHex = maxFeePerGas ? web3.utils.toHex(maxFeePerGas) : null
  const maxPriorityFeePerGasHex = maxPriorityFeePerGas ? web3.utils.toHex(maxPriorityFeePerGas) : null
  const tx = {
    from: from,
    to: to,
    value: value,
    maxFeePerGas: maxFeePerGasHex,
    maxPriorityFeePerGas: maxPriorityFeePerGasHex,
    data: data,
    weiComm: weiComm
  }
  return tx
}

export function bnMultiplyRate(weiComm: any, rate: number, web3: any) {
  // console.log('BN before mulply = ', web3.utils.hexToNumberString(weiComm))
  if (rate === 0) { return web3.utils.toBN(0) }

  const weiCommBN = web3.utils.toBN(weiComm)
  const rateBN = web3.utils.toBN(rate)
  const weiBonusBN = weiCommBN.mul(rateBN).div(web3.utils.toBN(100))
  return weiBonusBN
}

export function applyDiscount(rawTx: { [x: string]: any }, discountRate: number, tipVal: number, web3: any) {
  const weiComm = rawTx['weiComm']
  var weiValue = rawTx['value']
  if (discountRate > 0) {
    const weiDiscountBN = bnMultiplyRate(weiComm, discountRate, web3)
    weiValue = web3.utils.toBN(weiValue).sub(weiDiscountBN)
    weiValue = web3.utils.toHex(weiValue)
  }

  if (tipVal > 0) {
    const decimals = 18  // TODO: Might change to 8 for CRO
    const tipWei = etherToWei(tipVal, decimals, web3)
    const tipWeiBN = web3.utils.toBN(tipWei)
    weiValue = web3.utils.toBN(weiValue).add(tipWeiBN)
    weiValue = web3.utils.toHex(weiValue)
  }

  // if (rawTx['maxFeePerGas'] === null) {
  //   const tx = {
  //     from: rawTx['from'],
  //     to: rawTx['to'],
  //     value: weiValue,
  //     data: rawTx['data'],
  //   }
  //   return tx
  // }
  const tx = {
    from: rawTx['from'],
    to: rawTx['to'],
    value: weiValue,
    maxFeePerGas: rawTx['maxFeePerGas'],
    maxPriorityFeePerGas: rawTx['maxPriorityFeePerGas'],
    data: rawTx['data'],
  }
  return tx
}

export async function getBatchSenderApprovalStatus(
  account: string,
  tokenAddress: string,
  tokenStandard: string,
  erc20AmountEther: number,
  chainId: number,
  web3: any,
) {
  if (tokenStandard === 'eth') {
    return true
  }

  const batchsenderAddress = BATCHSENDER_CONTRACT[chainId].address
  if (tokenStandard === 'erc20') {
    const tokenContract = getERC20Contract(tokenAddress, web3)
    const allowance = await tokenContract.methods.allowance(account, batchsenderAddress).call()
    const decimals = await tokenContract.methods.decimals().call()
    const erc20AmountWei = etherToWei(erc20AmountEther, decimals, web3)
    const approvalStatus = greaterThanOrEqual(allowance, erc20AmountWei)
    return approvalStatus
  }

  const tokenContract = getERC721Contract(tokenAddress, web3)
  const approvalStatus = await tokenContract.methods.isApprovedForAll(account, batchsenderAddress).call()
  return approvalStatus
}

export function computeTotalAmountWei(amount_1d: number[], decimals: number, error: number, web3: any) {
  var total_amount_wei = web3.utils.toBN(0)
  const amount_1d_wei = amount_1d.map(x => etherToWei(x + error, decimals, web3)) // TODO: Fix the roundoff error
  try {
    total_amount_wei = sumBNArr(amount_1d_wei, web3)
  } catch (e) {
    total_amount_wei = web3.utils.toBN(0)
  }
  return total_amount_wei.toString()
}

export function getTotalAmountWei(unique_amount_2d: number[][], decimals: number, error: number, web3: any) {
  const amount_1d = unique_amount_2d.map(row => row[0])
  const totalAmountWei = computeTotalAmountWei(amount_1d, decimals, error, web3)
  return totalAmountWei
}

export function checkBalance(balance: any, unique_amount_2d: number[][], decimals: number, web3: any) {
  const totalAmountWei = getTotalAmountWei(unique_amount_2d, decimals, -1e-6, web3)
  return greaterThanOrEqual(balance, totalAmountWei)
}

export async function enoughETHBalance(account: string, unique_amount_2d: number[][], web3: any) {
  const balance = await web3.eth.getBalance(account)
  return checkBalance(balance, unique_amount_2d, 18, web3)
}

export async function enoughERC20Balance(account: string, tokenAddress: string, unique_amount_2d: number[][], web3: any) {
  const balance = await getERC20Balance(account, tokenAddress, web3)
  const decimals = await getERC20Decimals(tokenAddress, web3)
  return checkBalance(balance, unique_amount_2d, decimals, web3)
}

export async function getERC20Amount(tokenAddress: string, unique_amount_2d: number[][], web3: any) {
  const decimals = await getERC20Decimals(tokenAddress, web3)
  const totalAmountWei = getTotalAmountWei(unique_amount_2d, decimals, 1e-6, web3)
  return totalAmountWei
}

// export async function enoughERC721Balance(account: string, tokenAddress: string, token_id_1d: string[], amount_1d: number[], web3: any) {
//   for (let i = 0; i < token_id_1d.length; i++) {
//     if (amount_1d[i] > 1) { return false }
//     const ownerOf = await isOwnerOfERC721(account, tokenAddress, token_id_1d[i], web3)
//     if (!ownerOf) { return false }
//   }
//   return true
// }

// export async function enoughERC721Balance(account: string, tokenAddress: string, token_id_1d: string[], web3: any) {
//   // var bluePromise = require('bluebird')
//   const res = await bluePromise.map(token_id_1d, async (tokenId: any) => {
//     try {
//       return await isOwnerOfERC721(account, tokenAddress, tokenId, web3)
//     } catch (e) {
//       return false
//     }
//   }, { concurrency: 16 });

//   return res.every(Boolean)
// }

export async function getNoERC721TokenIDs(account: string, tokenAddress: string, token_id_1d: string[], web3: any) {
  // var bluePromise = require('bluebird')
  const res = await bluePromise.map(token_id_1d, async (tokenId: string) => {
    try {
      const isOwner = await isOwnerOfERC721(account, tokenAddress, tokenId, web3)
      return isOwner ? null : tokenId
    } catch (e) {
      return NetworkError
    }
  }, { concurrency: 16 });
  return res.filter((tokenId: any) => { return tokenId !== null })
}

export function zip(token_id_1d: string[], amount_1d: number[]) {
  let tokenId_amount: string[][] = []
  for (let i = 0; i < token_id_1d.length; i++) {
    tokenId_amount.push([token_id_1d[i], amount_1d[i].toString()])
  }
  return tokenId_amount
}

export function shortAddress(tokenAddress: string) {
  if (!tokenAddress || tokenAddress.length <= 10) { return tokenAddress }
  return tokenAddress.substring(0, 6) + '...' + tokenAddress.substring(tokenAddress.length - 4)
}

export async function getNoERC1155TokenIDs(account: string, tokenAddress: string, token_id_1d: string[], amount_1d: number[], web3: any) {
  const tokenId_amount = zip(token_id_1d, amount_1d)
  // var bluePromise = require('bluebird')
  const res = await bluePromise.map(tokenId_amount, async (row: any) => {
    const tokenId = row[0]
    const amount = parseInt(row[1])
    try {
      const balance = await getERC1155Balance(account, tokenAddress, tokenId, web3)
      return balance < amount ? tokenId : null
    } catch (e) {
      return NetworkError
    }
  }, { concurrency: 16 });
  return res.filter((tokenId: any) => { return tokenId !== null })
}

export async function getNoOpenStoreTokenIDsEth(account: string, tokenAddress: string, token_id_1d: string[], amount_1d: number[], web3: any) {
  const tokenId_amount = zip(token_id_1d, amount_1d)
  const res = await bluePromise.map(tokenId_amount, async (row: any) => {
    const tokenId = row[0]
    const amount = parseInt(row[1])
    try {
      const isMint = await isOpenStoreMintEth(account, tokenAddress, tokenId, amount, web3)
      return isMint ? null : tokenId
    } catch (e) {
      return NetworkError
    }
  }, { concurrency: 16 });
  return res.filter((tokenId: any) => { return tokenId !== null })
}

export async function getNoOpenStoreTokenIDsMatic(account: string, tokenAddress: string, token_id_1d: string[], web3: any) {
  // var bluePromise = require('bluebird')
  const res = await bluePromise.map(token_id_1d, async (tokenId: string) => {
    try {
      const isMint = await isOpenStoreMintMatic(account, tokenAddress, tokenId, web3)
      return isMint ? null : tokenId
    } catch (e) {
      return NetworkError
    }
  }, { concurrency: 16 });
  return res.filter((tokenId: any) => { return tokenId !== null })
}

export async function getERC20Balance(account: string, tokenAddress: string, web3: any) {
  const tokenContract = getERC20Contract(tokenAddress, web3)
  const balance = await tokenContract.methods.balanceOf(account).call()
  return balance
}

export async function isOwnerOfERC721(account: string, tokenAddress: string, tokenId: string, web3: any) {
  const tokenContract = getERC721Contract(tokenAddress, web3)
  const ownerAddr = await tokenContract.methods.ownerOf(tokenId).call()
  if (account === ownerAddr) { return true }
  if (account.toLowerCase() === ownerAddr.toLowerCase()) { return true }
  return false
}

export async function getERC1155Balance(account: string, tokenAddress: string, tokenId: string, web3: any) {
  const tokenContract = getERC1155Contract(tokenAddress, web3)
  const balance = await tokenContract.methods.balanceOf(account, tokenId).call()
  return balance
}

export async function isOpenStoreMintEth(account: string, tokenAddress: string, tokenId: string, amount: number, web3: any) {
  const tokenContract = getERC1155OpenSeaContract(tokenAddress, web3)
  // const isMint = await tokenContract.methods.exists(tokenId).call()  // This is necessary but not sufficient
  const totalS = await tokenContract.methods.totalSupply(tokenId).call()
  const isMint = parseInt(totalS) >= amount  // This is necessary but not sufficient
  return isMint
}

export async function isOpenStoreMintMatic(account: string, tokenAddress: string, tokenId: string, web3: any) {
  const tokenContract = getERC1155OpenSeaContract(tokenAddress, web3)
  const creatorAddr = await tokenContract.methods.creator(tokenId).call()
  if (account.toLowerCase() !== creatorAddr.toLowerCase()) {
    return true
  }
  // const isMint = await tokenContract.methods.exists(tokenId).call()  // This is necessary but not sufficient
  const totalS = await tokenContract.methods.totalSupply(tokenId).call()
  const maxS = await tokenContract.methods.maxSupply(tokenId).call()
  const isMint = parseInt(totalS) >= parseInt(maxS)  // This is sufficient
  return isMint
}

export async function getERC20TotalSupply(tokenAddress: string, web3: any) {
  const tokenContract = getERC20Contract(tokenAddress, web3)
  const totalSupply = await tokenContract.methods.totalSupply().call()
  return totalSupply
}

export async function getERC20Allowance(account: string, tokenAddress: string, chainId: number, web3: any) {
  const batchsenderAddress = BATCHSENDER_CONTRACT[chainId].address
  const tokenContract = getERC20Contract(tokenAddress, web3)
  const allowance = await tokenContract.methods.allowance(account, batchsenderAddress).call()
  return allowance
}

export async function getNFTApprovalStatus(account: string, tokenAddress: string, chainId: number, web3: any) {
  const batchsenderAddress = BATCHSENDER_CONTRACT[chainId].address
  const tokenContract = getERC721Contract(tokenAddress, web3)
  const approvalStatus = await tokenContract.methods.isApprovedForAll(account, batchsenderAddress).call()
  return approvalStatus
}

export async function resolveENS(address: string, web3: any) {
  if (address.toLowerCase().slice(-4) === '.eth') {
    try {
      const addr = await web3.eth.ens.getAddress(address)
      return addr
    } catch (e) {
      return address
    }
  }
  return address
}

export function isAddressWeb3(address: string, web3: any) {
  return web3.utils.isAddress(address.toLowerCase())
}

export function filterENS(addrArr: string[]) {
  const uniqueArr = [...new Set(addrArr)]
  return uniqueArr.filter(addr => { return addr.toLowerCase().slice(-4) === '.eth' })
}

export async function getENSMap(ensArr: any[]) {
  if (ensArr.length === 0) { return new Map<string, string>() }

  const web3 = new Web3(new Web3.providers.HttpProvider(`https://mainnet.infura.io/v3/${API_KEY}`));
  const res = await bluePromise.map(ensArr, async (a: any) => {
    try {
      const addr = await web3.eth.ens.getAddress(a)
      return [a, addr]
    } catch (e) {
      return [a, a]
    }
  }, { concurrency: 16 });

  const ensMap = new Map<string, string>(res)
  return ensMap
}

export function resolveAddressJson(jsonArray: any[], ensMap: Map<string, string>, web3: any) {
  return jsonArray.map(row => {
    try {
      const keyAddr = row.Receiver_Address
      row.Receiver_Address = keyAddr ? ensMap.get(keyAddr) ?? keyAddr : 'error'
      if (!isAddressWeb3(row.Receiver_Address, web3)) {
        row.Receiver_Address = 'error'
      }
    } catch (e) {
      row.Receiver_Address = 'error'
    }
    return row
  })
}

export async function resolveAddressJsonOld(jsonArray: any[], web3: any) {  // Deprecated
  // var bluePromise = require('bluebird')
  const res = await bluePromise.map(jsonArray, async (row: any) => {
    try {
      row.Receiver_Address = await resolveENS(row.Receiver_Address, web3)
      if (!isAddressWeb3(row.Receiver_Address, web3)) {
        row.Receiver_Address = 'error'
      }
    } catch (e) {
      row.Receiver_Address = 'error'
    }
    return row
  }, { concurrency: 16 });
  return res
}


export function isValidReferrer(referrerParam: string, refereeAddress: string, web3: any) {
  if (!referrerParam) { return false }

  const referrer = referrerParam.toLowerCase()
  if (!web3.utils.isAddress(referrer)) { return false }
  if (referrer === refereeAddress) { return false }

  return true
}

export function hasReferral(data: any, validReferrer: boolean) {
  if (!data) {
    if (validReferrer) {
      return true
    }
  } else {
    if (data.referrer) {
      if (data.referrer !== 'SELF') {
        return true
      }
    } else {
      if (validReferrer) {
        return true
      }
    }
  }
  return false
}

export async function createRecord(eeExist: boolean, chainId: number, referrerParam: string, refereeAddress: string, paidWeiComm: string, weiBonus: string, web3: any) {
  var referrerAddress = null
  const validReferrer = isValidReferrer(referrerParam, refereeAddress, web3)
  if (validReferrer) {
    referrerAddress = referrerParam.toLowerCase()
    // 1. Upsert ee Wallet with referrer
    if (!eeExist) {
      const eeWithRR = {
        chainId: chainId,
        address: refereeAddress,
        referrer: referrerAddress,
        totalValue: paidWeiComm,
        paid: '0x0',
        accEarnings: '0x0'
      }
      await API.graphql(graphqlOperation(mutations.createWallet, { input: eeWithRR }))
    } else {
      const eeWithRR = {
        chainId: chainId,
        address: refereeAddress,
        referrer: referrerAddress,
        totalValue: paidWeiComm // TODO: Change to += for the case of multiple referee
      }
      await API.graphql(graphqlOperation(mutations.updateWallet, { input: eeWithRR }))
    }

    // 2. Create ReferralRelationship 
    const refRelation = {
      chainId: chainId,
      referrerAddress: referrerAddress,
      refereeAddress: refereeAddress,
      accEarnings: weiBonus,
      lastTxTimestamp: Math.floor(Date.now() / 1000)
    }
    await API.graphql(graphqlOperation(mutations.createReferralRelationship, { input: refRelation }))

    // 3. Upsert rr Wallet
    const queryReferrer = await API.graphql(graphqlOperation(queries.getWallet, { chainId: chainId, address: referrerAddress })) as any
    const referrerData = queryReferrer.data.getWallet
    if (!referrerData) {
      const rr = {
        chainId: chainId,
        address: referrerAddress,
        totalValue: '0x0',
        paid: '0x0',
        accEarnings: weiBonus,
      }
      await API.graphql(graphqlOperation(mutations.createWallet, { input: rr }))
    } else {
      const rr = {
        chainId: chainId,
        address: referrerAddress,
        accEarnings: sumTwoHexStrings(referrerData.accEarnings, weiBonus, web3),
      }
      await API.graphql(graphqlOperation(mutations.updateWallet, { input: rr }))
    }
  } else {
    referrerAddress = 'SELF'
    // 1. Upsert ee Wallet SELF
    if (!eeExist) {
      const eeWithoutRR = {
        chainId: chainId,
        address: refereeAddress,
        referrer: referrerAddress,
        totalValue: paidWeiComm,
        paid: '0x0',
        accEarnings: '0x0'
      }
      await API.graphql(graphqlOperation(mutations.createWallet, { input: eeWithoutRR }))
    } else {
      const eeWithoutRR = {
        chainId: chainId,
        address: refereeAddress,
        referrer: referrerAddress,
        totalValue: paidWeiComm // TODO: Change to += for the case of multiple referee
      }
      await API.graphql(graphqlOperation(mutations.updateWallet, { input: eeWithoutRR }))
    }
  }

  return referrerAddress
}

export async function updateRecord(chainId: number, data: any, refereeAddress: string, paidWeiComm: string, weiBonus: string, web3: any) {
  const referrerAddress = data.referrer
  // 1. Update ee Wallet
  const eeUpdate = {
    chainId: chainId,
    address: refereeAddress,
    totalValue: sumTwoHexStrings(data.totalValue, paidWeiComm, web3)
  }
  await API.graphql(graphqlOperation(mutations.updateWallet, { input: eeUpdate }))

  if (referrerAddress !== 'SELF') {
    // 2. Update ReferralRelationship
    const queryRelation = await API.graphql(graphqlOperation(queries.getReferralRelationship, {
      chainId: chainId,
      referrerAddress: referrerAddress,
      refereeAddress: refereeAddress
    })) as any
    const relationData = queryRelation.data.getReferralRelationship
    const relationUpdate = {
      chainId: chainId,
      referrerAddress: referrerAddress,
      refereeAddress: refereeAddress,
      accEarnings: sumTwoHexStrings(relationData.accEarnings, weiBonus, web3),
      lastTxTimestamp: Math.floor(Date.now() / 1000)
    }
    await API.graphql(graphqlOperation(mutations.updateReferralRelationship, { input: relationUpdate }))

    // 3. Update rr Wallet
    const queryRR = await API.graphql(graphqlOperation(queries.getWallet, { chainId: chainId, address: referrerAddress })) as any
    const rrData = queryRR.data.getWallet
    const rrUpdate = {
      chainId: chainId,
      address: referrerAddress,
      accEarnings: sumTwoHexStrings(rrData.accEarnings, weiBonus, web3),
    }
    await API.graphql(graphqlOperation(mutations.updateWallet, { input: rrUpdate }))
  }

  return referrerAddress
}

export async function doesBTTokenExist(chainId: number, tokenAddress: string) {
  //
  const queryResult = await API.graphql(graphqlOperation(getTokenLean, { chainId: chainId, token_address: tokenAddress })) as any
  const data = queryResult.data.getToken
  //
  if (!data) {
    return false
  }
  return true
}

export async function createBTToken(chainId: number, tokenAddress: string, tokenStandard: string) {
  //
  const newBTToken = {
    chainId: chainId,
    token_address: tokenAddress,
    contract_type: tokenStandard
  }
  //
  await API.graphql(graphqlOperation(mutations.createToken, { input: newBTToken }))
}

export async function formatTxArr(
  senderAddress: string,
  tokenAddress: string,
  chainId: number,
  tokenStandard: string,
  web3: any,
  inputArr: any[]
) {
  // var bluePromise = require('bluebird')
  const txArr = await bluePromise.map(inputArr, async (input: any) => {
    return await formatBatchSendTransaction(senderAddress, tokenAddress, chainId, tokenStandard, web3, input)
  }, { concurrency: 4 })
  return txArr
}

export function checkInputError(jsonArray: any[], tokenStandard?: string) {
  return jsonArray.map(row => {
    let checkRow = { receiver: row.Receiver_Address === 'error' ? false : true, token_ids: true, amounts: true }

    if (tokenStandard === 'eth' || tokenStandard === 'erc20') {
      if (isNaN(row.Amount) || parseFloat(row.Amount) <= 0) {
        checkRow.amounts = false
      }
    }

    if (tokenStandard === 'erc721' || tokenStandard === 'erc1155') {
      if (isNaN(row.Token_ID) || (!Number.isInteger(Number(row.Token_ID))) || parseInt(row.Token_ID) < 0) {
        checkRow.token_ids = false
      }
    }

    if (tokenStandard === 'erc1155') {
      if (isNaN(row.Amount) || (!Number.isInteger(Number(row.Amount))) || parseInt(row.Amount) <= 0) {
        checkRow.amounts = false
      }
    }
    return checkRow
  })
}

export function splitArray(arr: any[], len: number) {
  var chunks = []
  var i = 0
  while (i < arr.length) {
    chunks.push(arr.slice(i, i += len));
  }
  return chunks;
}

export function aggregateJson(jsonArray: any[], tokenStandard: string) {
  return jsonArray.reduce((aggregate: any, o: any) => {
    var found = aggregate.find((p: any) => p.receiver === o.Receiver_Address);
    if (found) {
      if (tokenStandard === 'eth' || tokenStandard === 'erc20') {
        found.token_ids.push(0);
      } else {
        found.token_ids.push(o.Token_ID)
      }
      if (tokenStandard === 'erc721') {
        found.amounts.push(1);
      } else {
        found.amounts.push(parseFloat(o.Amount));
      }
    } else {
      var agg_obj = {
        receiver: o.Receiver_Address,
        token_ids: (tokenStandard === 'eth' || tokenStandard === 'erc20') ? [0] : [o.Token_ID],
        amounts: (tokenStandard === 'erc721') ? [1] : [parseFloat(o.Amount)]
      }
      aggregate.push(agg_obj);
    }
    return aggregate;
  }, []);
}

export function mergeDupPerAddr(aggInput: any) {
  let toAddresses: string[] = []
  let token_id_2d: string[][] = []
  let amount_2d: number[][] = []
  aggInput.forEach((row: any) => {
    toAddresses.push(row.receiver)
    token_id_2d.push(row.token_ids)
    amount_2d.push(row.amounts)
  })
  // Groupby sum the amount for each token_id
  let unique_token_id_2d: string[][] = []
  let unique_amount_2d: number[][] = []
  for (let i = 0; i < token_id_2d.length; i++) {
    let unique_token_id_1d: string[] = []
    let unique_amount_1d: number[] = []
    for (let j = 0; j < token_id_2d[i].length; j++) {
      var curIndex = unique_token_id_1d.indexOf(token_id_2d[i][j])
      if (curIndex < 0) {
        unique_token_id_1d.push(token_id_2d[i][j])
        unique_amount_1d.push(amount_2d[i][j])
      } else {
        unique_amount_1d[curIndex] += amount_2d[i][j]
      }
    }
    unique_token_id_2d.push(unique_token_id_1d)
    unique_amount_2d.push(unique_amount_1d)
  }
  return {
    'toAddresses': toAddresses,
    'unique_token_id_2d': unique_token_id_2d,
    'unique_amount_2d': unique_amount_2d
  }
}

export function mergeDuplicates(token_id_2d: string[][], amount_2d: number[][]) {
  // Groupby sum the amount for each token_id
  let unique_token_id_1d: string[] = []
  let unique_amount_1d: number[] = []
  for (let i = 0; i < token_id_2d.length; i++) {
    for (let j = 0; j < token_id_2d[i].length; j++) {
      var curIndex = unique_token_id_1d.indexOf(token_id_2d[i][j])
      if (curIndex < 0) {
        unique_token_id_1d.push(token_id_2d[i][j])
        unique_amount_1d.push(amount_2d[i][j])
      } else {
        unique_amount_1d[curIndex] += amount_2d[i][j]
      }
    }
  }
  return {
    'unique_token_id_1d': unique_token_id_1d,
    'unique_amount_1d': unique_amount_1d
  }
}

export function removeHeaderFromCsvStr(tokenStandard: string, csvStr: string, web3: any) {
  if (csvStr.length === 0) { return csvStr }

  var firstLine = ''
  var restLines = ''
  if (csvStr.includes('\n')) {
    const firstLineEnd = csvStr.indexOf('\n')
    firstLine = csvStr.slice(0, firstLineEnd)
    restLines = csvStr.slice(firstLineEnd + 1)
  } else {
    firstLine = csvStr
    restLines = ''
  }

  const headers = firstLine.split(',')
  const h0 = headers[0].toLowerCase()
  if (web3.utils.isAddress(h0) || h0.slice(-4) === '.eth') {
    if (tokenStandard === 'eth' || tokenStandard === 'erc20') { return csvStr }
    if (tokenStandard === 'erc721') { return csvStr }
    if (tokenStandard === 'erc1155') { return csvStr }
  }

  const h1 = headers[1].toLowerCase()
  if ((tokenStandard === 'eth' || tokenStandard === 'erc20') && !(h0.includes('receiver') || h0.includes('address') || h1.includes('amount'))) {
    return csvStr
  }

  if (tokenStandard === 'erc721' && !(h0.includes('receiver') || h0.includes('address') || h1.includes('token'))) {
    return csvStr
  }

  if (tokenStandard === 'erc1155') {
    const h2 = headers[2].toLowerCase()
    if (!(h0.includes('receiver') || h0.includes('address') || h1.includes('token') || h2.includes('amount'))) {
      return csvStr
    }
  }
  return restLines
}

export function convertToCsvStr(csvStr: string) {
  var lines = csvStr.split('\n').filter(row => { return row !== '' })  // Remove empty row

  lines = lines.map(row => row.replace(/^[\s\t|&,\uFF0C:\uFF1A;\uFF1B]+/, ''))  // Remove [\s\t|&,\uFF0C:\uFF1A;\uFF1B] at the beginning
  lines = lines.map(row => row.replace(/[\s\t|&,\uFF0C:\uFF1A;\uFF1B]+$/, ''))  // Remove [\s\t|&,\uFF0C:\uFF1A;\uFF1B] in the end
  lines = lines.map(row => row.replace(/[\s\t|&,\uFF0C:\uFF1A;\uFF1B]+/g, ','))  // Replace [\s\t|&,\uFF0C:\uFF1A;\uFF1B] with , in the middle

  lines = lines.filter(row => row.split(',').every(v => v !== ''))  // Remove ',,,'
  return lines.join('\n')
}

export function checkNumColsPerRow(row: string, nCols: number) {
  if (row === null || row.length === 0) { return false }
  return row.split(',').length === nCols
}

export function checkNumCols(tokenStandard: string, csvStr: string) {
  if (csvStr === null || csvStr.length === 0) { return false }

  const nCols = tokenStandard === 'erc1155' ? 3 : 2
  const rows = csvStr.split('\n')
  for (const row of rows) {
    if (!checkNumColsPerRow(row, nCols)) { return false }
  }
  return true
}

export function addHeaderToCsvStr(csvStr: string, web3: any, tokenStandard?: string) {
  if (csvStr === null || csvStr.length === 0) { return csvStr }

  var firstLine = ''
  if (csvStr.includes('\n')) {
    firstLine = csvStr.slice(0, csvStr.indexOf('\n'))
  } else {
    firstLine = csvStr
  }

  const headers = firstLine.split(',')
  const h0 = headers[0].toLowerCase()
  if (web3.utils.isAddress(h0) || h0.slice(-4) === '.eth') {
    if (tokenStandard === 'eth' || tokenStandard === 'erc20') { return 'Receiver_Address,Amount\n' + csvStr }
    if (tokenStandard === 'erc721') { return 'Receiver_Address,Token_ID\n' + csvStr }
    if (tokenStandard === 'erc1155') { return 'Receiver_Address,Token_ID,Amount\n' + csvStr }
  }

  const h1 = headers[1].toLowerCase()
  if ((tokenStandard === 'eth' || tokenStandard === 'erc20') && !(h0.includes('receiver') || h0.includes('address') || h1.includes('amount'))) {
    return 'Receiver_Address,Amount\n' + csvStr
  }

  if (tokenStandard === 'erc721' && !(h0.includes('receiver') || h0.includes('address') || h1.includes('token'))) {
    return 'Receiver_Address,Token_ID\n' + csvStr
  }

  if (tokenStandard === 'erc1155') {
    const h2 = headers[2].toLowerCase()
    if (!(h0.includes('receiver') || h0.includes('address') || h1.includes('token') || h2.includes('amount'))) {
      return 'Receiver_Address,Token_ID,Amount\n' + csvStr
    }
  }
  return csvStr
}

export function getTotalAmount(aggResult: any[]) {
  const totalNumberOfTokens = aggResult.reduce((sum: number, o: any) => {
    let partial: number = 0;
    for (var i in o.amounts) {
      partial = partial + parseFloat(o.amounts[i]);
    }
    sum += partial
    return sum;
  }, 0);
  return totalNumberOfTokens
}

export function countDecimals(value: number) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
}

export function roundNumber(value: number) {
  const nDeci = countDecimals(value)
  if (nDeci > 8) { return value.toFixed(8) }
  return value
}

export function getPlaceholder(tokenStandard: string, chainId?: number) {
  if (chainId === 1) {
    if (tokenStandard === 'eth') { return ethPlaceholderENS }
    if (tokenStandard === 'erc20') { return erc20PlaceholderENS }
    if (tokenStandard === 'erc721') { return erc721PlaceholderENS }
    if (tokenStandard === 'erc1155') { return erc1155PlaceholderENS }
  }
  if (tokenStandard === 'eth') { return ethPlaceholder }
  if (tokenStandard === 'erc20') { return erc20Placeholder }
  if (tokenStandard === 'erc721') { return erc721Placeholder }
  if (tokenStandard === 'erc1155') { return erc1155Placeholder }
  return erc20Placeholder
}

export function formattedTime(timestamp: number, dayOnly: boolean) {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(timestamp * 1000);
  //
  const year = date.getFullYear();
  const month = "0" + (date.getMonth() + 1);
  const day = "0" + date.getDate();
  if (dayOnly) {
    return year + '-' + month.slice(-2) + '-' + day.slice(-2)
  }

  // Hours part from the timestamp
  const hours = date.getHours();
  // Minutes part from the timestamp
  const minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  return year + '-' + month.slice(-2) + '-' + day.slice(-2) + ' ' + hours + ':' + minutes.slice(-2) + ':' + seconds.slice(-2);
}

export function sumTwoHexStrings(hex1: string, hex2: string, web3: any) {
  if (hex1 === null) { return hex2 }
  if (hex2 === null) { return hex1 }
  const bn1 = web3.utils.toBN(hex1)
  const bn2 = web3.utils.toBN(hex2)
  const newBN = bn1.add(bn2)
  return web3.utils.toHex(newBN);
}

export function subTwoHexStrings(hex1: string, hex2: string, web3: any) {
  if (hex2 === null) { return hex1 }
  const bn2 = web3.utils.toBN(hex2)
  if (hex1 === null) {
    const newBN = web3.utils.toBN(0).sub(bn2)
    return web3.utils.toHex(newBN);
  }
  const bn1 = web3.utils.toBN(hex1)
  const newBN = bn1.sub(bn2)
  return web3.utils.toHex(newBN);
}

////// BACKUP, DO NOT DELETE //////
// const sendBatchSendTransaction = useCallback(async function () {
//   if (!addressRef.current || !chainIdRef.current || !typeRef.current || !web3Ref.current || !aggTaskInputRef.current) { return }
//   const address = addressRef.current
//   const chainId = chainIdRef.current
//   const selectType = typeRef.current
//   const web3 = web3Ref.current
//   const aggTaskInput = aggTaskInputRef.current
//   if (!(selectType === 'eth' || tokenAddressRef.current)) { return }
//   const tokenAddress = tokenAddressRef.current

//   try {
//     // Open modal
//     toggleModal();

//     // Toggle pending request indicator
//     dispatch({
//       type: 'SEND_BATCH_TRANSFER',
//       pendingRequest: true,
//       pendingTxReceipt: false
//     });

//     // @ts-ignore
//     const sendTransaction = (_tx: any) => {
//       return new Promise((resolve, reject) => {
//         web3.eth
//           .sendTransaction(_tx)
//           .once("transactionHash", (txHash: string) => {
//             dispatch({
//               type: 'SEND_BATCH_TRANSFER',
//               pendingRequest: false,
//               pendingTxReceipt: true,
//               latestTxHash: txHash
//             });
//           })
//           .then((txReceipt: any) => resolve(txReceipt))
//           .catch((err: any) => reject(err));
//       });
//     }

//     const referrerParam = decode(query.get('ref'), K_ARR, DE_ORDER32)  // DO NOT CHANGE THIS LINE
//     setReferrerParam(referrerParam)

//     const refereeAddress = address.toLowerCase()
//     const validReferrer = isValidReferrer(referrerParam, refereeAddress, web3)
//     var discountRate = 0  // Must be integer
//     var bonusRate = 0  // Must be integer
//     var data = null

//     var formattedResult: { [k: string]: any } = {
//       Action: 'Safe Batch Transfer',
//       From: address,
//       To: BATCHSENDER_CONTRACT[chainId].address,
//     }
//     const inputArr = splitArray(aggTaskInput, maxRows)
//     for (let i = 0; i < inputArr.length; i++) {
//       try {
//         // Get referee Wallet, might not exist
//         const queryResult = await API.graphql(graphqlOperation(queries.getWallet, { chainId: chainId, address: refereeAddress })) as any;
//         data = queryResult.data.getWallet
//         if (hasReferral(data, validReferrer)) {
//           discountRate = 10
//           bonusRate = 20
//         }
//       } catch (error: any) {
//         console.error(error)
//       }
//       setDiscountRate(discountRate)

//       // Send transaction
//       const rawTx = await formatBatchSendTransaction(
//         address,
//         tokenAddress,
//         chainId,
//         selectType,
//         web3,
//         inputArr[i]
//       )
//       const tx = applyDiscount(rawTx, discountRate, web3)
//       const receipt: any = await sendTransaction(tx);

//       // Format display result
//       formattedResult.Value = weiToFixed(tx.value, 4, web3) + ' ETH'
//       formattedResult.Status = (receipt && receipt.blockNumber) ? 'Success' : 'Error'
//       var hashKey = 'Tx hash'
//       if (inputArr.length > 1) { hashKey += (i + 1) }
//       formattedResult[hashKey] = receipt.transactionHash

//       // Handle referral database
//       try {
//         const weiBonus = web3.utils.toHex(bnMultiplyRate(rawTx.weiComm, bonusRate, web3))
//         const paidWeiComm = web3.utils.toHex(bnMultiplyRate(rawTx.weiComm, 100 - discountRate, web3))
//         var referrerAddress = null
//         if (!data) {
//           referrerAddress = await createRecord(false, chainId, referrerParam, refereeAddress, paidWeiComm, weiBonus, web3)
//         } else {
//           if (!data.referrer) {
//             referrerAddress = await createRecord(true, chainId, referrerParam, refereeAddress, paidWeiComm, weiBonus, web3)
//           } else {
//             referrerAddress = await updateRecord(chainId, data, refereeAddress, paidWeiComm, weiBonus, web3)
//           }
//         }
//         // Insert Transaction in gaaphql
//         const txBTDetails = {
//           chainId: chainId,
//           txHash: receipt.transactionHash,
//           sender: refereeAddress,
//           referrer: referrerAddress,
//           tokenType: selectType,
//           tokenAddress: tokenAddress ? tokenAddress : null,
//           numberOfReceivers: inputArr[i].length,
//           tokenAmount: getTotalAmount(inputArr[i]),
//           value: tx.value, // hex string
//           commission: rawTx.weiComm,
//           discountRate: discountRate,
//           referralBonusRate: bonusRate,
//           timestamp: Math.floor(Date.now() / 1000)
//         }
//         await API.graphql(graphqlOperation(mutations.createTransaction, { input: txBTDetails }))
//       } catch (error: any) {
//         console.error(error)
//       }

//       dispatch({
//         type: 'SEND_BATCH_TRANSFER',
//         pendingRequest: true,
//         pendingTxReceipt: false
//       })
//     }

//     // display result
//     setApproveSendState(ApproveStatus.COMPLETED);
//     dispatch({
//       type: 'SEND_BATCH_TRANSFER',
//       pendingRequest: false,
//       result: formattedResult || null,
//       approveSendState: ApproveStatus.COMPLETED,
//       pendingTxReceipt: false,
//       errorMsg: ""
//     })
//   } catch (error: any) {
//     console.error(error); // tslint:disable-line
//     dispatch({
//       type: 'SEND_BATCH_TRANSFER',
//       pendingRequest: false,
//       result: null,
//       // approveSendState: ApproveStatus.ERROR,
//       pendingTxReceipt: false,
//       errorMsg: error.message
//     });
//   }
// }, []);
////// BACKUP, DO NOT DELETE //////
