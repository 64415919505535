import { useCallback, useEffect, useReducer, useMemo } from 'react'
import Web3 from 'web3'
import Web3Modal from 'web3modal'
import { useLocation } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import {
  showNFTrai,
  getEtherscanUrl, convertToCsvStr, checkNumCols, isValidReferrer, filterENS, getENSMap, resolveENS, isAddressWeb3, isEthChain, isChargeChain, chainId_map,
  addHeaderToCsvStr, resolveAddressJson, checkInputError, aggregateJson, mergeDupPerAddr, mergeDuplicates,
  enoughETHBalance, enoughERC20Balance, NetworkError, getNoERC721TokenIDs, getNoERC1155TokenIDs, getNoOpenStoreTokenIDsEth, getNoOpenStoreTokenIDsMatic,
  getCoinPrice, applyDiscount, bnMultiplyRate, getERC20TotalSupply, getERC20Amount, formatSetApprovalTransaction, formatBatchSendTransaction, getBatchSenderApprovalStatus,
  getTotalAmount, weiToFixed, roundNumber, hasReferral, createRecord, updateRecord, isTipChain,
  removeHeaderFromCsvStr, getPlaceholder, doesBTTokenExist, createBTToken, chainId_maxRows, getIndexForTokenStandard, getTokenStandard,
  chainId_ETH, chainId_SYMBOL, chainId_ADDR, chainId_PRICE, chainId_blackToken, supportedChainIds, shortAddress,
} from "../helpers/utilities";
import { encode, decode, K_ARR, ORDER32, DE_ORDER32 } from '../helpers/encode'  // DO NOT CHANGE THIS LINE
import useState from 'react-usestateref';
import Column from "../components/Column";
import TokenStandardSelector from "../components/TokenStandardSelector";
import TipSelector from "../components/TipSelector";
import AutoComplete from "../components/Autocomplete";
import Downloader from "../components/Downloader";
import GasTracker from "../components/GasTracker";
import FileUploader from "../components/FileUploader";
import SummaryCard from "../components/SummaryCard";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import ModalResult from "../components/ModalResult";
import useCollapse from 'react-collapsed'
import {
  SPanelContainer, SContainer, STightP, SLoaderDiv, SAddressLabelContainer, SWrongPhraseContainer, SBalances,
  STestButtonContainer, STextInputWrapper, SModalContainer, SModalTitle, SModalParagraph, SFormWrapper,
  SSendNoteMargin, SSendNoteLeft, SChainsNote, SCodeMirror, SInvalidParagraph
} from "../styles/SComponents";
import styled from "styled-components";
import { BATCHSENDER_CONTRACT } from "../constants";
import { API, graphqlOperation } from "aws-amplify";
import ReactGA from "react-ga4";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { EyeOutlined, SendOutlined } from "@ant-design/icons";
import '../styles/senderStyles.less';
import { Steps, Button, PageHeader, Radio, RadioChangeEvent } from 'antd';
import { isMobile } from "../utils/userAgent";
import TokenIDGif from "../assets/find_token_id.gif";
import EthIcon from '../assets/coin_logos/eth.png'
import SolIcon from '../assets/coin_logos/sol.png'
import BnbIcon from '../assets/coin_logos/bnb.png'
import MaticIcon from '../assets/coin_logos/matic.png'
import AvaxIcon from '../assets/coin_logos/avax.png'
import FtmIcon from '../assets/coin_logos/ftm.png'
import HecoIcon from '../assets/coin_logos/heco.svg'
import CroIcon from '../assets/coin_logos/cronos.svg'
import ArbiIcon from '../assets/coin_logos/arbitrum.svg'
import OpIcon from '../assets/coin_logos/optimism.png'
import KlayIcon from '../assets/coin_logos/klaytn.svg'
import GnoIcon from '../assets/coin_logos/gno.png'
import AuroIcon from '../assets/coin_logos/aurora.svg'
import CeloIcon from '../assets/coin_logos/celo.svg'
import FsnIcon from '../assets/coin_logos/fsn.png'
import EthwIcon from '../assets/coin_logos/ethw.png'
import EthfIcon from '../assets/coin_logos/ethf.png'
import GlmrIcon from '../assets/coin_logos/glmr.svg'
import MovrIcon from '../assets/coin_logos/movr.svg'
// import OpenStoreVideo from '../assets/openstore.mp4'
import OpenStoreGifEn from '../assets/openstore_en.gif'
import OpenStoreGifZh from '../assets/openstore_zh.gif'
import WeiXIcon from '../assets/weix.png'
import WeiX from '../assets/weix.jpg'
import RaiIcon from '../assets/nftrai.png'
import NFTrai from '../assets/nftrai.jpg'

const { Step } = Steps;

ReactGA.initialize("G-7J4MBTF15E");

const styles: any = {
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#041836",
    marginTop: "130px",
    padding: "10px",
  },
  stepsBarSider: {
    /* Auto layout */
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "4px 4px",
    //position: "relative",
    width: "225px",
    height: "664px",
    left: "88px",
    top: "124px",
    //zIndex: 99,
    /* Gray / gray-1 */
    //background: "#FFFFFF",
    //boxShadow: "inset -1px 0px 0px #F0F0F0",
  },
  supportedChainsDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "4px 4px",
    //position: "absolute",
    width: "200px",
    height: showNFTrai ? "850px" : "650px",
    right: "88px",
    top: "124px",
  },
  tipDiv: {
    marginBottom: '32px',
  },
  openStoreDiv: {
    textAlign: 'left',
  },
  sameWidth: {
    width: '100%',
    height: 'auto',
  },
  nftraiDiv: {
    marginTop: '20px',
    marginBottom: '4px',
  },
  sameWidthRound: {
    marginTop: '10px',
    width: '100%',
    height: 'auto',
    borderWidth: '2px',
    borderRadius: '15px',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  cryptoDiv: {
    marginLeft: '12px',
    marginTop: '4px',
    marginBottom: '4px',
    fontSize: '12px',
    alignItems: 'center',  // vertical center
    textAlign: 'left',
  },
  cryptoIcon: {
    width: "12px",
    height: "12px",
    marginRight: '3px',
  },
  raiIcon: {
    width: "24px",
    height: "24px",
    marginRight: '4px',
  },
  primaryButton: {
    backgroundColor: "#141516",
  },
  centerContent: {
    margin: 0,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  transparentButton: {
    marginBottom: '4px',
    backgroundColor: 'transparent',
  },
  noBorderInput: {
    marginTop: '4px',
    width: '410px',
    border: 'none',
    borderRadius: '25px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  centerDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  approveDiv: {
    width: '410px',
  },
  radioDiv: {
    width: '400px',
  },
};

const LeftDiv = styled.div`
  align-items: left;
  text-align: left;
`;


export enum ApproveStatus {
  DISCONNECTED = 0,  // user's wallet needs to be connected, landing page
  IDLE = 1,  // wallet connected, before user input, button disabled
  CONFIRMED_INPUT = 2,
  APPROVED = 3, // setApproval completed
  COMPLETED = 4,  // sending completed
  ERROR = -1  // fallback
};

enum ReferralStatus {
  REF_DB = 0,  // Has referer in database
  SELF_DB = 1,  // No referer in database
  REF_WEB = 2,  // Has referer on web page
  SELF_WEB = 3 // No referer on web page
};

type ISenderState = {
  web3Modal?: Web3Modal;
  fetching?: boolean;
  address?: string;
  web3Provider?: any;
  provider?: any;
  connected?: boolean;
  chainId?: number;
  networkId?: number;
  showModal: boolean;
  showQuestModal: boolean;
  pendingRequest?: boolean;
  result?: any | null;
  selectType?: "eth" | "erc20" | "erc721" | "erc1155";
  NFTTokenOptions?: any[];
  tokenOptions?: any[];
  placeholder?: string;
  manualInputMode?: boolean;
  textareaInput?: string;
  tokenName?: string;
  tokenAddress?: string;
  csvFile?: any | null;
  aggInput?: any[];
  approveSendState?: ApproveStatus;
  approvalStatus?: boolean;
  pendingConfirmToken?: boolean;
  pendingDataInputConfirm?: boolean;
  pendingTxReceipt?: boolean;
  latestTxHash?: string;
  didCheckAll?: number;
  errorMsg?: string;
  onConnect?: () => void;
  ERC20Balances?: any;
  NFTBalances?: any;
  tl: (key: string) => string;
}

// const erc20Placeholder = '0xc00F09F6463607C03a6828132cab9621B0b78fA9,1\n0x40De03083741bEA794652C02cfAFe610e680ba94,123.45\n0xdC7100D8069D6E180a9910E4c3fe2f1ee03467BE,2.3'
const erc721Placeholder = '0xc00F09F6463607C03a6828132cab9621B0b78fA9,122\n0xc00F09F6463607C03a6828132cab9621B0b78fA9,6934\n0x40De03083741bEA794652C02cfAFe610e680ba94,7698\n0xdC7100D8069D6E180a9910E4c3fe2f1ee03467BE,118'
const openStoreEth = '0x495f947276749ce646f68ac8c248420045cb7b5e'
const openStoreMatic = '0x2953399124f0cbb46d2cbacd8a89cf0599974963'

const INITIAL_STATE: ISenderState = {
  fetching: false,
  address: "",
  web3Provider: null,
  provider: null,
  connected: false,
  chainId: 1,
  networkId: 1,
  showModal: false,
  showQuestModal: false,
  pendingRequest: false,
  result: null,
  selectType: "erc721",
  NFTTokenOptions: [],
  tokenOptions: [],
  placeholder: erc721Placeholder,
  manualInputMode: true,
  textareaInput: '',
  tokenName: '',
  tokenAddress: '',
  csvFile: null,
  aggInput: [],
  approveSendState: ApproveStatus.DISCONNECTED,
  approvalStatus: false,
  pendingConfirmToken: true,
  pendingDataInputConfirm: true,
  pendingTxReceipt: false,
  latestTxHash: "",
  didCheckAll: 0,
  errorMsg: "",
  tl: (key: string) => key,
};

type ActionType =
  | {
    type: 'SET_WEB3_PROVIDER'
    provider?: ISenderState['provider']
    web3Provider?: ISenderState['web3Provider']
    address?: ISenderState['address']
    chainId?: ISenderState['chainId']
    //connected?: ISenderState['connected']
  }
  | {
    type: 'SET_ADDRESS'
    address?: ISenderState['address']
  }
  | {
    type: 'SET_CHAIN_ID'
    chainId?: ISenderState['chainId']
  }
  | {
    type: 'SET_TOKEN_STANDARD'
    selectType: ISenderState['selectType']
    placeholder: ISenderState['placeholder']
    textareaInput: ISenderState['textareaInput']
  }
  | {
    type: 'CONFIRM_INPUT'
    aggInput?: ISenderState['aggInput']
    fetching?: ISenderState['fetching']
    approveSendState?: ISenderState['approveSendState']
    approvalStatus?: ISenderState['approvalStatus']
    pendingConfirmToken?: ISenderState['pendingConfirmToken']
    pendingDataInputConfirm?: ISenderState['pendingDataInputConfirm']
    tokenAddress?: ISenderState['tokenAddress']
  }
  | {
    type: 'TOGGLE_MODAL'
    showModal: ISenderState['showModal']
  }
  | {
    type: 'TOGGLE_QUEST_MODAL'
    showQuestModal: ISenderState['showQuestModal']
  }
  | {
    type: 'TOGGLE_INPUT_MODE'
    manualInputMode: ISenderState['manualInputMode']
  }
  | {
    type: 'SET_TOKEN_ADDRESS'
    tokenAddress: ISenderState['tokenAddress']
  }
  | {
    type: 'DROP_FILE'
    textareaInput: ISenderState['textareaInput']
  }
  | {
    type: 'SET_TEXTAREA_INPUT'
    textareaInput: ISenderState['textareaInput']
    approveSendState: ISenderState['approveSendState']
  }
  | {
    type: 'SEND_SET_APPROVAL'
    pendingRequest: ISenderState['pendingRequest']
    pendingTxReceipt: ISenderState['pendingTxReceipt']
    latestTxHash?: ISenderState['latestTxHash']
    result?: ISenderState['result']
    approvalStatus?: ISenderState['approvalStatus']
    approveSendState?: ISenderState['approveSendState']
    errorMsg?: ISenderState['errorMsg']
  }
  | {
    type: 'SEND_BATCH_TRANSFER'
    pendingRequest: ISenderState['pendingRequest']
    pendingTxReceipt: ISenderState['pendingTxReceipt']
    latestTxHash?: ISenderState['latestTxHash']
    result?: ISenderState['result']
    approvalStatus?: ISenderState['approvalStatus']
    approveSendState?: ISenderState['approveSendState']
    errorMsg?: ISenderState['errorMsg']
  }
  | {
    type: 'RESET_WEB3_PROVIDER'
  }

function reducer(state: ISenderState, action: ActionType): ISenderState {
  switch (action.type) {
    case 'SET_WEB3_PROVIDER':
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        chainId: action.chainId,
      }
    case 'SET_ADDRESS':
      return {
        ...state,
        address: action.address,
      }
    case 'SET_CHAIN_ID':
      return {
        ...state,
        chainId: action.chainId,
      }
    case 'SET_TOKEN_STANDARD':
      return {
        ...state,
        selectType: action.selectType,
        placeholder: action.placeholder,
        textareaInput: action.textareaInput,
      }
    case 'CONFIRM_INPUT':
      return {
        ...state,
        fetching: action.fetching,
        approveSendState: action.approveSendState,
        pendingDataInputConfirm: action.pendingDataInputConfirm,
        approvalStatus: action.approvalStatus,
        aggInput: action.aggInput,
        tokenAddress: action.tokenAddress,
      }
    case 'TOGGLE_MODAL':
      return {
        ...state,
        showModal: action.showModal,
      }
    case 'TOGGLE_QUEST_MODAL':
      return {
        ...state,
        showQuestModal: action.showQuestModal,
      }
    case 'TOGGLE_INPUT_MODE':
      return {
        ...state,
        manualInputMode: action.manualInputMode
      }
    case 'SET_TOKEN_ADDRESS':
      return {
        ...state,
        tokenAddress: action.tokenAddress
      }
    case 'DROP_FILE':
      return {
        ...state,
        textareaInput: action.textareaInput,
      }
    case 'SET_TEXTAREA_INPUT':
      return {
        ...state,
        textareaInput: action.textareaInput,
        approveSendState: action.approveSendState,
      }
    case 'SEND_SET_APPROVAL':
      return {
        ...state,
        pendingRequest: action.pendingRequest,
        pendingTxReceipt: action.pendingTxReceipt,
        latestTxHash: action.latestTxHash,
        result: action.result,
        approvalStatus: action.approvalStatus,
        approveSendState: action.approveSendState,
        errorMsg: action.errorMsg,
      }
    case 'SEND_BATCH_TRANSFER':
      return {
        ...state,
        pendingRequest: action.pendingRequest,
        pendingTxReceipt: action.pendingTxReceipt,
        latestTxHash: action.latestTxHash,
        result: action.result,
        approvalStatus: action.approvalStatus,
        approveSendState: action.approveSendState,
        errorMsg: action.errorMsg,
      }
    case 'RESET_WEB3_PROVIDER':
      return INITIAL_STATE
    default:
      throw new Error()
  }
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const trackNftRai = () => ReactGA.event('visit_nftrai')
export const trackWeiX = () => ReactGA.event('visit_weix')

const Sender = (props: ISenderState) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { chainId: cId, address: addr, provider, web3Provider: web3, ERC20Balances, NFTBalances, tl } = props;
  const { errorMsg, fetching, manualInputMode, result, pendingRequest, pendingTxReceipt,
    showModal, showQuestModal, latestTxHash, placeholder, web3Modal } = state;
  //const [fetching, setFetching] = useState(false);
  const [web3Provider, setWeb3Provider, web3Ref] = useState(web3);
  const [address, setAddress, addressRef] = useState(addr);
  const [chainId, setChainId, chainIdRef] = useState(cId);
  const [textareaInput, setTextareaInput, textareaRef] = useState('');
  const [selectType, setSelectType, selectTypeRef] = useState('erc721');
  const [tokenAddress, setTokenAddress, tokenAddressRef] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [referralStatus, setReferralStatus, referralStatusRef] = useState(ReferralStatus.SELF_DB);
  const [refInputVal, setRefInputVal, refInputValRef] = useState('');
  const [tips, setTips, tipsRef] = useState(0);
  const [referrerCode, setReferrerCode, referrerCodeRef] = useState('');
  const [referrerParam, setReferrerParam, referrerParamRef] = useState('');
  const [discountRate, setDiscountRate, discountRateRef] = useState(0);
  const [blackToken, setBlackToken] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [maxRows, setMaxRows] = useState(300);
  const [wrongMaxRows, setWrongMaxRows] = useState(false);
  const [wrongCols, setWrongCols] = useState(false);
  const [wrongRows, setWrongRows] = useState(false);
  const [wrongAddrPhrase, setWrongAddrPhrase] = useState('');
  const [wrongTokenIdPhrase, setWrongTokenIdPhrase] = useState('');
  const [wrongAmountPhrase, setWrongAmountPhrase] = useState('');
  const [wrongCoinBalance, setWrongCoinBalance] = useState(false);
  const [duplicate721, setDuplicate721] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [inSufTokenIdArr, setInSufTokenIdArr] = useState([]);
  const [unMintLen, setUnMintLen] = useState(0);
  const [unMintTokenId, setUnMintTokenId] = useState('');
  const [openStoreNFTEth, setOpenStoreNFTEth] = useState(false);
  const [aggInput, setAggInput, aggInputRef] = useState([]);
  const [approveSendState, setApproveSendState] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState(false);
  const [isSendAmount, setIsSendAmount, isSendAmountRef] = useState(false);
  const [pendingDataInputConfirm, setPendingDataInputConfirm] = useState(true);
  /* we want to memorize the input state and get back to it when clicking the back button */
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const query: any = useQuery();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/app" });
    setWeb3Provider(web3);
    setAddress(addr);
    setChainId(cId);
    /* handle the case when in summary page and switched wallet */
    setPendingDataInputConfirm(true);

    if (web3 && addr && cId) {
      setApproveSendState(ApproveStatus.IDLE);
    } else {
      setApproveSendState(ApproveStatus.DISCONNECTED);
    }
    dispatch({
      type: 'SET_WEB3_PROVIDER',
      provider: provider,
      web3Provider: web3,
      address: addr,
      chainId: chainId
    })

    if (web3 && addr && cId) {
      const checkReferral = async () => {
        var referrerCode = query.get('ref') ?? ''
        var referrerParam = decode(referrerCode, K_ARR, DE_ORDER32)  // DO NOT CHANGE THIS LINE
        const refereeAddress = addr.toLowerCase()
        const validReferrer = isValidReferrer(referrerParam, refereeAddress, web3)
        // Get referee Wallet, might not exist
        const queryResult = await API.graphql(graphqlOperation(queries.getWallet, { chainId: chainId, address: refereeAddress })) as any;
        const data = queryResult.data.getWallet
        var discountRate = 0  // Must be integer
        if (!data) {
          if (validReferrer) {
            setReferralStatus(ReferralStatus.REF_WEB)
            discountRate = 10
          } else {
            setReferralStatus(ReferralStatus.SELF_WEB)
          }
        } else {
          if (data.referrer) {
            if (data.referrer !== 'SELF') {
              setReferralStatus(ReferralStatus.REF_DB)
              referrerParam = data.referrer
              referrerCode = encode(referrerParam, K_ARR, ORDER32)
              discountRate = 10
            } else {
              setReferralStatus(ReferralStatus.SELF_DB)
            }
          } else {
            if (validReferrer) {
              setReferralStatus(ReferralStatus.REF_WEB)
              discountRate = 10
            } else {
              setReferralStatus(ReferralStatus.SELF_WEB)
            }
          }
        }
        setReferrerCode(referrerCode ?? '')
        setRefInputVal(referrerCode ?? '')
        setReferrerParam(referrerParam)
        setDiscountRate(discountRate)
      }

      checkReferral().catch(console.error)
    }
  }, [web3, addr, cId])


  const handleSelectTokenStandard = useCallback(function (type: any) {
    //if (web3Modal && web3Modal.cachedProvider) {
    //  onConnect();
    //}
    setBlackToken(false)
    setInvalidToken(false)
    setTokenName('')
    setOpenStoreNFTEth(false)
    setTextareaInput('');
    setSelectType(type);
    setPendingDataInputConfirm(true);
    dispatch({
      type: 'SET_TOKEN_STANDARD',
      selectType: type,
      placeholder: getPlaceholder(type, chainIdRef.current),
      textareaInput: ''
    })
  }, [pendingDataInputConfirm]);


  const confirmInput = useCallback(async function () {
    setTips(0)
    setBlackToken(false)
    setInvalidToken(false)
    setWrongMaxRows(false)
    setWrongCols(false)
    setWrongRows(false)
    setWrongAddrPhrase('')
    setWrongTokenIdPhrase('')
    setWrongAmountPhrase('')
    setWrongCoinBalance(false)
    setDuplicate721(false)
    setNetworkError(false)
    setInSufTokenIdArr([])
    setUnMintLen(0)
    setUnMintTokenId('')
    setOpenStoreNFTEth(false)

    // send event to google analytics
    ReactGA.event('preview_transaction');

    const web3 = web3Ref.current
    const selectType = selectTypeRef.current
    const address = addressRef.current
    const chainId = chainIdRef.current
    const textareaInput = textareaRef.current
    if (!web3 || !selectType || !address || !chainId || !textareaInput) { return }

    const tokenAddress = tokenAddressRef.current
    if (!tokenAddress && selectType !== 'eth') { return }

    // Check blacklist and valid tokenAddress
    const tokenLowerCase = tokenAddress.toLowerCase()
    if (selectType !== 'eth') {
      const tokenBlackList = chainId_blackToken.get(chainId)
      if (tokenBlackList && tokenBlackList.includes(tokenLowerCase)) {
        setBlackToken(true)
        return
      }
      if (!web3.utils.isAddress(tokenLowerCase)) {
        setInvalidToken(true)
        return
      }
    }

    try {
      const csvStr = convertToCsvStr(textareaInput);

      // Check number of columns
      if (!checkNumCols(selectType, csvStr)) {
        setWrongCols(true);
        return
      }

      const CSVToJSON = require('csvtojson');
      const inputJsonArray = await CSVToJSON().fromString(addHeaderToCsvStr(csvStr, web3, selectType));
      // Check number of rows
      if (inputJsonArray.length === 0) {
        setWrongRows(true)
        return
      }

      const addrArr = inputJsonArray.map((row: { Receiver_Address: any }) => row.Receiver_Address)
      const ensArr = filterENS(addrArr)
      if (ensArr.length > 0) {
        dispatch({ type: 'CONFIRM_INPUT', fetching: true })
      }
      const ensMap = await getENSMap(ensArr)

      // const inputJsonResolve = await resolveAddressJson(inputJsonArray, web3) // inputJsonResolve is shallow copy of inputJsonArray
      const inputJsonResolve = resolveAddressJson(inputJsonArray, ensMap, web3) // inputJsonResolve is shallow copy of inputJsonArray

      // Check whether input is valid
      const jsonIndicator = checkInputError(inputJsonResolve, selectType)
      let wrongAddrArr = []
      let wrongTokenIdArr = []
      let wrongAmountArr = []
      for (let i = 0; i < jsonIndicator.length; i++) {
        var indRow = jsonIndicator[i]
        var rowNum = i + 1
        if (!indRow.receiver) { wrongAddrArr.push(rowNum) }
        if (!indRow.token_ids) { wrongTokenIdArr.push(rowNum) }
        if (!indRow.amounts) { wrongAmountArr.push(rowNum) }
      }

      if (wrongAddrArr.length > 0) { setWrongAddrPhrase(wrongAddrArr.join(', ')) }

      if (wrongTokenIdArr.length > 0) { setWrongTokenIdPhrase(wrongTokenIdArr.join(', ')) }

      if (wrongAmountArr.length > 0) { setWrongAmountPhrase(wrongAmountArr.join(', ')) }

      if (wrongAddrArr.length > 0 || wrongTokenIdArr.length > 0 || wrongAmountArr.length > 0) {
        dispatch({ type: 'CONFIRM_INPUT', fetching: false })
        return
      }

      // Aggregate json
      const aggInput = aggregateJson(inputJsonResolve, selectType);
      setAggInput(aggInput);
      dispatch({ type: 'CONFIRM_INPUT', aggInput: aggInput })

      // Check max number of rows <= maxRows
      const cMaxRowsArr = [1000, 1000, 300, 600]
      const maxRowsArr = chainId ? chainId_maxRows.get(chainId) ?? cMaxRowsArr : cMaxRowsArr
      const maxRows = maxRowsArr[getIndexForTokenStandard(selectType)]
      if (aggInput.length > maxRows) {
        setMaxRows(maxRows)
        setWrongMaxRows(true)
        dispatch({ type: 'CONFIRM_INPUT', fetching: false })
        return
      }

      // Check balance
      const uInputPerAddr = mergeDupPerAddr(aggInput)
      const token_id_2d = uInputPerAddr.unique_token_id_2d
      const amount_2d = uInputPerAddr.unique_amount_2d

      if (selectType === 'eth') {
        const enoughBalance = await enoughETHBalance(address, amount_2d, web3)
        if (!enoughBalance) {
          setWrongCoinBalance(true)
          dispatch({ type: 'CONFIRM_INPUT', fetching: false })
          return
        }
      } else if (selectType === 'erc20') {
        const enoughBalance = await enoughERC20Balance(address, tokenAddress, amount_2d, web3)
        if (!enoughBalance) {
          setWrongCoinBalance(true)
          dispatch({ type: 'CONFIRM_INPUT', fetching: false })
          return
        }
      } else {
        const uniqueInput = mergeDuplicates(token_id_2d, amount_2d)
        const token_id_1d = uniqueInput.unique_token_id_1d
        const amount_1d = uniqueInput.unique_amount_1d

        if (selectType === 'erc721') {
          // Check unique ERC721 tokenId
          for (let i = 0; i < amount_1d.length; i++) {
            if (amount_1d[i] > 1) {
              setDuplicate721(true)
              dispatch({ type: 'CONFIRM_INPUT', fetching: false })
              return
            }
          }
        }

        dispatch({ type: 'CONFIRM_INPUT', fetching: true })
        if (selectType === 'erc721') {
          const noERC721TokenIDs = await getNoERC721TokenIDs(address, tokenAddress, token_id_1d, web3)
          if (noERC721TokenIDs.length > 0) {
            if (noERC721TokenIDs.includes(NetworkError)) {
              setNetworkError(true)
            } else {
              setInSufTokenIdArr(noERC721TokenIDs.map((tokenID: string) => shortAddress(tokenID)))
            }
            dispatch({ type: 'CONFIRM_INPUT', fetching: false })
            return
          }
        } else if (selectType === 'erc1155') {
          const noERC1155TokenIDs = await getNoERC1155TokenIDs(address, tokenAddress, token_id_1d, amount_1d, web3)
          if (noERC1155TokenIDs.length > 0) {
            if (noERC1155TokenIDs.includes(NetworkError)) {
              setNetworkError(true)
            } else {
              setInSufTokenIdArr(noERC1155TokenIDs.map((tokenID: string) => shortAddress(tokenID)))
            }
            dispatch({ type: 'CONFIRM_INPUT', fetching: false })
            return
          }
          if (chainId === 1 && tokenLowerCase === openStoreEth) {
            const noOpenStoreTokenIDs = await getNoOpenStoreTokenIDsEth(address, tokenAddress, token_id_1d, amount_1d, web3)
            const noLen = noOpenStoreTokenIDs.length
            if (noLen > 0) {
              if (noOpenStoreTokenIDs.includes(NetworkError)) {
                setNetworkError(true)
              } else {
                const shortTokenId = shortAddress(noOpenStoreTokenIDs[0])
                setUnMintLen(noLen)
                setUnMintTokenId(shortTokenId)
              }
              dispatch({ type: 'CONFIRM_INPUT', fetching: false })
              return
            }
          }
          if (chainId === 137 && tokenLowerCase === openStoreMatic) {
            const noOpenStoreTokenIDs = await getNoOpenStoreTokenIDsMatic(address, tokenAddress, token_id_1d, web3)
            const noLen = noOpenStoreTokenIDs.length
            if (noLen > 0) {
              if (noOpenStoreTokenIDs.includes(NetworkError)) {
                setNetworkError(true)
              } else {
                const shortTokenId = shortAddress(noOpenStoreTokenIDs[0])
                setUnMintLen(noLen)
                setUnMintTokenId(shortTokenId)
              }
              dispatch({ type: 'CONFIRM_INPUT', fetching: false })
              return
            }
          }
        }
      }

      // Get referrerParam and discountRate
      const referrerParam = referrerParamRef.current
      const refereeAddress = address.toLowerCase()
      const validReferrer = isValidReferrer(referrerParam, refereeAddress, web3)
      var discountRate = 0  // Must be integer
      var bonusRate = 0  // Must be integer
      try {
        // Get referee Wallet, might not exist
        const queryResult = await API.graphql(graphqlOperation(queries.getWallet, { chainId: chainId, address: refereeAddress })) as any;
        const data = queryResult.data.getWallet
        if (hasReferral(data, validReferrer)) {
          discountRate = 10
          bonusRate = 20
        }
      } catch (error: any) {
        // console.error(error)
      }
      setDiscountRate(discountRate)

      var erc20AmountEther = 0
      if (selectType === 'erc20') {
        for (let i = 0; i < aggInput.length; i++) {
          var row = aggInput[i].amounts
          for (let j = 0; j < row.length; j++) {
            erc20AmountEther += row[j]
          }
        }
      }

      var approvalStatus = false
      try {
        approvalStatus = await getBatchSenderApprovalStatus(address, tokenAddress, selectType, erc20AmountEther, chainId, web3)
      } catch (error: any) {
        // console.error(error)
      }

      // TODO: Test this is OK.
      // Set state variables
      setApproveSendState(ApproveStatus.CONFIRMED_INPUT);
      dispatch({ type: 'CONFIRM_INPUT', approveSendState: ApproveStatus.CONFIRMED_INPUT });

      setApprovalStatus(approvalStatus);
      setPendingDataInputConfirm(false);
      dispatch({ type: 'CONFIRM_INPUT', approvalStatus: approvalStatus, pendingConfirmToken: false })

      if (approvalStatus) {
        setApproveSendState(ApproveStatus.APPROVED);
        dispatch({ type: 'CONFIRM_INPUT', approveSendState: ApproveStatus.APPROVED })
      }
      dispatch({ type: 'CONFIRM_INPUT', fetching: false })
    } catch (error) {
      // console.error(error);
      setApproveSendState(ApproveStatus.ERROR);
      dispatch({ type: 'CONFIRM_INPUT', fetching: false, approveSendState: ApproveStatus.ERROR })
    }
  }, []);

  const toggleModal = useCallback(function () {
    dispatch({
      type: 'TOGGLE_MODAL',
      showModal: !showModal
    });
  }, [showModal]);

  const toggleQuestModal = useCallback(function () {
    dispatch({
      type: 'TOGGLE_QUEST_MODAL',
      showQuestModal: !showQuestModal
    });
  }, [showQuestModal]);

  const toggleManualInputMode = useCallback(function () {
    dispatch({
      type: 'TOGGLE_INPUT_MODE',
      manualInputMode: !manualInputMode
    });
  }, [manualInputMode]);

  const onIsSendAmount = (e: RadioChangeEvent) => {
    setIsSendAmount(e.target.value);
  };

  const handleDropdownMenu = useCallback(function (token: string, tokenName = '') {
    setBlackToken(false)
    setInvalidToken(false)
    setWrongCoinBalance(false)
    setDuplicate721(false)
    setNetworkError(false)
    setInSufTokenIdArr([])
    setUnMintLen(0)
    setUnMintTokenId('')
    setOpenStoreNFTEth(false)
    const chainId = chainIdRef.current
    const selectType = selectTypeRef.current

    if (chainId && selectType && selectType !== 'eth') {
      const tokenLowerCase = token.toLowerCase()
      const tokenBlackList = chainId_blackToken.get(chainId)
      if (tokenBlackList && tokenBlackList.includes(tokenLowerCase)) {
        setBlackToken(true)
      }
      if (!web3Ref.current.utils.isAddress(tokenLowerCase)) {
        setInvalidToken(true)
      }

      if (selectType === 'erc1155' && chainId === 1 && tokenLowerCase === openStoreEth) {
        setOpenStoreNFTEth(true)
      }
    }

    setTokenAddress(token);
    setTokenName(tokenName);
    dispatch({
      type: 'SET_TOKEN_ADDRESS',
      tokenAddress: token
    })
  }, []);

  const handleDropFile = useCallback(function (csvStr: string) {
    if (!selectTypeRef.current) { return }
    csvStr = removeHeaderFromCsvStr(selectTypeRef.current, csvStr, web3Ref.current)
    toggleManualInputMode()
    setTextareaInput(csvStr)
    setPendingDataInputConfirm(true)
    dispatch({
      type: 'DROP_FILE',
      textareaInput: csvStr,
    })
  }, [manualInputMode]);

  const handleCodeMirrorChange = useCallback(function (inputValue: string) {
    setWrongMaxRows(false)
    setWrongCols(false)
    setWrongRows(false)
    setWrongAddrPhrase('')
    setWrongTokenIdPhrase('')
    setWrongAmountPhrase('')
    setWrongCoinBalance(false)
    setDuplicate721(false)
    setNetworkError(false)
    setInSufTokenIdArr([])
    setUnMintLen(0)
    setUnMintTokenId('')
    setTextareaInput(inputValue)
    setApproveSendState(ApproveStatus.IDLE)
    setPendingDataInputConfirm(true)
    dispatch({
      type: 'SET_TEXTAREA_INPUT',
      textareaInput: inputValue,
      //pendingDataInputConfirm: true,
      approveSendState: ApproveStatus.IDLE
    })
  }, [textareaInput]);

  const sendSetApprovalTransaction = useCallback(async function () {
    setTips(0)
    const web3 = web3Ref.current;
    const address = addressRef.current;
    const tokenAddress = tokenAddressRef.current;
    const selectType = selectTypeRef.current;
    const chainId = chainIdRef.current;
    if (!web3 || !address || !tokenAddress || !selectType || !chainId) { return }

    try {
      //
      ReactGA.event('set_token_approval');
      // Open modal
      toggleModal();

      // Toggle pending request indicator
      dispatch({
        type: 'SEND_SET_APPROVAL',
        pendingRequest: true,
        pendingTxReceipt: false
      });

      // @ts-ignore
      const sendTransaction = (_tx: any) => {
        return new Promise((resolve, reject) => {
          web3.eth
            .sendTransaction(_tx)
            .once("transactionHash", (txHash: string) => {
              dispatch({
                type: 'SEND_SET_APPROVAL',
                pendingRequest: false,
                pendingTxReceipt: true,
                latestTxHash: txHash
              });
            })
            .then((txReceipt: any) => resolve(txReceipt))
            .catch((err: any) => reject(err));
        });
      }

      // Send transaction
      var amountWei = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'  // 2 ** 256 - 1
      if (selectType === 'erc20') {
        if (isSendAmountRef.current && aggInputRef.current) {
          const aggInput = aggInputRef.current
          const uInputPerAddr = mergeDupPerAddr(aggInput)
          const amount_2d = uInputPerAddr.unique_amount_2d
          amountWei = await getERC20Amount(tokenAddress, amount_2d, web3)  // Considered error 1e-6
        } else {
          amountWei = await getERC20TotalSupply(tokenAddress, web3)
        }
      }
      const tx = await formatSetApprovalTransaction(address, tokenAddress, selectType, chainId, amountWei, true, web3)
      const receipt: any = await sendTransaction(tx)

      // Format display result
      const formattedResult = {
        Action: 'Approve',
        From: address,
        To: tokenAddress,
        Value: `0 ${chainId ? chainId_ETH.get(chainId) : ''}`,
        Status: (receipt && receipt.blockNumber) ? 'Success' : 'Error',
        'Tx hash': receipt.transactionHash,
      }

      // Handle referral database
      try {
        const referrerParam = referrerParamRef.current
        const refereeAddress = address.toLowerCase()
        const queryResult = await API.graphql(graphqlOperation(queries.getWallet, { chainId: chainId, address: refereeAddress })) as any
        const data = queryResult.data.getWallet
        const paidWeiComm = '0x0'
        const weiBonus = '0x0'
        if (!data) {
          await createRecord(false, chainId, referrerParam, refereeAddress, paidWeiComm, weiBonus, web3)
        } else if (!data.referrer) {
          await createRecord(true, chainId, referrerParam, refereeAddress, paidWeiComm, weiBonus, web3)
        }

        // Create token record if not exist
        const btTokenExist = await doesBTTokenExist(chainId, tokenAddress.toLowerCase());
        if (!btTokenExist) {
          await createBTToken(chainId, tokenAddress.toLowerCase(), selectType);
        }

        // Update WalletTokenRelationship Table
        const newBTWalletTokenRelationship = {
          chainId: chainId,
          walletAddress: refereeAddress,
          tokenAddress: tokenAddress,
          didApprove: true,
          timestamp: Math.floor(Date.now() / 1000)
        }
        await API.graphql(graphqlOperation(mutations.createWalletTokenRelationship, { input: newBTWalletTokenRelationship }))
      } catch (error: any) {
        // console.error(error)
      }

      // Display result
      setApproveSendState(ApproveStatus.APPROVED);
      setApprovalStatus(true);
      dispatch({
        type: 'SEND_SET_APPROVAL',
        pendingRequest: false,
        result: formattedResult || null,
        approvalStatus: true,
        approveSendState: ApproveStatus.APPROVED,
        pendingTxReceipt: false,
        errorMsg: ""
      });
    } catch (error: any) {
      // console.error(error); // tslint:disable-line
      dispatch({
        type: 'SEND_SET_APPROVAL',
        pendingRequest: false,
        result: null,
        // approveSendState: ApproveStatus.ERROR,
        pendingTxReceipt: false,
        errorMsg: error.message
      });
    }
  }, []);


  const sendBatchSendTransaction = useCallback(async function () {
    if (!addressRef.current || !chainIdRef.current || !selectTypeRef.current || !web3Ref.current || !aggInputRef.current) { return }
    const address = addressRef.current
    const chainId = chainIdRef.current
    const selectType = selectTypeRef.current
    const web3 = web3Ref.current
    const aggInput = aggInputRef.current
    if (!(selectType === 'eth' || tokenAddressRef.current)) { return }
    const tokenAddress = tokenAddressRef.current

    try {
      //
      ReactGA.event('batch_transfer');
      // Open modal
      toggleModal();

      // Toggle pending request indicator
      dispatch({
        type: 'SEND_BATCH_TRANSFER',
        pendingRequest: true,
        pendingTxReceipt: false
      });

      // @ts-ignore
      const sendTransaction = (_tx: any) => {
        return new Promise((resolve, reject) => {
          web3.eth
            .sendTransaction(_tx)
            .once("transactionHash", (txHash: string) => {
              dispatch({
                type: 'SEND_BATCH_TRANSFER',
                pendingRequest: false,
                pendingTxReceipt: true,
                latestTxHash: txHash
              });
            })
            .then((txReceipt: any) => resolve(txReceipt))
            .catch((err: any) => reject(err));
        });
      }

      const referrerParam = referrerParamRef.current
      const refereeAddress = address.toLowerCase()
      const validReferrer = isValidReferrer(referrerParam, refereeAddress, web3)
      var discountRate = 0  // Must be integer
      var bonusRate = 0  // Must be integer
      var data = null

      try {
        // Get referee Wallet, might not exist
        const queryResult = await API.graphql(graphqlOperation(queries.getWallet, { chainId: chainId, address: refereeAddress })) as any;
        data = queryResult.data.getWallet
        if (hasReferral(data, validReferrer)) {
          discountRate = 10
          bonusRate = 20
        }
      } catch (error: any) {
        // console.error(error)
      }
      setDiscountRate(discountRate)

      // Send transaction
      const rawTx = await formatBatchSendTransaction(
        address,
        tokenAddress,
        chainId,
        selectType,
        web3,
        aggInput
      )

      const tips = tipsRef.current
      var tipVal = 0
      if (tips > 0 && isTipChain(chainId)) {
        try {
          // const symbol = (chainId ? chainId_SYMBOL.get(chainId) : chainId_SYMBOL.get(1)) ?? 'ETH-USD'
          const cAddr = (chainId ? chainId_ADDR.get(chainId) : chainId_ADDR.get(1)) ?? '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
          const defaultPrice = (chainId ? chainId_PRICE.get(chainId) : chainId_PRICE.get(1)) ?? 2000
          // const coinPrice = await getCoinBasePrice(symbol, defaultPrice)
          const coinPrice = await getCoinPrice(cAddr, defaultPrice)
          // console.log('AVAX price = ', coinPrice)
          ////
          // const ads = [1, 56, 137, 43114, 250, 128, 25, 1284, 1285]
          // for (let i = 0; i < ads.length; i++) {
          //   const coinPrice = await getCoinPrice(chainId_ADDR.get(ads[i]) ?? '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', defaultPrice)
          //   console.log('Chain = ', ads[i])
          //   console.log('coinPrice = ', coinPrice)
          // }
          ////

          tipVal = tips / coinPrice
        } catch (error: any) {
        }
      }
      const tx = applyDiscount(rawTx, discountRate, tipVal, web3)
      const receipt: any = await sendTransaction(tx);

      // Format display result
      const formattedResult = {
        Action: 'Safe Batch Transfer',
        From: address,
        To: BATCHSENDER_CONTRACT[chainId].address,
        Value: `${weiToFixed(tx.value, 4, web3)} ${chainId ? chainId_ETH.get(chainId) : ''}`,
        Status: (receipt && receipt.blockNumber) ? 'Success' : 'Error',
        'Tx hash': receipt.transactionHash,
      }

      // Handle referral database
      try {
        const weiBonus = web3.utils.toHex(bnMultiplyRate(rawTx.weiComm, bonusRate, web3))
        const paidWeiComm = web3.utils.toHex(bnMultiplyRate(rawTx.weiComm, 100 - discountRate, web3))
        var referrerAddress = null
        if (!data) {
          referrerAddress = await createRecord(false, chainId, referrerParam, refereeAddress, paidWeiComm, weiBonus, web3)
        } else {
          if (!data.referrer) {
            referrerAddress = await createRecord(true, chainId, referrerParam, refereeAddress, paidWeiComm, weiBonus, web3)
          } else {
            referrerAddress = await updateRecord(chainId, data, refereeAddress, paidWeiComm, weiBonus, web3)
          }
        }
        // Insert Transaction in gaaphql
        const txBTDetails = {
          chainId: chainId,
          txHash: receipt.transactionHash,
          sender: refereeAddress,
          referrer: referrerAddress,
          tokenType: selectType,
          tokenAddress: tokenAddress ? tokenAddress : null,
          numberOfReceivers: aggInput.length,
          tokenAmount: getTotalAmount(aggInput),
          value: tx.value, // hex string
          commission: rawTx.weiComm,
          discountRate: discountRate,
          referralBonusRate: bonusRate,
          timestamp: Math.floor(Date.now() / 1000)
        }
        await API.graphql(graphqlOperation(mutations.createTransaction, { input: txBTDetails }))
      } catch (error: any) {
        // console.error(error)
      }

      // display result
      setApproveSendState(ApproveStatus.COMPLETED);
      dispatch({
        type: 'SEND_BATCH_TRANSFER',
        pendingRequest: false,
        result: formattedResult || null,
        approveSendState: ApproveStatus.COMPLETED,
        pendingTxReceipt: false,
        errorMsg: ''
      });
    } catch (error: any) {
      // console.error(error); // tslint:disable-line
      dispatch({
        type: 'SEND_BATCH_TRANSFER',
        pendingRequest: false,
        result: null,
        // approveSendState: ApproveStatus.ERROR,
        pendingTxReceipt: false,
        errorMsg: error.message
      });
    }
  }, []);

  const handleClickBackButton = useCallback(function () {
    setTips(0)
    setPendingDataInputConfirm(true);
    setBackButtonClicked(true);
    setApproveSendState(ApproveStatus.IDLE);
  }, []);

  const handleRefInputChange = useCallback(function (e) {
    setRefInputVal(e.target.value);
  }, []);

  const handleSelectTippingAmount = useCallback(function (val: any) {
    setTips(val);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      var referrerCode = refInputValRef.current
      const address = addressRef.current
      const web3 = web3Ref.current
      const referralStatus = referralStatusRef.current
      if (referrerCode && referrerCode.length > 0 && referrerCode !== referrerCodeRef.current && address && web3 && (referralStatus === ReferralStatus.REF_WEB || referralStatus === ReferralStatus.SELF_WEB)) {
        const checkReferral = async () => {
          var referrerParam = null
          const referrerAddress = await resolveENS(referrerCode.toLowerCase(), web3)
          if (isAddressWeb3(referrerAddress, web3)) {
            referrerParam = referrerAddress.toLowerCase()
            referrerCode = encode(referrerParam, K_ARR, ORDER32)
          } else {
            if (referrerCode.includes('ref=')) {
              const splitArr = referrerCode.split('?')
              if (splitArr.length > 1) {
                referrerCode = splitArr[1]
              } else {
                referrerCode = splitArr[0]
              }
              referrerCode = new URLSearchParams(referrerCode).get('ref') ?? ''
            }
            referrerParam = decode(referrerCode, K_ARR, DE_ORDER32)  // DO NOT CHANGE THIS LINE
          }
          const refereeAddress = address.toLowerCase()
          const validReferrer = isValidReferrer(referrerParam, refereeAddress, web3)
          if (validReferrer) {
            setReferrerCode(referrerCode)
            setReferrerParam(referrerParam)
            setReferralStatus(ReferralStatus.REF_WEB)
            setDiscountRate(10)
          }
        }

        checkReferral().catch(console.error)
      }
    }, 1000);  // Only execute when refInputVal have not changed for more than 1 seconds (user stopped typing)
    return () => clearTimeout(timeoutId);
  }, [refInputVal])


  return (
    <div>
      <SFormWrapper>
        {!isMobile && <div style={styles.supportedChainsDiv}>
          <Steps direction="vertical" size="small" current={approveSendState} style={styles.stepsBarSider}>
            <Step title={tl('Start')} description={tl('Connect your wallet')} />
            <Step title={tl('Input')} description={tl('Input token and receivers information')} />
            <Step title={tl('Approve')} description={tl('BatchTransfer is delegated to transfer your token')} />
            <Step title={tl('Safe Batch Transfer')} />
            <Step title={tl('Success')} />
          </Steps>
          {showNFTrai && <div style={styles.nftraiDiv}>
            <div>
              <a href='https://www.weix.io' onClick={trackWeiX} target='_blank' rel='noopener noreferrer'>
                <img src={WeiXIcon} title='WeiX.io' alt='WeiX.io' style={styles.raiIcon}></img>
              </a>
              <label><a href='https://www.weix.io' onClick={trackWeiX} target='_blank' rel='noopener noreferrer'>{tl('Trade Crypto with Free Limit Order')}</a></label>
            </div>
            <a href='https://www.weix.io' onClick={trackWeiX} target='_blank' rel='noopener noreferrer'>
              <img src={WeiX} title='DeFi with Free Limit Order - WeiX.io' alt='DeFi Crypto Trading with Free Limit Order - WeiX.io' style={styles.sameWidthRound}></img>
            </a>
          </div>}
        </div>
        }
        <Column maxWidth={1000} spanHeight>
          <SSendNoteMargin><a href='https://sol.batchtransfer.io' target='_blank' rel='noopener noreferrer'>{tl('BatchTransfer Solana is HERE, and it is FREE!')}</a></SSendNoteMargin>
          <SPanelContainer isMobile={isMobile}>
            {!web3Provider && <SChainsNote>{tl('Tool supports Ethereum, BSC, Solana, Polygon, Avalanche, Fantom, HECO, Cronos, Arbitrum, Optimism, Klaytn, Gnosis, Aurora, Celo, Fusion, ETHW, ETHF, Moonbeam and Moonriver. FREE for most of the networks.')}</SChainsNote>}
            {pendingDataInputConfirm ?
              <>
                <TokenStandardSelector
                  connected={web3Provider}
                  chainId={chainId}
                  defaultStandard={selectType}
                  backButtonClicked={backButtonClicked}
                  onSelectTokenStandard={handleSelectTokenStandard}
                />
                <SBalances>
                  {selectType !== "eth" &&
                    <AutoComplete
                      web3={web3}
                      chainId={chainId_map.get(chainId)}
                      address={address}
                      tokenStandard={selectType}
                      tokenAddress={tokenAddress}
                      options={selectType === 'erc20' ? ERC20Balances : NFTBalances}
                      backButtonClicked={backButtonClicked}
                      handleFetchBalances={handleDropdownMenu}
                      tl={tl}
                    />}
                  <br />
                  <>
                    <SAddressLabelContainer>
                      <div>
                        {selectType === 'eth' || selectType === 'erc20' ? 'Receiver_Address, Amount' : (selectType === 'erc721' ? 'Receiver_Address, Token_ID ' : (selectType === 'erc1155' ? 'Receiver_Address, Token_ID,Amount ' : null))}
                        {(selectType === 'erc721' || selectType === 'erc1155') && <Button title={tl('How to get the Token ID?')} shape="round" type="primary" size="small" onClick={toggleQuestModal}><b>?</b></Button>}
                      </div>
                      <Radio.Group onChange={toggleManualInputMode} value={manualInputMode}>
                        <Radio value={true}>{tl('Insert')}</Radio>
                        <Radio value={false}>{tl('Upload')}</Radio>
                      </Radio.Group>
                    </SAddressLabelContainer>
                    {manualInputMode ?
                      <STextInputWrapper>
                        <SCodeMirror
                          value={textareaInput}
                          onChange={handleCodeMirrorChange}
                          placeholder={placeholder}
                        />
                      </STextInputWrapper> :
                      <FileUploader
                        tokenStandard={selectType}
                        handleFileUploading={handleDropFile}
                        connected={web3Provider}
                        tl={tl}
                      />
                    }
                    <SAddressLabelContainer>
                      {!isMobile && <Downloader tokenStandard={selectType} tl={tl} />}
                      {chainId && isEthChain(chainId) && <GasTracker />}
                    </SAddressLabelContainer>
                    <br />
                    <Column>
                      {fetching ? (
                        <Column center>
                          <SLoaderDiv>
                            <Loader />
                            <STightP>{tl('Loading ...')}</STightP>
                          </SLoaderDiv>
                        </Column>
                      ) :
                        <Button
                          onClick={confirmInput}
                          disabled={!web3Provider || !pendingDataInputConfirm || !textareaInput ||
                            (selectType !== 'eth' && !tokenAddress) || blackToken || invalidToken
                          }
                          hidden={false}
                          icon={<EyeOutlined />}
                          shape="round"
                          type="primary"
                          size="large">
                          {tl('Preview')}
                        </Button>}
                    </Column>
                    <SWrongPhraseContainer>
                      {!supportedChainIds.includes(chainId ?? 0) && <SInvalidParagraph>{tl('The current network is not supported. Please switch to the supported networks from your wallet.')}</SInvalidParagraph>}
                      {blackToken && <SInvalidParagraph>{tl('This particular token address is currently not supported.')}</SInvalidParagraph>}
                      {invalidToken && <SInvalidParagraph>{tl('Invalid token address.')}</SInvalidParagraph>}
                      {wrongMaxRows && <SInvalidParagraph>{`${tl('Input cannot be more than')} ${maxRows} ${tl('rows.')}`}</SInvalidParagraph>}
                      {wrongCols && <SInvalidParagraph>{`${tl('The number of input columns must be ')}` + (selectType === 'erc1155' ? 3 : 2) + tl('. ')}</SInvalidParagraph>}
                      {wrongRows && <SInvalidParagraph>{tl('The number of rows must be greater than 0.')}</SInvalidParagraph>}
                      {(wrongAddrPhrase && wrongAddrPhrase.length > 0) && <SInvalidParagraph>{tl('Invalid receiver address in row ') + wrongAddrPhrase + tl('. ')}</SInvalidParagraph>}
                      {(wrongTokenIdPhrase && wrongTokenIdPhrase.length > 0) && <SInvalidParagraph>{tl('Invalid token ID in row ') + wrongTokenIdPhrase + tl('. ')}</SInvalidParagraph>}
                      {(wrongAmountPhrase && wrongAmountPhrase.length > 0) && <SInvalidParagraph>{tl('Invalid token amount in row ') + wrongAmountPhrase + tl('. ')}</SInvalidParagraph>}
                      {wrongCoinBalance && <SInvalidParagraph>{`${getTokenStandard(chainId, selectType)} ${tl('has insufficient balance.')}`}</SInvalidParagraph>}
                      {duplicate721 && <SInvalidParagraph>{`${tl('Input has duplicate')} ${getTokenStandard(chainId, selectType)} NFT token ID${tl('. ')}`}</SInvalidParagraph>}
                      {networkError && <SInvalidParagraph>{tl('Network error. Select the right network in your wallet and refresh.')}</SInvalidParagraph>}
                      {inSufTokenIdArr.length > 0 && inSufTokenIdArr.map(tokenID => <SInvalidParagraph>{`${tl('You do not have enough amount of Token ID ')}${tokenID}${tl('. ')}`}</SInvalidParagraph>)}
                      {unMintLen > 0 && <SInvalidParagraph>{unMintLen === 1 ? `${tl('Some OpenStore NFTs')} (Token ID ${unMintTokenId}) ${tl('are not minted.')}` : `${tl('Some OpenStore NFTs')} (Token ID ${unMintTokenId} ${tl('and other')} ${unMintLen - 1} ${tl('Token IDs')}) ${tl('are not minted.')}`}</SInvalidParagraph>}
                      {(unMintLen > 0 || openStoreNFTEth) &&
                        <div style={styles.openStoreDiv}>
                          <p>{tl('Free created NFTs in OpenSea all have a ')}<a href='https://support.opensea.io/hc/en-us/articles/1500003076601-Can-I-list-an-item-without-paying-to-mint-it-' target='_blank' rel='noopener noreferrer'><u>{tl('lazy minting')}</u></a>{tl(' feature')}{tl('. ')}</p>
                          {tl('en') === 'zh' ?
                            <div>
                              <p>{tl('Please follow the ')}<a href='https://www.bilibili.com/video/BV1UA4y1U7zW' target='_blank' rel='noopener noreferrer'><u>{tl('steps')}</u></a>{tl(' below to mint ')}<b><i>{tl('ALL')}</i></b>{tl(' number of your OpenStore NFTs')}{tl('. ')}</p>
                              <img src={OpenStoreGifZh} alt='How to mint OpenStore NFTs' style={styles.sameWidth}></img>
                            </div>
                            :
                            <div>
                              <p>{tl('Please follow the ')}<a href='https://www.youtube.com/watch?v=7pw9u3WqbsU' target='_blank' rel='noopener noreferrer'><u>{tl('steps')}</u></a>{tl(' below to mint ')}<b><i>{tl('ALL')}</i></b>{tl(' number of your OpenStore NFTs')}{tl('. ')}</p>
                              <img src={OpenStoreGifEn} alt='How to mint OpenStore NFTs' style={styles.sameWidth}></img>
                            </div>
                          }
                          {/* <video width='400' height='270' controls>
                            <source src={OpenStoreVideo} type='video/mp4' />
                            Your browser does not support the video.
                          </video> */}
                          <p>{tl('1. Free create all your OpenStore NFTs in OpenSea.')}</p>
                          <p>{tl('2. Use OpenSea transfer to mint ')}<b><i>{tl('ALL')}</i></b> {tl('the number of NFTs you want to BatchTransfer to a second wallet.')}</p>
                          <p>{tl('3. Go to')} <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'><u>app.batchtransfer.io</u></a>{tl(' and BatchTransfer from the second wallet to multiple wallets.')}</p>
                          <p>{tl('If you still have problems, please ')}<a href='https://www.batchtransfer.io/#contact' target='_blank' rel='noopener noreferrer'><u>{tl('contact us')}</u></a>{tl('. ')}</p>
                        </div>
                      }
                    </SWrongPhraseContainer>
                  </>
                </SBalances>
              </> :
              <div style={{ width: '100%' }}>
                <PageHeader
                  title=" "
                  onBack={handleClickBackButton}
                />
                <SummaryCard
                  chainId={chainIdRef.current}
                  approveSendState={approveSendState}
                  tokenStandard={selectTypeRef.current}
                  tokenName={tokenName}
                  tokenAddress={tokenAddressRef.current}
                  numberOfReceivers={aggInput ? aggInput.length : 0}
                  totalAmountOfTokens={aggInput ? roundNumber(getTotalAmount(aggInput)) : 0}
                  discountRate={discountRateRef.current}
                  // txHash={result && result.hasOwnProperty('Tx hash') ? result['Tx hash'] : null}
                  tl={tl}
                />
                {!approvalStatus && selectType === 'erc20' &&
                  <div style={styles.centerDiv}>
                    <div style={styles.approveDiv}>
                      <p>{tl('Select amount of tokens to approve:')}</p>
                    </div>
                    <div style={styles.radioDiv}>
                      <Radio.Group onChange={onIsSendAmount} value={isSendAmount}>
                        <Radio value={true}>{tl('Amount of tokens to send (need approve again next time)')}</Radio>
                        <Radio value={false}>{tl('Total supply of the token (need approve only this time)')}</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                }
                <br />
                <br />
                <Column center={false}>
                  <STestButtonContainer>
                    {(!approvalStatus && selectType !== 'eth') ?
                      <Button
                        onClick={sendSetApprovalTransaction}
                        disabled={(approvalStatus || approveSendState !== ApproveStatus.CONFIRMED_INPUT)}
                        hidden={false}
                        shape='round'
                        type='primary'
                        size='large'
                      >
                        {tl('Approve')}
                      </Button> :
                      <Column>
                        {approveSendState === ApproveStatus.APPROVED && isTipChain(chainIdRef.current) &&
                          <div style={styles.tipDiv}>
                            <label>{`${tl('Tips:')}\u00A0`}</label>
                            <TipSelector
                              onSelectTips={handleSelectTippingAmount}
                            />
                          </div>
                        }
                        <Button
                          onClick={sendBatchSendTransaction}
                          disabled={(selectType !== 'eth' && (!approvalStatus || pendingDataInputConfirm)) || approveSendState === ApproveStatus.COMPLETED}
                          hidden={false}
                          icon={<SendOutlined />}
                          shape='round'
                          type='primary'
                          size='large'
                        >
                          {tl('Batch Transfer')}
                        </Button>
                      </Column>
                    }
                  </STestButtonContainer>
                </Column>
              </div>}
          </SPanelContainer>
          <br />
          {isChargeChain(chainId) && pendingDataInputConfirm && referralStatusRef.current !== ReferralStatus.SELF_DB &&
            <div>
              <button {...getToggleProps()} style={styles.transparentButton}>
                {isExpanded ? `${tl('Referral Code (Optional)')} 🔼` : `${tl('Referral Code (Optional)')} 🔽`}
              </button>
              <section {...getCollapseProps()}>
                {discountRateRef.current > 0 && <p style={styles.centerContent}>{`✅\u00A0\u00A0${tl('You have')} ${discountRateRef.current}% ${tl('discount!')}`}</p>}
                <input
                  id='refcode'
                  name='refcode'
                  type='text'
                  readOnly={referralStatusRef.current === ReferralStatus.REF_DB}
                  placeholder={tl('Enter the referral code or referral link')}
                  // defaultValue={referrerCodeRef.current}
                  value={refInputVal}
                  onChange={handleRefInputChange}
                  style={styles.noBorderInput}
                />
              </section>
            </div>
          }
        </Column>
        {!isMobile &&
          <div style={styles.supportedChainsDiv}>
            <p>{tl('Tool supports')}:</p>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={EthIcon} title='Ethereum' alt='Ethereum' style={styles.cryptoIcon}></img>
                <label>{tl('Ethereum')}</label>
              </a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={BnbIcon} title='Binance BNB Smart Chain (BSC)' alt='Binance BNB Smart Chain (BSC)' style={styles.cryptoIcon}></img>
                <label>{`${tl('BSC')}`}</label>
              </a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://sol.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={SolIcon} title='Solana' alt='Solana' style={styles.cryptoIcon}></img>
                <label>{`${tl('Solana')} (${tl('Free')})`}</label>
              </a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={MaticIcon} title='Polygon' alt='Polygon' style={styles.cryptoIcon}></img>
                <label>{`Polygon (${tl('Free')})`}</label>
              </a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={AvaxIcon} title='Avalanche' alt='Avalanche' style={styles.cryptoIcon}></img>
                <label>{`Avalanche`}</label>
              </a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={FtmIcon} title='Fantom' alt='Fantom' style={styles.cryptoIcon}></img>
                <label>{`Fantom`}</label>
              </a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={HecoIcon} title='HECO chain' alt='HECO chain' style={styles.cryptoIcon}></img>
                <label>{`${tl('HECO')} (${tl('Free')})`}</label>
              </a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={CroIcon} title='Cronos' alt='Cronos' style={styles.cryptoIcon}></img>
                <label>{`Cronos`}</label></a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={ArbiIcon} title='Arbitrum' alt='Arbitrum' style={styles.cryptoIcon}></img>
                <label>{`Arbitrum`}</label></a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={OpIcon} title='Optimism' alt='Optimism' style={styles.cryptoIcon}></img>
                <label>{`Optimism`}</label></a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={KlayIcon} title='Klaytn' alt='Klaytn' style={styles.cryptoIcon}></img>
                <label>{`Klaytn (${tl('Free')})`}</label></a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={GnoIcon} title='Gnosis' alt='Gnosis' style={styles.cryptoIcon}></img>
                <label>{`Gnosis`}</label></a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={AuroIcon} title='Aurora' alt='Aurora' style={styles.cryptoIcon}></img>
                <label>{`Aurora (${tl('Free')})`}</label></a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={CeloIcon} title='Celo' alt='Celo' style={styles.cryptoIcon}></img>
                <label>{`Celo`}</label></a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={FsnIcon} title='Fusion' alt='Fusion' style={styles.cryptoIcon}></img>
                <label>{`Fusion (${tl('Free')})`}</label></a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={EthwIcon} title='ETH PoW' alt='ETH PoW' style={styles.cryptoIcon}></img>
                <label>{`ETH PoW`}</label>
              </a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={EthfIcon} title='ETH Fair' alt='ETH Fair' style={styles.cryptoIcon}></img>
                <label>{`ETH Fair`}</label>
              </a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={GlmrIcon} title='Moonbeam' alt='Moonbeam' style={styles.cryptoIcon}></img>
                <label>{`Moonbeam (${tl('Free')})`}</label>
              </a>
            </div>
            <div style={styles.cryptoDiv}>
              <a href='https://app.batchtransfer.io' target='_blank' rel='noopener noreferrer'>
                <img src={MovrIcon} title='Moonriver' alt='Moonriver' style={styles.cryptoIcon}></img>
                <label>{`Moonriver (${tl('Free')})`}</label>
              </a>
            </div>
            {showNFTrai && <div style={styles.nftraiDiv}>
              <div>
                <a href='https://www.nftrai.io' onClick={trackNftRai} target='_blank' rel='noopener noreferrer'>
                  <img src={RaiIcon} title='NFTrai.io' alt='NFTrai.io' style={styles.raiIcon}></img>
                </a>
                <label><a href='https://www.nftrai.io' onClick={trackNftRai} target='_blank' rel='noopener noreferrer'>{tl('NFT Free Mint, Trending, Blue Chip')}</a></label>
              </div>
              <a href='https://www.nftrai.io' onClick={trackNftRai} target='_blank' rel='noopener noreferrer'>
                <img src={NFTrai} title='NFT AI Analytics and TradingView - nftrai.io' alt='NFT AI Analytics and TradingView, Discover Next Blue Chip NFT - nftrai.io' style={styles.sameWidthRound}></img>
              </a>
            </div>}
          </div>
        }
      </SFormWrapper>
      <br />
      <Modal show={showQuestModal} toggleModal={toggleQuestModal}>
        <LeftDiv>
          <h6><b>{tl('Three Steps to Get the Token ID')}</b></h6>
          <p>{tl('1. Go to the OpenSea NFT web page.')}</p>
          <p>{tl('2. Scroll down, and expand the Details.')}</p>
          <p>{tl('3. Click the Token ID to copy it.')}</p>
        </LeftDiv>
        <br />
        <img src={TokenIDGif} alt='Get the Token ID GIF' style={styles.sameWidth} />
      </Modal>
      <Modal show={showModal} toggleModal={toggleModal}>
        {pendingRequest ? (
          <SModalContainer>
            <SModalTitle>{tl('Pending Request')}</SModalTitle>
            <SContainer>
              <Loader />
              <SModalParagraph>
                {tl('Confirm the request from your wallet')}
              </SModalParagraph>
            </SContainer>
          </SModalContainer>
        ) : pendingTxReceipt ? (
          <SModalContainer>
            <SModalTitle>{tl('Pending Transaction Receipt')}</SModalTitle>
            <SContainer>
              <Loader />
              <SModalParagraph>
                {tl('Waiting for the transaction to complete: ')}<a href={getEtherscanUrl(chainId, 'tx', latestTxHash)} target='_blank' rel='noopener noreferrer'><u>{latestTxHash}</u></a>
              </SModalParagraph>
            </SContainer>
          </SModalContainer>
        ) : result ? (
          <SModalContainer>
            <SModalTitle>{approveSendState === ApproveStatus.APPROVED ? tl('Token Approved!') : tl('Transaction Success!')}</SModalTitle>
            {approveSendState === 3 &&
              <>
                <SSendNoteLeft><b>{tl('Next step:')}</b></SSendNoteLeft>
                <SSendNoteLeft><b>{tl('Dismiss this popup window, and click the Batch Transfer button to proceed.')}</b></SSendNoteLeft>
              </>
            }
            <ModalResult chainId={chainId} tl={tl}>{result}</ModalResult>
          </SModalContainer>
        ) : (
          <SModalContainer>
            <SModalTitle>{tl('Request Rejected or Timeout')}</SModalTitle>
            <SModalParagraph>
              {tl(errorMsg ?? '')}
            </SModalParagraph>
          </SModalContainer>
        )}
      </Modal>
    </div>
  );
}

Sender.propTypes = {
  address: PropTypes.string
};

export default Sender;
