import { isChargeChain, getTokenStandard, getEtherscanUrl, shortAddress } from "../helpers/utilities";
import { Skeleton, Table } from "antd";
import { useBTTransactions } from "../hooks/useBTTransactions";
import { formattedTime } from "../helpers/utilities";
import styled from "styled-components";

const STransactionContainer = styled.div`
  position: relative;
  width: 65%;
  max-width: 960px;
  margin: 0 auto;
  padding-top: 56px;
  justify-content: center;
`;

function BatchtransferTransactions(props) {
  const { provider, web3, chainId, walletAddress, tl } = props;
  const { transactions } = useBTTransactions(provider, web3, chainId, walletAddress);
  const isCharge = isChargeChain(chainId)

  var hasDiscount = false
  if (transactions !== null && transactions.length > 0) {
    hasDiscount = transactions[0]['discountRate'] > 0
  }

  const columns = [
    {
      title: tl('Transaction Hash'),
      dataIndex: "txHash",
      key: "txHash",
      render: (hash) => (hash &&
        <a
          href={getEtherscanUrl(chainId, "tx", hash)}
          target="_blank"
          rel="noreferrer"
        >
          {hash ? hash.substring(0, 20) + '...' : ''}
        </a>
      ),
    },
    {
      title: tl('Token Address'),
      dataIndex: "tokenAddress",
      key: "tokenAddress",
      render: (tokenAddress) => shortAddress(tokenAddress)
    },
    {
      title: tl('Token Type'),
      dataIndex: "tokenType",
      key: "tokenType",
      render: (tokenType) => tokenType ? getTokenStandard(chainId, tokenType) : null
    },
    // {
    //   title: "Value (ETH)",
    //   dataIndex: "value",
    //   key: "value",
    //   render: (value, item) => web3 && value ? weiToFixed(value, 4, web3) : null,
    // },
    // // If you want to show commission, it should be the database commission * discountRate
    {
      title: tl('Number of Receivers'),
      dataIndex: "numberOfReceivers",
      key: "numberOfReceivers",
      render: (nReceivers, item) => nReceivers
    },
    {
      title: tl('Amount of Tokens'),
      dataIndex: "tokenAmount",
      key: "tokenAmount",
      render: (value, item) => value ? Math.round(parseFloat(value) * 1e6) / 1e6 : null,
    },
    {
      title: tl('Transaction Time'),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp) => timestamp ? formattedTime(timestamp, false) : null
    },
  ]

  if (!isCharge) {
    columns.push({
      title: tl('Commission'),
      dataIndex: "commission",
      key: "commission",
      render: (value, item) => 0,
    })
  }

  if (isCharge && hasDiscount) {
    columns.push({
      title: tl('Discount'),
      dataIndex: "discountRate",
      key: "discountRate",
      render: (value, item) => value && parseFloat(value) > 0 ? value + '%' : null,
    })
  }

  let key = 0;
  return (
    <STransactionContainer>
      <h6>{`💸 ${tl('Transaction History')}`}</h6>
      <Skeleton loading={!transactions}>
        <Table
          dataSource={transactions.reverse()}
          columns={columns}
          rowKey={(record) => {
            key++;
            return `${record.txHash}-${key}`;
          }}
          pagination={{ simple: true, position: ['bottomRight'], hideOnSinglePage: true }}
        />
      </Skeleton>
    </STransactionContainer>
  );
}

export default BatchtransferTransactions;
